import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    maxWidth: '1000px',
    width: '100%',
    backgroundColor: '#F5F5F7',
    borderRadius: '20px',
    marginBottom: '20px',

    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: 'none',
    minHeight: 24,
    padding: '34px 30px 34px 0',
    backgroundColor: '#F5F5F7',
    margin: 0,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',

    '@media (max-width: 600px)': {
      flexDirection: 'row',
      padding: '30px 10px 24px 20px',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  expandIcon: {
    padding: '0 30px 0 20px',
    margin: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    margin: '0 30px 30px 74px',
    padding: 0,

    '@media (max-width: 600px)': {
      margin: '0 20px 30px 20px',
    },
  },
})(MuiAccordionDetails);

const AccordionTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

const AccordionDesc = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  opacity: 0.7;
`;

const AccordionComponent = (props) => {
  const {
    title, children, expanded, handleChange, id,
  } = props;

  return (
    <Accordion
      square
      expanded={expanded}
      onChange={handleChange(id)}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <AccordionTitle>{title}</AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDesc>{children}</AccordionDesc>
      </AccordionDetails>
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  handleChange: PropTypes.func,
  id: PropTypes.number.isRequired,
};

AccordionComponent.defaultProps = {
  expanded: false,
  handleChange: () => {},
};

export default AccordionComponent;
