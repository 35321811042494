import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledPaymentContainer,
  BodyBlockTitle,
  PayoutDivider,
} from 'styles/cashout/StyledBody';
import Commission from './CommissionBlock';
import Payout from './PayoutContainer';
import PayoutForm from './PayoutForm';

const PaymentContainer = () => {
  const { t } = useTranslation();
  return (
    <StyledPaymentContainer>
      <Commission />
      <BodyBlockTitle>{t('cashout.paymentContainer')}</BodyBlockTitle>
      <Payout />
      <PayoutDivider />
      <PayoutForm />
    </StyledPaymentContainer>
  );
};

export default PaymentContainer;
