import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  SET_ACCOUNT,
  SET_USER_DATA,
} from './globalActions';

export const GlobalContext = createContext(undefined);

const initialState = {
  steamAccount: '',
  tradeUrl: '',
  promoRestricted: false,
  cashoutBonusPercent: null,
};

const setAccount = (state, account) => ({
  ...state,
  steamAccount: account,
});

const setUserData = (state, userData) => ({
  ...state,
  tradeUrl: userData.tradeUrl ? userData.tradeUrl : '',
  promoRestricted: userData.promoRestricted ? userData.promoRestricted : false,
  cashoutBonusPercent: userData.cashoutBonusPercent ? userData.cashoutBonusPercent : null,
});

const stateReducer = (state, action) => {
  const {
    account,
    userData,
  } = action.payload;

  switch (action.type) {
    case SET_ACCOUNT:
      return setAccount(state, account);
    case SET_USER_DATA:
      return setUserData(state, userData);
    default:
      return initialState;
  }
};

const StateProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(stateReducer, initialState);

  return (
    <GlobalContext.Provider value={{ globalState, globalDispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalContext);

  if (!context) throw new Error('useGlobalState must be used in a global state provider');

  return context;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
};

export default StateProvider;
