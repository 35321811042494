/* eslint-disable no-console */
import React, { useState } from 'react';
import { goToAnchor } from 'react-scrollable-anchor';
import { useLocation, useHistory } from 'react-router-dom';
import { useGlobalState } from 'context/global/globalContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useTranslation } from 'react-i18next';

// styles
import {
  StyledMenuBurger,
  StyledAccountMenu,
  MenuDivider,
  MobileMenuItem,
} from 'styles/cashout/StyledHeader';
import TransactionsModal from 'components/modals/TransactionsModal';
import Dropdown from 'components/common/DropdownLanguage';

const MobileMenu = () => {
  const [anchor, setAnchor] = useState(null);
  const [openedTransactionsModal, setOpenedTransactionsModal] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const { globalState } = useGlobalState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { signOut, getTransactions } = useFirebaseContext();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const login = () => {
    logAmplitudeAction('steam_login_click', {
      source: location.pathname === '/cashout'
        ? 'cashout'
        : 'landing',
    });
    window.location = '/_auth/steam';
  };

  const logout = () => {
    logAmplitudeAction('sign_out');
    signOut();
  };

  const closeDialog = () => {
    setOpenedTransactionsModal(false);
  };

  const getAuthButton = () => (globalState.steamAccount
    ? <MobileMenuItem onClick={logout}>{t('header.out')}</MobileMenuItem>
    : <MobileMenuItem onClick={login}>{t('header.entry')}</MobileMenuItem>);

  const handleTransactionsClick = async (e) => {
    e.preventDefault();
    setOpenedTransactionsModal(true);
    try {
      const transactionsResponse = await getTransactions();

      if (transactionsResponse.data) {
        setTransactions(transactionsResponse.data);
      }
    } catch (error) {
      console.error('Something happened while getting transactions', error);
      closeDialog();
    }
    closeMenu();
  };

  const handleFAQClick = () => {
    if (location.pathname !== '/') {
      logAmplitudeAction('main_page_click');
      history.push('/');
      goToAnchor('faq');
    } else {
      logAmplitudeAction('faq_click');
      goToAnchor('faq');
    }
    closeMenu();
  };

  const handleInstructionClick = () => {
    history.push('/instructions');
    closeMenu();
  };

  const handleTrustpilotClick = () => {
    logAmplitudeAction('trustpilot_click');
    window.open('https://www.trustpilot.com/review/gccash.gg', '_blank');
  };

  return (
    <>
      <StyledMenuBurger onClick={openMenu} />
      <StyledAccountMenu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={closeMenu}
      >
        {`ID ${globalState.steamAccount}`}
        <MenuDivider />
        <Dropdown mobile />
        <MobileMenuItem onClick={handleTrustpilotClick}>{t('header.linkOne')}</MobileMenuItem>
        <MobileMenuItem onClick={handleTransactionsClick}>{t('header.linkTwo')}</MobileMenuItem>
        <MobileMenuItem onClick={handleFAQClick}>{t('header.linkThree')}</MobileMenuItem>
        <MobileMenuItem onClick={handleInstructionClick}>{t('header.linkFour')}</MobileMenuItem>
        {
            getAuthButton()
        }
      </StyledAccountMenu>
      <TransactionsModal
        opened={openedTransactionsModal}
        transactions={transactions}
        closeAction={closeDialog}
      />
    </>
  );
};

export default MobileMenu;
