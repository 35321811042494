import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)`
  height: 48px;
  box-shadow: none;
  background-color: #3378FF;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;

  &:hover {
    background-color: #4685FF;
  }
`;

export default StyledButton;
