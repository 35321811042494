import styled from 'styled-components';

export const Copyright = styled.span`
  color: rgba(255, 255, 255, .5);
`;

export const Disclaimer = styled.span`
  color: rgba(255, 255, 255, .5);
  margin-left: auto;
  margin-right: 60px;

  @media (max-width: 1240px) {
    margin: 0;
  }
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 16px;
  margin: 0 48px;
  color: #fff;
  text-decoration: none;

  @media (max-width: 1280px) {
    margin: 0 24px;
  }

  @media (max-width: 1120px) {
    margin: ${(props) => (props.mtb ? '12px 0' : '0 0 12px 0')}px;
  }
`;

const StyledFooterContainer = styled.div`
  width: 100%;
  background-color: #202020;
  padding: 36px 60px;
  display: flex;
  align-items: center;

  @media (max-width: 1120px) {
    flex-direction: column;
    padding: 20px 0;
  }

  @media (max-width: 460px) {
    align-items: flex-start;
    padding: 20px 0 20px 20px;
  }
`;

export default StyledFooterContainer;
