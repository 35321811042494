import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from 'themes/DarkTheme';
import AmplitudeProvider from 'context/amplitude/amplitudeContext';
import GlobalContextProvider from 'context/global/globalContext';
import FirebaseContextProvider from 'context/firebase/firebaseContext';
import './i18n';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import 'static/fonts/inter/stylesheet.css';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <ThemeProvider>
        <AmplitudeProvider>
          <GlobalContextProvider>
            <FirebaseContextProvider>
              <App />
            </FirebaseContextProvider>
          </GlobalContextProvider>
        </AmplitudeProvider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
