import React from 'react';
import styled from 'styled-components';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useTranslation } from 'react-i18next';
import tiktok from '../../../static/images/landing/tiktok.svg';
import vk from '../../../static/images/landing/vk.svg';
import instagram from '../../../static/images/landing/instagram.svg';

const Main = styled.div`
  background-color: #000000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 92px;

  @media (max-width: 790px) {
    padding: 60px 0 40px;
  }
`;

const Header = styled.div`
  max-width: 778px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;

  @media (max-width: 790px) {
    margin: 0 24px 60px;
  }

  @media (max-width: 680px) {
    max-width: 288px;
    margin: 0 24px 24px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #ffffff;

  @media (max-width: 1080px) {
    margin-left: 24px;
  }

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Desc = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #ffffff;
  opacity: 0.5;

  @media (max-width: 1080px) {
    margin-left: 24px;
  }

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 9px;

  @media (max-width: 1080px) {
    margin: 0 24px 60px;
    flex-direction: column;
  }
`;

const SocialCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 320px;
  max-width: 340px;
  width: 100%;
  border-radius: 20px;
  padding: 30px;
  background: #1d1e20;
  margin-left: 20px;

  &:first-of-type {
    margin-left: 0;
  }

  @media (max-width: 1080px) {
    margin-left: 0;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 360px) {
    min-width: 288px;
    max-width: 288px;
  }
`;

const SocialIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const SocialTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin: 24px 0 8px;
`;

const SocialDesc = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 20px;
`;

const SocialLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 8px 24px;
  background: #2a7bf6;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;

  &:hover {
    cursor: pointer;
  }
`;

const Socialmedia = () => {
  const { logAmplitudeAction } = useAmplitudeContext();
  const { t, i18n } = useTranslation();

  const handleClickSocial = (link) => {
    logAmplitudeAction('social_media', { socialMedia: link });
  };

  return (
    <Main>
      <Header>
        <Title>{t('landing.socialTitle')}</Title>
        <Desc>{t('landing.socialDesc')}</Desc>
      </Header>
      <Container>
        <SocialCard>
          <SocialIcon src={tiktok} alt="Tiktok" />
          <SocialTitle>Tiktok</SocialTitle>
          <SocialDesc>
            {i18n.language === 'ru' ? '@gc.skins' : '@gcskins'}
          </SocialDesc>
          {i18n.language === 'ru' ? (
            <SocialLink href="https://www.tiktok.com/@gc.skins" target="_blank" onClick={() => handleClickSocial('tiktok')}>
              {t('landing.faqOpen')}
            </SocialLink>
          ) : (
            <SocialLink href="https://www.tiktok.com/@gcskins" target="_blank" onClick={() => handleClickSocial('tiktok')}>
              {t('landing.faqOpen')}
            </SocialLink>
          )}
        </SocialCard>
        <SocialCard>
          <SocialIcon src={vk} alt="VK" />
          <SocialTitle>Vk</SocialTitle>
          <SocialDesc>@gcskins</SocialDesc>
          <SocialLink href="https://vk.com/gcskins" target="_blank" onClick={() => handleClickSocial('vk')}>
            {t('landing.faqOpen')}
          </SocialLink>
        </SocialCard>
        <SocialCard>
          <SocialIcon src={instagram} alt="Instagram" />
          <SocialTitle>Instagram</SocialTitle>
          <SocialDesc>@gc.skins</SocialDesc>
          <SocialLink
            href="https://www.instagram.com/gc.skins/"
            target="_blank"
            onClick={() => handleClickSocial('instagram')}
          >
            {t('landing.faqOpen')}
          </SocialLink>
        </SocialCard>
      </Container>
    </Main>
  );
};

export default Socialmedia;
