import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import loadingIcon from 'static/images/icons/loading_icon.png';
// styles
import {
  StyledOfferContainer,
} from 'styles/offer/StyledOffer';
import {
  Spinner,
} from 'styles/cashout/StyledBody';
import OfferStage from './OfferStage';

const OfferContainer = () => {
  const [transactions, setTransactions] = useState(null);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  // const [acceptDateMillis, setAcceptDateMillis] = useState(null);
  const { getTransactions } = useFirebaseContext();
  const { id } = useParams();
  const history = useHistory();

  const getAllTransactions = async () => {
    try {
      const transactionsResponse = await getTransactions();

      if (transactionsResponse.data) {
        setTransactions(transactionsResponse.data);
      } else {
        setTransactions([]);
      }
    } catch (_) {
      history.push('/cashout');
    }
  };

  const getCurrentTransaction = () => {
    const transactionIds = transactions.map((transaction) => transaction.id);
    const transactionIndex = transactionIds.indexOf(id);

    if (transactionIndex > -1) {
      setCurrentTransaction(transactions[transactionIndex]);
    } else {
      setCurrentTransaction({});
    }
  };

  const getOfferComponent = useCallback(() => {
    if (transactions && currentTransaction) {
      if (currentTransaction.id) {
        return (
          <OfferStage
            transaction={currentTransaction}
            acceptDateMillis={currentTransaction.acceptDateMillis}
          />
        );
      }
      return <Redirect to="/cashout" />;
    }
    return <Spinner src={loadingIcon} alt="" />;
  }, [transactions, currentTransaction]);

  useEffect(() => {
    setCurrentTransaction(null);
  }, [id]);

  useEffect(() => {
    if (!transactions) {
      getAllTransactions();
    } else if (!currentTransaction) {
      getCurrentTransaction();
    }
  }, [transactions, currentTransaction]);

  return (
    <StyledOfferContainer container justifyContent="center" alignItems="center">
      {
        getOfferComponent()
      }
    </StyledOfferContainer>
  );
};
export default OfferContainer;
