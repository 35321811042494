import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// styles
import StyledFooterContainer, {
  Copyright,
  Disclaimer,
} from 'styles/footer/StyledFooter';

import {
  StyledLink,
} from 'styles/cashout/StyledHeader';

const FooterContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickPolicy = (e) => {
    e.preventDefault();
    history.push('/terms');
    window.scrollTo(0, 0);
  };

  const handleClickPrivacy = (e) => {
    e.preventDefault();
    history.push('/privacy');
    window.scrollTo(0, 0);
  };

  const handleClickContacts = (e) => {
    e.preventDefault();
    history.push('/contacts');
    window.scrollTo(0, 0);
  };

  return (
    <StyledFooterContainer container alignItems="center">
      <Copyright>© Skinsta Limited, 2021</Copyright>
      <StyledLink onClick={handleClickPolicy}>{t('footer.termsOfUse')}</StyledLink>
      <StyledLink onClick={handleClickPrivacy}>{t('footer.privacy')}</StyledLink>
      <StyledLink onClick={handleClickContacts}>{t('contacts.title')}</StyledLink>
      <Disclaimer>{t('footer.note')}</Disclaimer>
    </StyledFooterContainer>
  );
};

export default FooterContainer;
