import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  OPEN_UNAVAILABLE_MODAL,
  CLOSE_UNAVAILABLE_MODAL,
  OPEN_PROMO_MODAL,
  CLOSE_PROMO_MODAL,
} from './modalsActions';

export const ModalsContext = createContext(undefined);

const initialState = {
  unavailableModalOpened: false,
  promoModalOpened: false,
};

const openUnavailableModal = (state) => ({
  ...state,
  unavailableModalOpened: true,
});

const closeUnavailableModal = (state) => ({
  ...state,
  unavailableModalOpened: false,
});

const openPromoModal = (state) => ({
  ...state,
  promoModalOpened: true,
});

const closePromoModal = (state) => ({
  ...state,
  promoModalOpened: false,
});

const stateReducer = (state, action) => {
  switch (action.type) {
    case OPEN_UNAVAILABLE_MODAL:
      return openUnavailableModal(state);
    case CLOSE_UNAVAILABLE_MODAL:
      return closeUnavailableModal(state);
    case OPEN_PROMO_MODAL:
      return openPromoModal(state);
    case CLOSE_PROMO_MODAL:
      return closePromoModal(state);
    default:
      return initialState;
  }
};

const StateProvider = ({ children }) => {
  const [modalsState, modalsDispatch] = useReducer(stateReducer, initialState);

  return (
    <ModalsContext.Provider value={{ modalsState, modalsDispatch }}>
      {children}
    </ModalsContext.Provider>
  );
};

export const useModalsState = () => {
  const context = useContext(ModalsContext);

  if (!context) throw new Error('useModalsState must be used in a modals state provider');

  return context;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
};

export default StateProvider;
