import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)`
  height: 48px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;

  &.MuiButton-outlined {
    border: 1px solid #fff;

    &.Mui-disabled {
      color: #fff;
      opacity: .5;
    }
  }
`;

export default StyledButton;
