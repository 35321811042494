import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isTablet } from 'react-device-detect';
import { ReactComponent as PaginationIcon } from 'static/images/icons/pagination_icon.svg';
import { useCashoutState } from 'context/cashout/cashoutContext';
import {
  SET_INVENTORY_PAGE,
} from 'context/cashout/cashoutActions';
// styles
import {
  ContentPaginationContainer,
  LeftPaginationButton,
  RightPaginationButton,
} from 'styles/mobile/cashout/StyledBody';

const ContentPagination = (props) => {
  const {
    totalItems,
  } = props;
  const [smallMobile, setSmallMobile] = useState(false);
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const currentPage = cashoutState.page;
  const displayDevice = () => {
    if (smallMobile) return 4;
    if (isTablet) return 20;
    return 6;
  };
  const totalPages = Math.ceil(totalItems / displayDevice());
  const { t } = useTranslation();

  const onresize = () => {
    if (window.outerHeight < 680) {
      setSmallMobile(true);
    } else {
      setSmallMobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener('resize', onresize());

    return () => {
      document.removeEventListener('resize', onresize());
    };
  }, [window.outerHeight]);

  const increasePage = () => {
    cashoutDispatch({ type: SET_INVENTORY_PAGE, payload: { page: currentPage + 1 } });
  };

  const decreasePage = () => {
    cashoutDispatch({ type: SET_INVENTORY_PAGE, payload: { page: currentPage - 1 } });
  };

  return (
    <ContentPaginationContainer container justifyContent="space-between" alignItems="center">
      <LeftPaginationButton type="button" onClick={decreasePage} disabled={currentPage === 0}>
        <PaginationIcon />
      </LeftPaginationButton>
      { `${currentPage + 1} ${t('cashout.contentPaginationOf')} ${totalPages || 1}` }
      <RightPaginationButton
        type="button"
        onClick={increasePage}
        disabled={currentPage === (totalPages ? totalPages - 1 : 0)}
      >
        <PaginationIcon />
      </RightPaginationButton>
    </ContentPaginationContainer>
  );
};

ContentPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
};

export default ContentPagination;
