import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import {
  PayoutTitle,
  PayoutContent,
  PayoutContainerFirstItem,
  PayoutContainerItem,
  PayoutMethodItem,
  IconContainer,
  StyledDialog,
  ModalContainer,
  QiwiModalTitle,
  QiwiModalLink,
  QiwiModalDesc,
  QiwiModalButton,
  // PayoutMethodItemText,
} from 'styles/cashout/StyledBody';
import { ReactComponent as CardLogo } from 'static/images/logos/card_logo.svg';
// import { ReactComponent as QiwiLogo } from 'static/images/logos/qiwi_logo.svg';
import { ReactComponent as CoinsLogo } from 'static/images/logos/coins_logo.svg';
import { ReactComponent as CoinIcon } from 'static/images/icons/coin_icon.svg';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { SET_PAYOUT_METHOD } from 'context/cashout/cashoutActions';
import { useWalletState } from 'context/wallet/walletContext';
import { useGlobalState } from 'context/global/globalContext';
import {
  SET_WALLET_VALIDITY,
  SET_WALLET_VALUE,
} from 'context/wallet/walletActions';

const PayoutContainer = () => {
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { walletDispatch } = useWalletState();
  const { globalState } = useGlobalState();
  const [openedQiwiModal, setOpenedQiwiModal] = useState(false);
  const [openedCardModal, setOpenedCardModal] = useState(false);
  const { t, i18n } = useTranslation();

  const [bonus, setBonus] = useState(0);

  const handleClick = (type) => {
    if (type === 'qiwi') {
      setOpenedQiwiModal(true);
    }

    if (type === 'card') {
      setOpenedCardModal(true);
    }
    cashoutDispatch({
      type: SET_PAYOUT_METHOD,
      payload: { payoutMethod: type },
    });
    walletDispatch({
      type: SET_WALLET_VALIDITY,
      payload: { walletValidity: false },
    });
    walletDispatch({
      type: SET_WALLET_VALUE,
      payload: { walletValue: '' },
    });
  };

  const handleClosePromoModal = () => {
    setOpenedQiwiModal(false);
    setOpenedCardModal(false);
  };

  const bonusSum = () => {
    const percent = globalState.cashoutBonusPercent / 100;
    let sum;
    if (cashoutState.payoutMethod === 'gccoins') {
      sum = (cashoutState.selectedItemsCostCoins * percent).toFixed(2);
    } else {
      sum = (cashoutState.selectedItemsCost * percent).toFixed(2);
    }
    setBonus(sum);
  };

  useEffect(() => {
    bonusSum();
  }, [
    cashoutState.selectedItemsCost,
    cashoutState.selectedItemsCostCoins,
    cashoutState.payoutMethod,
  ]);

  useEffect(() => {
    if (globalState.steamAccount && cashoutState.payoutMethod === 'qiwi' && i18n.language === 'ru') {
      setOpenedQiwiModal(true);
    }
    if (globalState.steamAccount && cashoutState.payoutMethod === 'card' && i18n.language === 'ru') {
      setOpenedCardModal(true);
    }
  }, [cashoutState.payoutMethod, globalState.steamAccount, i18n.language]);

  useEffect(() => {
    if (i18n.language === 'en') {
      cashoutDispatch({
        type: SET_PAYOUT_METHOD,
        payload: { payoutMethod: 'gccoins' },
      });
    } else {
      cashoutDispatch({
        type: SET_PAYOUT_METHOD,
        payload: { payoutMethod: 'card' },
      });
    }
  }, [i18n.language]);

  return (
    <>
      <PayoutContainerFirstItem container justifyContent="space-between">
        <PayoutTitle>{t('cashout.payoutContainer.title')}</PayoutTitle>
        <PayoutContent>{cashoutState.selectedItems.size}</PayoutContent>
      </PayoutContainerFirstItem>

      {globalState.cashoutBonusPercent && (
        <>
          <PayoutContainerFirstItem container justifyContent="space-between">
            <PayoutTitle>{t('cashout.payoutContainer.promocode')}</PayoutTitle>
            <PayoutContent blue>
              +
              {' '}
              {globalState.cashoutBonusPercent}
              %
            </PayoutContent>
          </PayoutContainerFirstItem>
          <PayoutContainerFirstItem container justifyContent="space-between">
            <PayoutTitle>{t('cashout.payoutContainer.bonus')}</PayoutTitle>
            <PayoutContent>
              <IconContainer>
                {cashoutState.payoutMethod === 'gccoins' ? <CoinIcon /> : '₽'}
              </IconContainer>
              {bonus}
            </PayoutContent>
          </PayoutContainerFirstItem>
        </>
      )}

      <PayoutContainerItem container justifyContent="space-between">
        <PayoutTitle>{t('cashout.payoutContainer.sumPayout')}</PayoutTitle>
        <PayoutContent>
          <IconContainer>
            {cashoutState.payoutMethod === 'gccoins' ? <CoinIcon /> : '₽'}
          </IconContainer>
          {(
            Number(
              cashoutState.payoutMethod === 'gccoins'
                ? cashoutState.selectedItemsCostCoins
                : cashoutState.selectedItemsCost,
            ) + Number(bonus)
          ).toFixed(2)}
        </PayoutContent>
      </PayoutContainerItem>
      <PayoutContainerItem container justifyContent="space-between">
        <PayoutTitle>{t('cashout.payoutContainer.payoutMethod')}</PayoutTitle>
      </PayoutContainerItem>
      {i18n.language === 'ru' ? (
        <PayoutContainerItem container>
          <PayoutMethodItem
            selected={cashoutState.payoutMethod === 'gccoins'}
            onClick={() => handleClick('gccoins')}
          >
            <CoinsLogo />
          </PayoutMethodItem>
          {/* <PayoutMethodItem */}
          {/*  selected={cashoutState.payoutMethod === 'qiwi'} */}
          {/*  onClick={() => handleClick('qiwi')} */}
          {/* > */}
          {/* <PayoutMethodItemText>
            {t('cashout.payoutContainer.soon')}
          </PayoutMethodItemText> */}
          {/* <QiwiLogo /> */}
          {/* </PayoutMethodItem> */}
          <PayoutMethodItem
            selected={cashoutState.payoutMethod === 'card'}
            onClick={() => handleClick('card')}
          >
            {/* <PayoutMethodItemText>
            {t('cashout.payoutContainer.soon')}
          </PayoutMethodItemText> */}
            <CardLogo />
          </PayoutMethodItem>
        </PayoutContainerItem>
      ) : (
        <PayoutContainerItem container>
          <PayoutMethodItem
            selected={cashoutState.payoutMethod === 'gccoins'}
            onClick={() => handleClick('gccoins')}
          >
            <CoinsLogo />
          </PayoutMethodItem>
        </PayoutContainerItem>
      )}

      <StyledDialog open={openedQiwiModal}>
        <ModalContainer>
          <QiwiModalTitle>
            {t('cashout.payoutContainer.qiwiModalTitle')}
          </QiwiModalTitle>
          <QiwiModalDesc>
            {t('cashout.payoutContainer.qiwiModalDesc1')}
          </QiwiModalDesc>
          <QiwiModalDesc>
            {t('cashout.payoutContainer.qiwiModalDesc2')}
          </QiwiModalDesc>
          <QiwiModalLink
            href="https://qiwi.com/support/information/subject5/identifikatsiya-v-qiwi-koshelke"
            target="_blank"
            rel="noreferrer"
          >
            {t('cashout.payoutContainer.qiwiModalLink')}
          </QiwiModalLink>
          <QiwiModalDesc warning>
            {t('cashout.payoutContainer.qiwiModalDesc3')}
          </QiwiModalDesc>
          <QiwiModalButton onClick={handleClosePromoModal}>
            {t('cashout.payoutContainer.qiwiModalButton')}
          </QiwiModalButton>
        </ModalContainer>
      </StyledDialog>

      <StyledDialog open={openedCardModal}>
        <ModalContainer>
          <QiwiModalTitle>
            {t('cashout.payoutContainer.qiwiModalTitle')}
          </QiwiModalTitle>
          <QiwiModalDesc>
            {t('cashout.payoutContainer.cardNotificationsPrev')}
          </QiwiModalDesc>
          <QiwiModalDesc warning>
            {t('cashout.payoutContainer.cardNotifications1')}
          </QiwiModalDesc>
          <QiwiModalDesc warning>
            {t('cashout.payoutContainer.cardNotifications2')}
          </QiwiModalDesc>
          <QiwiModalButton onClick={handleClosePromoModal}>
            {t('cashout.payoutContainer.qiwiModalButton')}
          </QiwiModalButton>
        </ModalContainer>
      </StyledDialog>
    </>
  );
};

export default PayoutContainer;
