import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import OfferStateProvider from 'context/offer/OfferContext';
import ModalsContextProvider from 'context/modals/modalsContext';
import { IntercomProvider } from 'react-use-intercom';
import Header from 'components/header/HeaderContainer';
import Cashout from 'components/cashout/CashoutContainer';
import Landing from 'components/landing/LandingContainer';
import Offer from 'components/offer/OfferContainer';
import Instructions from 'components/instructions/InstuctionsContainer';
import Terms from 'components/terms/TermsContainer';
import Privacy from 'components/privacy/PrivacyContainer';
import Contacts from 'components/contacts/Contacts';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useGlobalState } from 'context/global/globalContext';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import UnavailableModal from 'components/modals/ServiceUnavailableModal';
import {
  SET_USER_DATA,
} from 'context/global/globalActions';

// I guess we need to hide it...
const INTERCOM_APP_ID = 'r16mhv8j';

function App() {
  const { initAmplitude, logAmplitudeAction } = useAmplitudeContext();
  const { globalState, globalDispatch } = useGlobalState();
  const { userData } = useFirebaseContext();
  const [promoModal, setPromoModal] = useState(false);

  const onIntercomShow = () => {
    logAmplitudeAction('support_click');
  };

  useEffect(() => {
    if (globalState.steamAccount) {
      userData.doc(globalState.steamAccount)
        .onSnapshot({
          includeMetadataChanges: true,
        }, (doc) => {
          const docData = doc.data();
          if (!docData.promoRestricted) {
            setPromoModal(true);
          }
          globalDispatch({
            type: SET_USER_DATA,
            payload: {
              userData: docData,
            },
          });
        });
    }
  }, [globalState.steamAccount]);

  useEffect(() => {
    initAmplitude();
    const userVisited = localStorage.getItem('visited_go_cash');
    if (userVisited !== 'true') {
      logAmplitudeAction('first_open');
      localStorage.setItem('visited_go_cash', true);
    }
  }, []);

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      onShow={onIntercomShow}
      autoBoot
    >
      <Router>
        <Header />
        <Switch>
          <ModalsContextProvider>
            <Route exact path="/">
              <Landing />
            </Route>
            <OfferStateProvider>
              <Route path="/cashout">
                <Cashout openPromoModal={promoModal} />
              </Route>
              <Route path="/offer/:id">
                <Offer />
              </Route>
              <Route path="/instructions">
                <Instructions />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/contacts">
                <Contacts />
              </Route>
            </OfferStateProvider>
            {/* modals */}
            <UnavailableModal />
          </ModalsContextProvider>
        </Switch>
      </Router>
    </IntercomProvider>
  );
}

export default App;
