import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StageBlock from 'components/common/OfferStageBlock';
import { useOfferState } from 'context/offer/OfferContext';
import { ReactComponent as FirstStageImage } from 'static/images/first_stage_image.svg';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
// styles
import {
  FirstStageImageContainer,
  StageTitle,
  StageDisclamer,
  StageInputContainer,
  SteamButtonContainer,
  FirstStageContainer,
  SecondStageContainer,
  FirstStageNumberBlock,
  StyledOfferInformationBlock,
  HintBlock,
} from 'styles/offer/StyledOffer';
import Input from 'components/common/CashoutInput';
import Button from 'components/common/CashoutPayoutButton';
import BotsButton from './ActualBotsButton';

const FirstStage = (props) => {
  const {
    transaction,
  } = props;
  const [botRegistrationDate, setBotRegistrationDate] = useState('');
  const { offerState } = useOfferState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { t } = useTranslation();

  const openSteam = () => {
    logAmplitudeAction('accept_steam_click');
    if (offerState.tradeOfferLink) {
      window.open(offerState.tradeOfferLink);
    }
  };

  const getServingBotRegistrationDate = () => new Date(offerState.servingBot.registrationDateMillis)
    .toLocaleDateString();

  useEffect(() => {
    if (offerState.servingBot) {
      setBotRegistrationDate(getServingBotRegistrationDate());
    }
  }, [offerState.servingBot]);

  return (
    <StageBlock>
      <FirstStageContainer item xs={5}>
        <StyledOfferInformationBlock container direction="column" justifyContent="center">
          <FirstStageNumberBlock>
            { t('offer.firstStage.stepOne') }
          </FirstStageNumberBlock>
          <StageTitle>
            { t('offer.firstStage.titleOne') }
          </StageTitle>
          <StageDisclamer>
            { t('offer.firstStage.descOne') }
          </StageDisclamer>
          <StageInputContainer>
            <Input
              placeholder={t('offer.firstStage.id')}
              value={(transaction && transaction.id) || offerState.transactionId}
              changeValue={() => {}}
              readonly
            />
          </StageInputContainer>
          <StageInputContainer>
            <Input
              placeholder={t('offer.firstStage.date')}
              value={(transaction
                && new Date(transaction.bot.registrationDateMillis).toLocaleDateString())
                || botRegistrationDate}
              changeValue={() => {}}
              readonly
            />
          </StageInputContainer>
          <StageInputContainer>
            <SteamButtonContainer>
              <Button onClick={openSteam}>
                {t('offer.firstStage.goToSteam')}
              </Button>
            </SteamButtonContainer>
          </StageInputContainer>
          <HintBlock>
            {t('offer.firstStage.hintBlock')}
          </HintBlock>
        </StyledOfferInformationBlock>
      </FirstStageContainer>
      <SecondStageContainer item xs={4}>
        <StyledOfferInformationBlock container direction="column">
          <FirstStageNumberBlock>{t('offer.firstStage.stepTwo')}</FirstStageNumberBlock>
          <StageTitle>{t('offer.firstStage.titleTwo')}</StageTitle>
          <StageDisclamer>{t('offer.firstStage.descTwo')}</StageDisclamer>
          <StageInputContainer>
            <BotsButton>
              {t('offer.actualBots.children')}
            </BotsButton>
          </StageInputContainer>
        </StyledOfferInformationBlock>
      </SecondStageContainer>
      <FirstStageImageContainer item container alignItems="center" justifyContent="flex-end" xs={3}>
        <FirstStageImage height="100%" />
      </FirstStageImageContainer>
    </StageBlock>
  );
};

FirstStage.propTypes = {
  transaction: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

FirstStage.defaultProps = {
  transaction: null,
};

export default FirstStage;
