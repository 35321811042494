import React from 'react';
import PropTypes from 'prop-types';
// styles
import StyledButton from 'styles/common/StyledFilledButton';

const CashoutFilledButton = (props) => {
  const {
    children,
    onClick,
    disabled,
  } = props;

  return (
    <StyledButton variant="contained" onClick={onClick} disabled={disabled} fullWidth>
      {children}
    </StyledButton>
  );
};

CashoutFilledButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CashoutFilledButton.defaultProps = {
  children: '',
  onClick: () => {},
  disabled: false,
};

export default CashoutFilledButton;
