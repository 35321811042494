const paragraphsRU = [
  'Общая информация',
  `Эти условия необходимо внимательно прочитать перед любым использованием
  данной Услуги.
  Используя этот Сервис или регистрируя учетную запись, вы
  должным образом соглашаетесь с этими условиями. Если вы не согласны с
  каким-либо положением настоящих условий, вы не должны использовать Сервис.`,
  `Gccash.gg оставляет за собой право в любое время обновлять,
  исправлять или изменять любую часть этих условий или иным
  образом изменять Сервис или его часть без предварительного
  уведомления Пользователей. Любая поправка к условиям или
  услуге становится обязательной после публикации.`,
  `Вы должны знать, что участие в обмене валют в играх, 
  а также любая другая деятельность, представляемая или поддерживаемая 
  этим Сервисом, может быть запрещена в некоторых странах. 
  Вы единолично обязаны и несете ответственность за обеспечение того, 
  чтобы доступ к этой Службе соответствовал законам, применимым в вашей юрисдикции, 
  и что обмен игровой валюты не является незаконным на территории, 
  на которой вы проживаете. Любые претензии к Gccash.gg в 
  отношении вышеизложенного считаются недействительными.`,
  'Этот Сервис не предназначен для использования лицами младше 18 лет.',
  'Вы ни в коем случае не можете использовать эту Службу для любых целей, которые являются незаконными, оскорбительными или несоответствующими, или которые мы считаем мошенническими или нечестными.',
  'Эти условия и любые вопросы, связанные с этой Услугой, регулируются и толкуются в соответствии с законами юридического адреса Gccash.gg. Любые претензии, споры или разногласия, возникающие в результате использования данного Сервиса, регулируются исключительно окружными судами юридического адреса Gccash.gg. Однако этот пункт не исключает и не ограничивает право Gccash.gg возбуждать дело против любого Пользователя в любом другом суде компетентной юрисдикции.',
  'Gccash.gg не несет ответственности за любые убытки или убытки, которые, как считается, или предположительно возникли в результате или были вызваны этой Услугой или ее содержанием. Это включает в себя использование или неправомерное использование его содержания любым лицом, неспособность любого лица подключиться к Сервису или использовать его, задержку операций или передачи, отказ линий связи или любые ошибки или упущения в содержании.',
  'Авторское право',
  'Gccash.gg и/или его лицензиары являются единственными владельцами всех прав на этот макет, коды, структуру и организацию Сервиса, включая коммерческую тайну, интеллектуальную собственность, а также любые другие права. Вы не должны копировать, распространять, публиковать, изменять или переводить любую часть этого Сервиса без письменного разрешения Gccash.gg.',
  'Домен Gccash.gg принадлежит исключительно Gccash.gg.',
  'Весь контент Сервисов, включая, в частности, но не исключительно, изображения, графику, фотографии, анимацию, видео, трансляции, музыку, звуки, тексты, условия и положения, принадлежит исключительно Gccash.gg или его связанным партнерам и защищен интеллектуальная собственность или другие права. Используя этот Сервис, вы не приобретаете никаких прав на его содержимое.',
  'Политика конфиденциальности',
  `Информация, которую мы можем хранить о пользователе, включает:
  любые данные, предоставленные при регистрации (объединении) аккаунта,
  детали ваших посещений веб-сайта,
  ваши ответы на проведенные исследовательские опросы / исследования,
  любая информация, предоставленная другими компаниями, получившими ваше разрешение на передачу информации о вас,
  ваш IP-адрес и техническая информация с вашего устройства.`,
  `Вся указанная выше информация может быть использована в следующих целях:
  обработка ваших депозитов и снятия средств,
  управление и персонализация ваших учетных записей,
  передача любого опроса или исследования,
  мониторинг любой активности на Веб-сайте, особенно, но не исключительно, для проверки его соответствия Положениям и условиям.`,
  'Gccash.gg гарантирует, что ваша информация будет надежно защищена. Никакая информация или данные не будут переданы или проданы третьим лицам. Однако имейте в виду, что мы можем раскрыть информацию после запроса органов государственной безопасности или соответствующих органов о предотвращении мошенничества и отмывания денег.',
  'Этот веб-сайт использует файлы cookie как средство сбора информации. Файлы cookie помогают нам развивать Сервис и повышать качество наших услуг. Если вы продолжаете использовать этот Сервис, вы знаете и соглашаетесь на использование нами файлов cookie.',
  'Регистрация',
  'Без регистрации Пользователь получает доступ к домашней странице Gccash.gg.',
  'Чтобы получить полный доступ к содержимому Сервиса, пользователю будет предложено объединить учетную запись Steam. Чтобы объединить учетную запись, вам не нужно предоставлять такие данные, как адрес электронной почты или пароль.',
  'У каждого пользователя может быть только 1 подключенная учетная запись Steam.',
  'Если Пользователь теряет данные для входа или по какой-либо другой причине не может получить доступ к учетной записи, Gccash.gg не несет ответственности за любые потери средств. Позаботьтесь о своих данных для входа и храните их в безопасности.',
  'Отключение и закрытие аккаунта',
  'Любой Пользователь может в любой момент отключить свой личный кабинет. Gccash.gg не несет ответственности за любые убытки в этом отношении.',
  'Gccash.gg оставляет за собой право заморозить, приостановить или аннулировать любую учетную запись пользователя вместе с валютой пользовательских игр. Это событие может произойти в рамках наших действий по борьбе с мошенничеством или попытками использовать лазейки и рекламные акции на нашем веб-сайте.',
  'Gccash.gg оставляет за собой право приостановить действие учетной записи клиента без предварительного уведомления пользователя.',
  `Внести средства
  Пользователя попросят:
  установить Steam Trade URL, сгенерированный из учетной записи Steam пользователя
  установить публичный статус инвентаря Steam
  Еcли пользователь не может каким-либо образом внести предметы в Steam, возможная причина указана на странице Steam.`,
  'Общая стоимость всех предметов Steam будет показана в российских рублях как баланс Пользователя.',
  'Доступность способов выплаты не является постоянной и может меняться во времени.',
  'Политика возврата и отмены',
  'Нет возможности вернуть проданные вами скины.',
  'Сделка не может быть отменена Вами ни на каком этапе после подтверждения в системе Steam. Сделка может быть расторгнута Компанией в одностороннем порядке на любом этапе на основании экспертизы Компании без объяснения причин. Компания обязуется выплатить точную сумму на выбранный Вами платежный счет. Если Компания не может сделать это по какой-либо причине, Компания может попросить Вас предоставить альтернативный платежный счет. Если по какой-либо причине Вы не можете предоставить Компании альтернативную платежную информацию, Компания имеет право вернуть Вам товары на свое усмотрение. Товары больше не подлежат возврату, если Компания уже выполнила выплату на предоставленный Вами платежный счет.',
  'Жалобы',
  'Любые жалобы на нас или предоставляемые нами Услуги должны быть адресованы нам в первую очередь, связавшись со службой поддержки клиентов. Вы должны четко указать, что хотите подать нам жалобу. Это помогает нам отличить жалобу от простого запроса. Мы отправим вам подтверждение жалобы по почте или по электронной почте в течение 48 часов с момента получения вашей жалобы в соответствии с нашей процедурой рассмотрения жалоб. Вы можете запросить копию нашей процедуры рассмотрения жалоб в любое время, связавшись со службой поддержки клиентов.',
  'Определения',
  'Сервис - сайт, расположенный на домене Gccash.gg.',
  'Пользователь - любое лицо, получающее доступ к Сервису, также именуемое «вы».',
  'GC CASH - Gccash.gg, также именуемое «мы».',
];

export default paragraphsRU;
