import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import styles from './Button.module.css';

const Button = (props) => {
  const history = useHistory();
  const {
    text, theme, link, chapter,
  } = props;
  const { logAmplitudeAction } = useAmplitudeContext();

  const handleClick = (e) => {
    e.preventDefault();
    logAmplitudeAction('landing_click', { page: chapter });
    history.push({ pathname: link });
  };

  return (
    <button onClick={handleClick} className={`${styles.button} ${theme === 'black' ? styles.theme_black : styles.theme_white}`} type="button">{text}</button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  chapter: PropTypes.string.isRequired,
};

export default Button;
