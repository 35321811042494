/* eslint no-console: ["error", { allow: ["error"] }] */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  SET_TRADE_LINK,
  REMOVE_ALL_SELECTED_ITEMS,
  SET_TRADE_STATUS,
} from 'context/cashout/cashoutActions';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useGlobalState } from 'context/global/globalContext';
// styles
import {
  StyledInventoryContainer,
  TradeUrlLink,
  BodyBlockTitle,
} from 'styles/mobile/cashout/StyledBody';
import {
  ModalTradeContainer,
  QiwiModalTitle,
  QiwiModalDesc,
  QiwiModalButton,
  SnackbarCloseButton,
} from 'styles/cashout/StyledBody';
import { StyledDialog } from 'styles/modals/StyledBotsModal';
import InventoryContent from 'components/cashout/body/inventory/content/InventoryContentContainer';
import TradeRow from 'components/cashout/body/inventory/TradeLinkRow';

const InventoryContainer = () => {
  const [confirmedTradeUrl, setConfirmedTradeUrl] = useState(false);
  const [tradeLink, setTradeLink] = useState('');
  const [inventoryItems, setInventoryItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tradeLinkError, setTradeLinkError] = useState(false);
  const [openedHoldModal, setOpenedHoldModal] = useState(false);
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { getUserInventory } = useFirebaseContext();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { globalState } = useGlobalState();
  const { t } = useTranslation();

  const handleCloseHoldModal = () => {
    setOpenedHoldModal(false);
  };

  const handleClickInstruction = () => {
    window.open(
      'https://help.steampowered.com/ru/faqs/view/451E-96B3-D194-50FC#itemhold',
    );
  };
  const tradeUrlApprove = () => {
    // if there is any symbol
    if (tradeLink) {
      // only if user changed his url
      if (cashoutState.tradeLink !== tradeLink) {
        cashoutDispatch({
          type: SET_TRADE_LINK,
          payload: {
            tradeLink,
          },
        });
        // removing all items to load new ones
        setInventoryItems(null);
        cashoutDispatch({ type: REMOVE_ALL_SELECTED_ITEMS, payload: {} });
      }
      setConfirmedTradeUrl(true);
    }
  };

  const changeTradeLink = (e) => {
    setTradeLink(e.target.value);
  };

  const reEnterTradeLink = () => {
    cashoutDispatch({
      type: SET_TRADE_LINK,
      payload: {
        tradeLink: '',
      },
    });
    setConfirmedTradeUrl(false);
  };

  const getUserInventoryItems = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line max-len
      const inventoryItemsResponse = await getUserInventory({
        tradeUrl: cashoutState.tradeLink,
        apiVersion: 2,
      });
      if (inventoryItemsResponse.data) {
        if (
          inventoryItemsResponse.data.escrowDays
          && inventoryItemsResponse.data.escrowDays > 0
        ) {
          setOpenedHoldModal(true);
          cashoutDispatch({
            type: SET_TRADE_STATUS,
            payload: {
              tradeHoldStatus: false,
            },
          });
        }
        setInventoryItems(inventoryItemsResponse.data.inventory);
      }
    } catch (e) {
      if (e.code === 'invalid-argument') {
        setTradeLinkError(true);
      }
      console.error('something went wrong while getting user inventiry', e);
    }
    setLoading(false);
  };

  const showTradeUrl = () => {
    logAmplitudeAction('trade_url_click');
    window.open(
      `https://steamcommunity.com/profiles/${globalState.steamAccount}/tradeoffers/privacy#trade_offer_access_url`,
    );
  };

  const getUrlActionLinks = () => {
    if (globalState.steamAccount) {
      if (cashoutState.tradeLink) {
        return (
          <TradeUrlLink onClick={reEnterTradeLink}>
            {t('cashout.inventoryContainer.changeTradeLink')}
          </TradeUrlLink>
        );
      }
      return (
        <TradeUrlLink onClick={showTradeUrl}>
          {t('cashout.inventoryContainer.getTradeLink')}
        </TradeUrlLink>
      );
    }
    return '';
  };

  useEffect(() => {
    if (cashoutState.tradeLink && !inventoryItems) {
      getUserInventoryItems();
    }
  }, [cashoutState.tradeLink]);

  useEffect(() => {
    if (globalState.tradeUrl) {
      setTradeLink(globalState.tradeUrl);
      cashoutDispatch({
        type: SET_TRADE_LINK,
        payload: {
          tradeLink: globalState.tradeUrl,
        },
      });
      setConfirmedTradeUrl(true);
    }
  }, [globalState.tradeUrl]);

  return (
    <StyledInventoryContainer>
      <Grid container justifyContent="space-between">
        <BodyBlockTitle>{t('cashout.inventoryContainer.inventory')}</BodyBlockTitle>
        {getUrlActionLinks()}
      </Grid>
      <TradeRow
        inventoryItems={inventoryItems}
        confirmedTradeUrl={confirmedTradeUrl}
        tradeLink={tradeLink}
        tradeUrlApprove={tradeUrlApprove}
        changeTradeLink={changeTradeLink}
        reEnterTradeLink={reEnterTradeLink}
      />
      <InventoryContent
        loading={loading}
        items={inventoryItems}
        tradeLinkError={tradeLinkError}
      />
      <StyledDialog open={openedHoldModal} maxWidth="lg">
        <ModalTradeContainer>
          <SnackbarCloseButton onClick={handleCloseHoldModal}>
            X
          </SnackbarCloseButton>
          <QiwiModalTitle>
            {t('cashout.inventoryContainer.holdModal.title')}
          </QiwiModalTitle>
          <QiwiModalDesc>
            {t('cashout.inventoryContainer.holdModal.desc')}
          </QiwiModalDesc>
          <QiwiModalButton onClick={handleClickInstruction}>
            {t('cashout.inventoryContainer.holdModal.button')}
          </QiwiModalButton>
        </ModalTradeContainer>
      </StyledDialog>
    </StyledInventoryContainer>
  );
};

export default InventoryContainer;
