import styled, { keyframes } from 'styled-components';
import {
  device,
} from 'styles/breakpoints';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledItemBlock = styled.div`
  position: relative;
  border-radius: 4px;
  width: 122px;
  height: 122px;
  cursor: pointer;
  border: ${(props) => (props.selected
    ? '1px solid #FFFFFF'
    : 'none')};
  background: ${(props) => (props.selected
    ? 'rgba(255, 255, 255, 0.2)'
    : 'rgba(255, 255, 255, 0.04)')};
  background: ${(props) => !props.tradable
    && 'rgba(255, 255, 255, 0.02)'};

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  @media ${device.desktopWide} {
    width: 178px;
    height: 178px;
  }
`;

export const ItemImage = styled.img`
  position: absolute;
  left: 10px;
  top: 18px;
  height: 78px;
  opacity: ${(props) => !props.tradable && 0.1};

  @media ${device.desktopWide} {
    left: 11px;
    top: 20px;
    height: 114px;
  }
`;

export const ItemPrice = styled.span`
  display: flex;
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export const ItemUnavailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

export const StyledLoadingIcon = styled.img`
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  animation: ${rotate} 2s linear infinite;
`;

export default StyledItemBlock;
