import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import amplitude from 'amplitude-js';

let amplitudeProjectHandler;
const amplitudeInstance = new Promise((resolve) => {
  amplitudeProjectHandler = resolve;
});

const logAmplitudeAction = (actionName, props = {}) => {
  if (!(window.location.hostname === 'localhost')) {
    amplitudeInstance.then((instance) => {
      instance.logEvent(actionName, props);
    });
  }
};

const setAmplitudeUser = (userId) => {
  if (!(window.location.hostname === 'localhost')) {
    // Setting up user's identificator for amplitude
    amplitude.getInstance().setUserId(userId);
  }
};

const amplitudeProjectFactory = (publicKey) => {
  const project = amplitude.getInstance();
  project.init(publicKey, null, {
    includeUtm: true,
  });

  return project;
};

const setAmplitudePublicKey = (value) => {
  amplitudeProjectHandler(amplitudeProjectFactory(value));
};

const initAmplitude = () => {
  setAmplitudePublicKey('a06a16440d7dfa1aa334ce8af31359c8');
};

export const AmplitudeContext = createContext(undefined);

const AmlitudeProvider = ({ children }) => (
  <AmplitudeContext.Provider value={{
    initAmplitude,
    logAmplitudeAction,
    setAmplitudeUser,
  }}
  >
    {children}
  </AmplitudeContext.Provider>
);

export const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext);

  if (!context) throw new Error('useAmplitudeContext must be used in a amplitude context provider');

  return context;
};

AmlitudeProvider.propTypes = {
  children: PropTypes.PropTypes.element.isRequired,
};

export default AmlitudeProvider;
