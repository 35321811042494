/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowCircleIcon } from 'static/images/icons/arrow_circle_icon.svg';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useModalsState } from 'context/modals/modalsContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import {
  OPEN_UNAVAILABLE_MODAL,
} from 'context/modals/modalsActions';
// styles
import {
  BotsButton,
} from 'styles/offer/StyledOffer';
import BotsModal from 'components/modals/BotsModal';

const ActualBotsButton = ({ children }) => {
  const [openedDialog, setOpenedDialog] = useState(false);
  const [steamBots, setSteamBots] = useState(null);
  const { getSteamBots } = useFirebaseContext();
  const { modalsDispatch } = useModalsState();
  const { logAmplitudeAction } = useAmplitudeContext();

  const closeDialog = () => {
    setOpenedDialog(false);
  };

  const openDialog = async () => {
    logAmplitudeAction('bot_list_click');
    setOpenedDialog(true);
    try {
      const steamBotsResponse = await getSteamBots();
      if (steamBotsResponse.data) {
        setSteamBots(steamBotsResponse.data);
      }
    } catch (e) {
      console.error('Someting happened while getting steam bots', e);
      closeDialog();
      modalsDispatch({ type: OPEN_UNAVAILABLE_MODAL });
    }
  };

  return (
    <>
      <BotsButton onClick={openDialog} type="button">
        { children }
        <ArrowCircleIcon />
      </BotsButton>
      <BotsModal bots={steamBots} opened={openedDialog} closeAction={closeDialog} />
    </>
  );
};

ActualBotsButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ActualBotsButton;
