import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledCommissionBlock,
} from 'styles/cashout/StyledBody';

const Commissionblock = () => {
  const { t } = useTranslation();
  return (
    <StyledCommissionBlock>
      {t('cashout.commissionBlock')}
    </StyledCommissionBlock>
  );
};

export default Commissionblock;
