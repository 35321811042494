import React from 'react';
import Footer from 'components/footer/FooterContainer';
import { useTranslation } from 'react-i18next';
// styled
import Main, {
  Title,
  Content,
} from 'styles/privacy/StyledPrivacy';
import paragraphsRU from 'mock/termsRU';
import paragraphsEN from 'mock/termsEN';

const TermsContainer = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Main>
        <Title>{t('terms.title')}</Title>
        {i18n.language === 'ru' && (
          paragraphsRU.map((paragraph) => (
            <Content key={paragraph}>
              {paragraph}
            </Content>
          ))
        )}
        {i18n.language === 'en' && (
          paragraphsEN.map((paragraph) => (
            <Content key={paragraph}>
              {paragraph}
            </Content>
          ))
        )}
      </Main>
      <Footer />
    </>
  );
};

export default TermsContainer;
