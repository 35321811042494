import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const StyledFooterContainer = styled(Grid)`
    position: absolute;
    bottom: 0;
    height: 80px;
    width: 100%;
    background: #344557;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    left: calc(50% - 20px);
    width: 48px;
    height: 48px;

    .MuiButton-root {
        min-width: 48px;
    }
`;

export const MoneyBlock = styled(Grid)`
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
`;

export const SkinsAmountBlock = styled(Grid)`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.5;
`;

export const FooterContentContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const PolicyBlock = styled(Grid)`
    max-width: 120px;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    color: rgba(255, 255, 255, .5);

    a {
        color: white;
        text-decoration: none;
    }
`;

export const RestrictedBlock = styled(Grid)`
    color: red;
`;

export default StyledFooterContainer;
