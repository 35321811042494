import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.css';

const Card = (props) => {
  const {
    icon, alt, title, desc, theme, orientation, mr,
  } = props;

  return (
    <div className={`
      ${orientation === 'horizontal' ? styles.card : styles.card_vertical} 
      ${theme === 'black' ? styles.theme_black : styles.theme_white}
      ${mr ? styles.mr : null}
    `}
    >
      <img src={icon} alt={alt} className={styles.icon} />
      <div className={orientation === 'horizontal' ? styles.section_container : styles.section_container_vertical}>
        <section className={orientation === 'horizontal' ? styles.title : styles.title_vertical}>{title}</section>
        <section className={styles.desc}>{desc}</section>
      </div>
    </div>
  );
};

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  mr: PropTypes.bool,
};

Card.defaultProps = {
  mr: false,
};

export default Card;
