import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { device } from 'styles/breakpoints';

export const StyledOfferContainer = styled(Grid)`
  height: calc(100vh - 60px);
`;

export const StyledOfferInformationContainer = styled(Grid)`
  position: relative;
  padding: 41px 0 40px 30px;
  height: 100%;
`;

export const StyledOfferInformationBlock = styled(Grid)`
  height: 100%;
`;

export const StageNumberBlock = styled.span`
  position: absolute;
  left: 30px;
  top: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: .5;
`;

export const HintBlock = styled.div`
  position: absolute;
  bottom: 40px;
  left: 30px;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, .21);
`;

export const BotsButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;

  & > * {
    margin: 0 8px;
  }
`;

export const StageTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
`;

export const StageDisclamer = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  opacity: .5;
  margin-top: 12px;
`;

export const StageTimer = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  margin-top: 24px;
`;

export const StageInputContainer = styled.div`
  margin-top: 32px;
`;

export const SteamButtonContainer = styled.div`
  width: 185px;
`;

export const ConfirmButtonContainer = styled.div`
  width: 149px;
`;

export const ImageContainer = styled(Grid)`
  overflow: hidden;
  height: 100%;
  padding: 40px 0;
`;

export const FirstStageContainer = styled(Grid)`
  position: relative;
  padding: 41px 0 40px 30px;
  height: 100%;
`;

export const SecondStageContainer = styled(FirstStageContainer)`
/* coz I need to be fast as flash */
  padding-top: 100px;

  @media ${device.desktopWide} {
    padding-top: 220px;
  }
`;

export const FirstStageLeftContainer = styled(Grid)`
  height: 100%;
`;

export const FirstStageNumberBlock = styled.span`
  position: absolute;
  left: 30px;
  top: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: .5;
`;

export const FirstStageImageContainer = styled(ImageContainer)`
  padding: 53px 0 0;
`;
