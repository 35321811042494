import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@material-ui/core';
// styles
import {
  StyledCashoutInput,
  StyledInputLabel,
  StyledEditIcon,
} from 'styles/common/StyledInput';

const CashoutInput = (props) => {
  const {
    placeholder,
    confirmed,
    value,
    changeValue,
    type,
    readonly,
    error,
    helperText,
    changeLink,
    // need other function 'validate' later on
    onBlur,
    onEnterPress,
    centeredplaceholder,
  } = props;

  const enterPressHandle = (event) => {
    if (event.key === 'Enter') {
      onEnterPress();
    }
  };

  const getShortValue = (stringValue) => {
    if (stringValue.length > 60 && confirmed) {
      return `${stringValue.slice(0, 60)}...`;
    }
    return stringValue;
  };

  return (
    <FormControl fullWidth error={error}>
      {!confirmed && (
        <StyledInputLabel centeredplaceholder={centeredplaceholder.toString()}>
          {placeholder}
        </StyledInputLabel>
      )}
      <StyledCashoutInput
        value={getShortValue(value)}
        onChange={changeValue}
        disabled={confirmed}
        endAdornment={confirmed && <StyledEditIcon />}
        type={type}
        readOnly={readonly}
        onClick={changeLink}
        onKeyUp={enterPressHandle}
        onBlur={onBlur}
        fullWidth
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CashoutInput.propTypes = {
  placeholder: PropTypes.string,
  confirmed: PropTypes.bool,
  value: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  readonly: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  changeLink: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterPress: PropTypes.func,
  centeredplaceholder: PropTypes.bool,
};

CashoutInput.defaultProps = {
  placeholder: '',
  confirmed: false,
  type: 'text',
  readonly: false,
  error: false,
  helperText: '',
  changeLink: () => {},
  onBlur: () => {},
  onEnterPress: () => {},
  centeredplaceholder: false,
};

export default CashoutInput;
