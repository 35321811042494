import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useOfferState } from 'context/offer/OfferContext';
import { ReactComponent as ThirdStageSuccess } from 'static/images/third_stage_success.svg';
import { ReactComponent as ThirdStageError } from 'static/images/third_stage_error.svg';
// styles
import {
  ImageContainer,
} from 'styles/offer/StyledOffer';
import StageBlock from 'components/common/OfferStageBlock';
import Success from './ThirdStageSuccess';
import Error from './ThirdStageError';
import OfferInformationContainer from '../OfferInformationContainer';

const ThirdStageContainer = (props) => {
  const {
    transaction,
    acceptDateMillis,
  } = props;
  const { offerState } = useOfferState();
  const { t } = useTranslation();

  return (
    <StageBlock>
      <OfferInformationContainer
        transaction={transaction}
        hint={
        offerState.isError || transaction.offerState > 3
          ? ' '
          : `${t('offer.thirdStageContainer.error')}`
      }
      >
        {
          offerState.isError || transaction.offerState > 3
            ? <Error transactionId={(transaction && transaction.id) || offerState.transactionId} />
            : (
              <Success
                transactionId={(transaction && transaction.id)
              || offerState.transactionId}
                acceptDateMillis={acceptDateMillis}
              />
            )
        }
      </OfferInformationContainer>
      <ImageContainer item xs={5} container alignItems="center" justifyContent="center">
        {
          offerState.isError || transaction.offerState > 3
            ? <ThirdStageError />
            : <ThirdStageSuccess />
        }
      </ImageContainer>
    </StageBlock>
  );
};

ThirdStageContainer.propTypes = {
  transaction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  acceptDateMillis: PropTypes.number.isRequired,
};

ThirdStageContainer.defaultProps = {
  transaction: null,
};

export default ThirdStageContainer;
