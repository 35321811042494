import React, { useEffect } from 'react';
import styled from 'styled-components';
import
getCookie,
{ deleteCookie } from 'cookiesHelper';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useGlobalState } from 'context/global/globalContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import {
  SET_ACCOUNT,
} from 'context/global/globalActions';

import Footer from 'components/footer/FooterContainer';
import Starting from './starting/Starting';
import Transactions from './transactions/Transactions';
import Safety from './safety/Safety';
import Sales from './sales/Sales';
import Questions from './questions/Questions';
import Socialmedia from './socialmedia/Socialmedia';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LandingContainer = () => {
  const { authenticate } = useFirebaseContext();
  const { globalDispatch } = useGlobalState();
  const { setAmplitudeUser } = useAmplitudeContext();

  useEffect(() => {
    const cookie = getCookie('custom-token');

    if (cookie) {
      authenticate(cookie)
        .then((data) => {
          setAmplitudeUser(data);
          return data;
        })
        .then((data) => {
          globalDispatch({
            type: SET_ACCOUNT,
            payload: {
              account: data,
            },
          });
        })
        .catch(() => {
          deleteCookie('custom-token');
        });
    }
  }, []);

  return (
    <Main>
      <Starting />
      <Transactions />
      <Safety />
      <Sales />
      <Questions />
      <Socialmedia />
      <Footer />
    </Main>
  );
};

export default LandingContainer;
