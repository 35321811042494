import React from 'react';
import styled from 'styled-components';
import { BotsButton } from 'styles/offer/StyledOffer';
import { useTranslation } from 'react-i18next';
import ActualBotsButton from '../../offer/ActualBotsButton';

import unique from '../../../static/images/landing/unique.svg';
import bots from '../../../static/images/landing/bots.svg';
import confirm from '../../../static/images/landing/confirm.svg';

import Card from '../common/card/Card';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f7;
  width: 100%;
  padding: 120px 0 92px;

  ${BotsButton} {
    background-color: inherit;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding: 14.5px 22px;
    max-width: 209px;
    border: 1px solid #000000;
    color: #000000;

    svg {
      display: none;
    }
  }

  @media (max-width: 790px) {
    padding: 60px 0 40px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 761px;
  width: 100%;
  margin-bottom: 60px;

  @media (max-width: 1080px) {
    margin-left: 36px;
  }

  @media (max-width: 790px) {
    max-width: 280px;
    margin-bottom: 24px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #000000;

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Desc = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #000000;
  opacity: 0.5;

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 60px;

  @media (max-width: 1080px) {
    margin: 0 24px 60px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    margin: 0 24px 24px;
  }
`;

const Safety = () => {
  const { t } = useTranslation();

  return (
    <Main>
      <Header>
        <Title>{t('landing.safetyTitle')}</Title>
        <Desc>{t('landing.safetyDesc')}</Desc>
      </Header>

      <Container>
        <Card
          icon={unique}
          alt="Unique ID"
          title={t('landing.safetyCards.firstTitle')}
          desc={t('landing.safetyCards.firstDesc')}
          mr
          theme="white"
          orientation="vertical"
        />
        <Card
          icon={bots}
          alt="List bots"
          title={t('landing.safetyCards.secondTitle')}
          desc={t('landing.safetyCards.secondDesc')}
          mr
          theme="white"
          orientation="vertical"
        />
        <Card
          icon={confirm}
          alt="Confirm"
          title={t('landing.safetyCards.thirdTitle')}
          desc={t('landing.safetyCards.thirdDesc')}
          theme="white"
          orientation="vertical"
        />
      </Container>

      <ActualBotsButton>{t('landing.safetyButton')}</ActualBotsButton>
    </Main>
  );
};

export default Safety;
