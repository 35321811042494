import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CloseModalIcon } from 'static/images/icons/modal_close_icon.svg';
import {
  Dialog,
  Grid,
} from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #363839;
    border-radius: 10px;
    margin: 0;

    &.MuiDialog-paperWidthLg.MuiDialog-paperScrollBody {
      /* max-width: calc(100% - 32px); */
      max-width: unset;
    }
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  width: ${isMobile ? 288 : 750}px;
  height: 500px;
  background: #363839;
  border-radius: 10px;
  padding: ${isMobile ? '24px 16px' : '48px 24px'};
  text-align: ${!isMobile && 'center'};
`;

export const ModalCloseButton = styled(CloseModalIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: ${isMobile ? 16 : 20}px;
  line-height: ${isMobile ? 22 : 28}px;
`;

export const ModalHint = styled.div`
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 17 : 22}px;
  opacity: .5;
`;

export const BotsContainer = styled.div`
  margin-top: 30px;
  height: 350px;
  overflow-y: scroll;
`;

export const BotLine = styled(Grid)`
  background: #4A4A4A;
  border-radius: 4px;
  height: ${isMobile ? 48 : 44}px;
  margin: 12px 0;
  padding: ${isMobile ? '8px 12px' : '0 16px'};
  max-width: 700px;
`;

export const BotLineLink = styled.div`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;

export const BotName = styled(Grid)`
  font-weight: 600;
  font-size: ${isMobile ? 10 : 14}px;
  line-height: ${isMobile ? 14 : 20}px;
`;

export const BotContent = styled(Grid)`
  font-size: ${isMobile ? 9 : 12}px;
  line-height: ${isMobile ? 14 : 20}px;

  a {
    color: #fff;
    text-decoration: none;
  }
`;
