import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import loadingIcon from 'static/images/icons/loading_icon.png';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CoinIcon } from 'static/images/icons/coin_icon.svg';
// styles
import ItemBlock, {
  ItemPrice,
  ItemUnavailable,
  ItemImage,
  StyledLoadingIcon,
} from 'styles/common/StyledItemBlock';
import {
  IconContainer,
} from 'styles/cashout/StyledBody';

const CashoutItemBlock = (props) => {
  const { item, selected, itemClick } = props;

  const [open, setOpen] = useState(false);
  const { cashoutState } = useCashoutState();
  const { t, i18n } = useTranslation();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getBlockType = () => {
    if (item.loading) {
      return <StyledLoadingIcon src={loadingIcon} />;
    }
    if (item.id) {
      return (
        <>
          { isMobile ? (
            <Tooltip
              title={t('common.cashoutItemBlock')}
              placement="top"
              open={open}
              onClose={handleTooltipClose}
              leaveTouchDelay={3000}
            >
              <ItemImage
                tradable={item.tradable}
                src={item.imageUrl}
                alt={item.name}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={t('common.cashoutItemBlock')}
              placement="top"
              disableInteractive={item.tradable}
              disableHoverListener={item.tradable}
              disableFocusListener={item.tradable}
            >
              <ItemImage
                tradable={item.tradable}
                src={item.imageUrl}
                alt={item.name}
              />
            </Tooltip>
          )}
          {item.tradable ? (
            <ItemPrice>
              <IconContainer>
                { cashoutState.payoutMethod === 'gccoins' || i18n.language === 'en' ? <CoinIcon /> : '₽'}
              </IconContainer>
              {cashoutState.payoutMethod === 'gccoins' || i18n.language === 'en' ? item.priceCoins : item.price }
            </ItemPrice>
          ) : (
            <ItemUnavailable>{t('common.itemUnavailable')}</ItemUnavailable>
          )}
        </>
      );
    }
    return '';
  };

  const currentItemClick = () => {
    if (item.tradable) {
      itemClick(item.id, item.price, item.priceUsd, item.priceCoins, item.name);
    } else if (isMobile) handleTooltipOpen();
  };

  return (
    <ItemBlock
      selected={selected}
      tradable={item.tradable}
      onClick={currentItemClick}
    >
      {getBlockType()}
    </ItemBlock>
  );
};

CashoutItemBlock.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  selected: PropTypes.bool,
  itemClick: PropTypes.func.isRequired,
};

CashoutItemBlock.defaultProps = {
  item: {
    id: '0',
    imageUrl: '',
    price: 0,
    loading: false,
    tradable: true,
  },
  selected: false,
};

export default CashoutItemBlock;
