import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { ReactComponent as ArrowIcon } from 'static/images/icons/arrow_icon.svg';
import {
  Grid,
  Divider,
  Snackbar,
} from '@material-ui/core';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 50px);
  padding: 12px 16px;

  @media (max-height: 500px) {
    height: ${(props) => (props.displayBanner ? 'calc(100% + 10px)' : 'calc(100vh + 10px)')};;
    /* height: calc(100vh + 10px); */
    min-height: 500px;
    overflow: scroll;
  }
`;

export const BodyContent = styled(Grid)`
  width: 100vw;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  margin-bottom: ${(props) => (props.displayBanner ? '100px' : '0')};
`;

export const SwipableContent = styled(SwipeableViews)`
  .react-swipeable-view-container {
    div[data-swipeable="true"] {
      padding: 0 16px;
    }
  }
`;

export const BodyBlockTitle = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
`;

export const ReturnIcon = styled(ArrowIcon)`
  transform: rotateY(180deg);
  margin-right: 9px;
`;

export const SnackbarCloseButton = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

export const StyledSnackbar = styled(Snackbar)`
  margin-bottom: ${(props) => (props.trade ? '80' : '0')}px;
  
  .MuiSnackbarContent-root {
    position: relative;
  }

  .MuiSnackbarContent-message {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    svg {
      margin-right: 8px;
    }
  }
`;

// inventory
export const StyledInventoryContainer = styled(Grid)`
  height: fit-content;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px 16px;
`;

export const TradeUrlLink = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: rgba(255, 255, 255, .5);
  text-decoration: underline;
  cursor: pointer;
`;

export const ContentPaginationContainer = styled(Grid)`
  margin-top: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
`;

export const LeftPaginationButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  width: 30px;
  height: 30px;
  border: none;
  opacity: ${(props) => (props.disabled ? '.3' : '1')};
`;

export const RightPaginationButton = styled(LeftPaginationButton)`
  transform: rotate(-180deg);
`;

// payment
export const StyledPaymentContainer = styled(StyledInventoryContainer)`
  position: relative;
`;

export const PayoutContainerFirstItem = styled(Grid)`
  margin-bottom: 8px;
`;

export const PayoutContainerItem = styled(Grid)`
  margin: 8px 0;
`;

export const PayoutTitle = styled.span`
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  opacity: .5;
`;

export const PayoutContent = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => (props.blue ? '#3378FF' : 'inherit')};
`;

export const PayoutMethodItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 44px;
  margin: 0 6px;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${(props) => (props.selected
    ? 'white'
    : 'rgba(255, 255, 255, .1)')};
  border: ${(props) => (props.selected
    ? '2px solid #3378FF'
    : 'none')};

  svg {
    opacity: ${(props) => (props.selected ? '1' : '.25')};
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

export const PayoutDivider = styled(Divider)`
  background: #fff;
  opacity: .1;
  border-radius: 5px;
  margin: 16px 0;
`;

export const CardNotifications = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  padding-top: 8px;
`;

export const PayoutSelect = styled.select`
  border: none;
  background-color: inherit;
  color: #3378FF;

  &:focus-visible {
    outline: none;
  }
`;

export const PayoutOption = styled.option``;
