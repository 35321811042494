import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  SET_TRADE_OFFER,
  SET_OFFER_STATE,
  SET_OFFER_STAGE,
  SET_TRANSACTION_DATE,
} from './offerActions';

export const OfferContext = createContext(undefined);

const initialState = {
  tradeOfferLink: '',
  transactionId: '',
  offerStage: 1,
  isError: false,
  steamOfferState: null,
  cashoutState: null,
  servingBot: null,
  transactionDate: 0,
};

const setTradeOffer = (state, tradeOfferLink, transactionId) => ({
  ...state,
  tradeOfferLink,
  transactionId,
});

const setOfferState = (state, steamOfferState, cashoutState, servingBot) => ({
  ...state,
  steamOfferState,
  cashoutState,
  servingBot,
});

const setOfferStage = (state, offerStage, isError) => ({
  ...state,
  offerStage,
  isError,
});

const setTransactionDate = (state, transactionDate) => ({
  ...state,
  transactionDate,
});

const stateReducer = (state, action) => {
  const {
    tradeOfferLink,
    transactionId,
    steamOfferState,
    cashoutState,
    offerStage,
    isError,
    servingBot,
    transactionDate,
  } = action.payload;

  switch (action.type) {
    case SET_TRADE_OFFER:
      return setTradeOffer(state, tradeOfferLink, transactionId);
    case SET_OFFER_STATE:
      return setOfferState(state, steamOfferState, cashoutState, servingBot);
    case SET_OFFER_STAGE:
      return setOfferStage(state, offerStage, isError);
    case SET_TRANSACTION_DATE:
      return setTransactionDate(state, transactionDate);
    default:
      return initialState;
  }
};

const StateProvider = ({ children }) => {
  const [offerState, offerDispatch] = useReducer(stateReducer, initialState);

  return (
    <OfferContext.Provider value={{ offerState, offerDispatch }}>
      {children}
    </OfferContext.Provider>
  );
};

export const useOfferState = () => {
  const context = useContext(OfferContext);

  if (!context) throw new Error('useOfferState must be used in a offer state provider');

  return context;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
};

export default StateProvider;
