import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from 'styles/common/StyledPayoutButton';

const CashoutPayoutButton = (props) => {
  const {
    children,
    onClick,
    disabled,
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      variant="outlined"
      fullWidth
    >
      {children}
    </StyledButton>
  );
};

CashoutPayoutButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CashoutPayoutButton.defaultProps = {
  children: '',
  onClick: () => {},
  disabled: false,
};

export default CashoutPayoutButton;
