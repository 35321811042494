/* eslint-disable max-len */
import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  SET_TRADE_LINK,
  SET_INVENTORY_PAGE,
  ADD_SELECTED_ITEM,
  ADD_SELECTED_ITEMS,
  REMOVE_SELECTED_ITEM,
  REMOVE_SELECTED_ITEMS,
  REMOVE_ALL_SELECTED_ITEMS,
  SET_STEAM_STATUS,
  SET_PAYOUT_METHOD,
  SET_MOBILE_INVENTORY_STAGE,
  SET_USER_EMAIL,
  SET_TRADE_STATUS,
} from './cashoutActions';

export const CashoutContext = createContext(undefined);

const initialState = {
  tradeLink: '',
  page: 0,
  selectedItems: new Set(),
  selectedItemsCost: 0,
  selectedItemsCostUsd: 0,
  selectedItemsCostCoins: 0,
  steamEnabled: true,
  payoutMethod: 'card',
  mobileInventoryStage: 0,
  userEmail: '',
  tradeHoldStatus: true,
};

const setTradeLink = (state, tradeLink) => ({
  ...state,
  tradeLink,
});

const setInventoryPage = (state, page) => ({
  ...state,
  page,
});

const addSelectedItem = (state, itemId, itemCost, itemCostUsd, itemCostCoins) => {
  const tmpState = { ...state };
  // don't add blank item
  if (itemId) {
    tmpState.selectedItems.add(itemId);
    tmpState.selectedItemsCost += itemCost;
    tmpState.selectedItemsCostUsd += itemCostUsd;
    tmpState.selectedItemsCostCoins += itemCostCoins;
  }

  return tmpState;
};

const removeSelectedItem = (state, itemId, itemCost, itemCostUsd, itemCostCoins) => {
  const tmpState = { ...state };
  tmpState.selectedItems.delete(itemId);
  tmpState.selectedItemsCost -= itemCost;
  tmpState.selectedItemsCostUsd -= itemCostUsd;
  tmpState.selectedItemsCostCoins -= itemCostCoins;

  if (+(tmpState.selectedItemsCost).toFixed(2) === 0) {
    tmpState.selectedItemsCost = 0;
  }

  return tmpState;
};

const addSelectedItems = (state, itemsIds, itemsCost, itemsCostUsd, tradableItems, itemsCostCoins) => {
  const tmpState = { ...state };
  itemsIds.forEach((itemId, i) => {
    // don't add blank item and selected and non tradable items
    if (itemId && !tmpState.selectedItems.has(itemId) && tradableItems[i]) {
      tmpState.selectedItems.add(itemId);
      tmpState.selectedItemsCost += itemsCost[i];
      tmpState.selectedItemsCostUsd += itemsCostUsd[i];
      tmpState.selectedItemsCostCoins += itemsCostCoins[i];
    }
  });

  return tmpState;
};
const removeSelectedItems = (state, itemsIds, itemsCost, itemsCostUsd, tradableItems, itemsCostCoins) => {
  const tmpState = { ...state };
  itemsIds.forEach((itemId, i) => {
    if (itemId && tradableItems[i]) {
      tmpState.selectedItems.delete(itemId);
      tmpState.selectedItemsCost -= itemsCost[i];
      tmpState.selectedItemsCostUsd -= itemsCostUsd[i];
      tmpState.selectedItemsCostCoins -= itemsCostCoins[i];
    }
  });

  if (+(tmpState.selectedItemsCost).toFixed(2) === 0) {
    tmpState.selectedItemsCost = 0;
  }

  return tmpState;
};

const removeAllSelectedItems = (state) => {
  const tmpState = { ...state };
  tmpState.selectedItems.clear();
  tmpState.selectedItemsCost = 0;
  tmpState.selectedItemsCostUsd = 0;
  tmpState.selectedItemsCostCoins = 0;

  return tmpState;
};

const setSteamStatus = (state, steamEnabled) => ({
  ...state,
  steamEnabled,
});

const setPayoutMethod = (state, payoutMethod) => ({
  ...state,
  payoutMethod,
});

const setMobileInventoryStage = (state, mobileInventoryStage) => ({
  ...state,
  mobileInventoryStage,
});

const setUserEmail = (state, userEmail) => ({
  ...state,
  userEmail,
});

const setTradeHoldStatus = (state, tradeHoldStatus) => ({
  ...state,
  tradeHoldStatus,
});

const stateReducer = (state, action) => {
  const {
    tradeLink,
    page,
    itemId,
    itemsIds,
    itemCost,
    itemCostUsd,
    itemCostCoins,
    itemsCost,
    itemsCostUsd,
    itemsCostCoins,
    steamEnabled,
    tradableItems,
    payoutMethod,
    mobileInventoryStage,
    userEmail,
    tradeHoldStatus,
  } = action.payload;

  switch (action.type) {
    case SET_TRADE_LINK:
      return setTradeLink(state, tradeLink);
    case SET_INVENTORY_PAGE:
      return setInventoryPage(state, page);
    case ADD_SELECTED_ITEM:
      return addSelectedItem(state, itemId, itemCost, itemCostUsd, itemCostCoins);
    case ADD_SELECTED_ITEMS:
      return addSelectedItems(state, itemsIds, itemsCost, itemsCostUsd, tradableItems, itemsCostCoins);
    case REMOVE_SELECTED_ITEM:
      return removeSelectedItem(state, itemId, itemCost, itemCostUsd, itemCostCoins);
    case REMOVE_SELECTED_ITEMS:
      return removeSelectedItems(state, itemsIds, itemsCost, itemsCostUsd, tradableItems, itemsCostCoins);
    case REMOVE_ALL_SELECTED_ITEMS:
      return removeAllSelectedItems(state);
    case SET_STEAM_STATUS:
      return setSteamStatus(state, steamEnabled);
    case SET_PAYOUT_METHOD:
      return setPayoutMethod(state, payoutMethod);
    case SET_MOBILE_INVENTORY_STAGE:
      return setMobileInventoryStage(state, mobileInventoryStage);
    case SET_USER_EMAIL:
      return setUserEmail(state, userEmail);
    case SET_TRADE_STATUS:
      return setTradeHoldStatus(state, tradeHoldStatus);
    default:
      return initialState;
  }
};

const StateProvider = ({ children }) => {
  const [cashoutState, cashoutDispatch] = useReducer(stateReducer, initialState);

  return (
    <CashoutContext.Provider value={{ cashoutState, cashoutDispatch }}>
      {children}
    </CashoutContext.Provider>
  );
};

export const useCashoutState = () => {
  const context = useContext(CashoutContext);

  if (!context) throw new Error('useCashoutState must be used in a cashout state provider');

  return context;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
};

export default StateProvider;
