import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AccordionComponent from '../common/accordion/AccordionComponent';

const Main = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 92px;

  @media (max-width: 790px) {
    padding: 60px 0 40px;
  }
`;

const Header = styled.div`
  max-width: 778px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;

  @media (max-width: 790px) {
    margin: 0 24px 60px;
  }

  @media (max-width: 680px) {
    max-width: 288px;
    margin: 0 0 24px 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #000000;

  @media (max-width: 1080px) {
    margin-left: 24px;
  }

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Desc = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #000000;
  opacity: 0.5;

  @media (max-width: 1080px) {
    margin-left: 24px;
  }

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1080px) {
    margin: 0 24px 0;
  }
`;

const Questions = () => {
  const [expanded, setExpanded] = React.useState(1);
  const { t } = useTranslation();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ScrollableAnchor id="faq">
      <Main>
        <Header>
          <Title>{t('landing.faqTitle')}</Title>
          <Desc>{t('landing.faqDesc')}</Desc>
        </Header>

        <Container>
          <AccordionComponent
            id={1}
            handleChange={handleChange}
            expanded={expanded === 1}
            title={t('landing.faqCards.oneCardTitle')}
          >
            {t('landing.faqCards.oneCardDesc')}
          </AccordionComponent>
          <AccordionComponent
            id={2}
            handleChange={handleChange}
            expanded={expanded === 2}
            title={t('landing.faqCards.twoCardTitle')}
          >
            {t('landing.faqCards.twoCardDesc')}
          </AccordionComponent>
          <AccordionComponent
            id={3}
            handleChange={handleChange}
            expanded={expanded === 3}
            title={t('landing.faqCards.threeCardTitle')}
          >
            {t('landing.faqCards.threeCardDesc')}
          </AccordionComponent>
          <AccordionComponent
            id={4}
            handleChange={handleChange}
            expanded={expanded === 4}
            title={t('landing.faqCards.fourCardTitle')}
          >
            {t('landing.faqCards.fourCardDesc')}
          </AccordionComponent>
          <AccordionComponent
            id={5}
            handleChange={handleChange}
            expanded={expanded === 5}
            title={t('landing.faqCards.fiveCardTitle')}
          >
            {t('landing.faqCards.fiveCardDesc')}
          </AccordionComponent>
          <AccordionComponent
            id={6}
            handleChange={handleChange}
            expanded={expanded === 6}
            title={t('landing.faqCards.sixCardTitle')}
          >
            {t('landing.faqCards.sixCardDesc')}
          </AccordionComponent>
          <AccordionComponent
            id={7}
            handleChange={handleChange}
            expanded={expanded === 7}
            title={t('landing.faqCards.sevenCardTitle')}
          >
            {t('landing.faqCards.sevenCardDesc')}
            <a href="https://steamstat.us/" target="_blank" rel="noreferrer"> https://steamstat.us/</a>
            .
          </AccordionComponent>
        </Container>
      </Main>
    </ScrollableAnchor>
  );
};

export default Questions;
