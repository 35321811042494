/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'components/footer/FooterContainer';
// styled
import Main, {
  Title,
  Content,
  Paragraph,
  Link,
} from 'styles/contacts/StyledContacts';

const PrivacyContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Main>
        <Title>{t('contacts.title')}</Title>
        <Content>
          <Paragraph>
            Company name: Skinsta Limited
          </Paragraph>
          <Paragraph>
            Company number: 121723
          </Paragraph>
          <Paragraph>
            Address: Suite 4, 2nd Floor, The Wing, Montarik House, 3 Bedlam Court, Gibraltar, GX11 1AA, Gibraltar
          </Paragraph>
          <Paragraph>
            Contact:
            {' '}
            <Link href="mailto:skinsta.ltd@yandex.ru">skinsta.ltd@yandex.ru</Link>
          </Paragraph>
        </Content>
      </Main>
      <Footer />
    </>
  );
};

export default PrivacyContainer;
