import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { device } from 'styles/breakpoints';

const StyledStageBlock = styled(Grid)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: 1000px;
  height: 562px;
  overflow: hidden;

  @media ${device.desktopWide} {
    width: 1220px;
    height: 797px;
  }
`;

export default StyledStageBlock;
