import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledHeaderContainer,
  HeaderLogo,
  HeaderBeta,
  HeaderDesc,
  StyledCommissionBlock,
} from 'styles/cashout/StyledHeader';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Navigation from './HeaderNavigation';
import MobileMenu from './MobileMenu';

const HeaderContainer = () => {
  const { t } = useTranslation();
  return (
    <StyledHeaderContainer
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <HeaderBeta>
        <Link to="/">
          <HeaderLogo />
        </Link>
        <HeaderDesc>beta</HeaderDesc>
      </HeaderBeta>
      <BrowserView>
        <Navigation />
      </BrowserView>
      {/* need two mobile views for Grid to set items spread the header */}
      <MobileView>
        <StyledCommissionBlock>
          {t('cashout.commissionBlock')}
        </StyledCommissionBlock>
      </MobileView>
      <MobileView>
        <MobileMenu />
      </MobileView>
    </StyledHeaderContainer>
  );
};

export default HeaderContainer;
