import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const StyledInstructionsContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: ${isMobile ? 'calc(100% - 32px)' : '1000px'};
  margin: ${isMobile ? '16px' : '85px auto'};
  padding: ${isMobile ? '24px 16px' : '42px 30px'};
`;

export const InstructionsTitle = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
  margin-top: 0;
`;

export const InstructionsStepContainer = styled.div`
  text-align: center;
`;

export const InstructionStepTitle = styled.h4`
  font-weight: bold;
  font-size: ${isMobile ? 16 : 20}px;
  line-height: ${isMobile ? 22 : 24}px;
  margin: 24px 0 12px;
`;

export const InstructionsStepContent = styled.div`
  font-weight: 500;
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 17 : 22}px;
  color: rgba(255, 255, 255, .5);
`;

export const InstructionsStepImage = styled.img`
  margin-top: 50px;
  max-width: 801px;
  width: ${isMobile && '100%'};
`;

export const RedirectButtonContainer = styled.div`
  width: 210px;
  margin: 60px auto;
`;

export default StyledInstructionsContainer;
