import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import steam from '../../../static/images/landing/steam.svg';
import skins from '../../../static/images/landing/skins.svg';
import money from '../../../static/images/landing/money.svg';

import Button from '../common/button/Button';
import Card from '../common/card/Card';

const Main = styled.div`
  background-color: #000000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 92px;

  @media (max-width: 790px) {
    padding: 60px 0 40px;
  }

  @media (max-width: 360px) {
    max-width: 320px;
  }
`;

const Header = styled.header`
  max-width: 778px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;

  @media (max-width: 790px) {
    margin: 0 24px 60px;
  }

  @media (max-width: 680px) {
    max-width: 288px;
    margin: 0 0 24px 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #ffffff;

  @media (max-width: 1080px) {
    margin-left: 24px;
  }

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Desc = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #ffffff;
  opacity: 0.5;

  @media (max-width: 1080px) {
    margin-left: 24px;
  }

  @media (max-width: 790px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;

  @media (max-width: 1080px) {
    margin: 0 24px 60px;
  }
`;

const Sales = () => {
  const { t } = useTranslation();

  return (
    <Main>
      <Header>
        <Title>{t('landing.salesTitle')}</Title>
        <Desc>{t('landing.salesDesc')}</Desc>
      </Header>
      <Container>
        <Card
          icon={steam}
          alt="Steam"
          title={t('landing.salesCards.firstTitle')}
          desc={t('landing.salesCards.firstDesc')}
          theme="black"
          orientation="horizontal"
        />
        <Card
          icon={skins}
          alt="Skins"
          title={t('landing.salesCards.secondTitle')}
          desc={t('landing.salesCards.secondDesc')}
          theme="black"
          orientation="horizontal"
        />
        <Card
          icon={money}
          alt="Money"
          title={t('landing.salesCards.thirdTitle')}
          desc={t('landing.salesCards.thirdDesc')}
          theme="black"
          orientation="horizontal"
        />
      </Container>

      <Button text={t('landing.startingButton')} theme="white" link="/cashout" chapter="3moves" />
    </Main>
  );
};

export default Sales;
