const paragraphsEN = [
  `We provide this Privacy Policy to inform you about our policies and procedures regarding the collection, use and disclosure of personal data and other information we receive from users.
  By continuing to use the Site and / or the Service after a notice of changes has been sent to you, you agree to those changes.`,
  'You voluntarily provide us with certain personal data that we collect in order for you to use the functions of the Site and / or Service. If you have any doubts about providing us with such personal data or otherwise using it in any way permitted by this Privacy Policy and our Terms of Use, you should not become a user.',
  'Site and third party sites',
  'This Privacy Policy applies exclusively to the Site. The site may contain links to third party sites that are not owned or controlled by the Supplier. The provider does not control and is not responsible for the content, privacy policy (if any) or the actions of third-party sites. You acknowledge that it is your responsibility to review and understand the privacy policies (if any) of any third party sites that you may access through this Site. By using this Site, you release the Provider from any liability arising from your use of third-party sites to which this Site may link.',
  'Our primary goals in collecting your personal data are to provide and improve the Site and Service and related functions, administer your subscription, and enable users to use and navigate the Site and Service easily. Below are the types and categories of information we collect.',
  'Collected personal data',
  'We collect your personal information in the following ways:',
  'Log Data',
  'When you visit the Site, our servers automatically record information about your use and visit to the Site ("Log Data"). Log Data may include information such as the IP address of your computer, the time and date of your requests, the type of your browser, what software you used, the web page you visited immediately before visiting the Site, access times and dates, your location, information about the mobile network and other statistics. We use this information to monitor and analyze the use of the Site and for the technical administration of the Site in order to improve the functionality of the Site and make it more user-friendly, as well as better adapt it to the needs of users.',
  'Cookie',
  'Like most sites, we use cookies and web logs to track the use of the Site. A cookie is a tiny data file that resides on your computer and allows the provider to recognize you as a user when you return to the site using the same computer and web browser. If your browser settings do not allow the use of cookies, you may not be able to use the Site. Like the information you enter when you sign up, cookies and log data are used to customize your interaction with the Site.',
  `One type of cookie, known as "session" cookies, is used to identify a specific visit to the Site. The session cookie expires a short time later or after the web browser is closed.
  Moreover, the Site may use local shared objects, commonly referred to as "flash cookies", for purposes such as storing user preferences. Here you can manage or refuse your Flash cookies.`,
  'Log files, IP and information about your computer',
  'In accordance with Internet communication standards, when you visit the Site, we automatically receive the URL of the site you came from and the site you visit when you leave the Site. We also obtain the Internet Protocol (IP) address of your computer (or the proxy server you use to access the World Wide Web), your computer\'s operating system and the type of web browser you use, email templates, and your Internet name. provider. This information is used to analyze general trends to help us improve the Site. The link between your IP address and your personal data is not shared with third parties without your prior consent or unless permitted or required by law.',
  'Connection',
  'The provider will contact you via email and notifications posted on the Site, or using other means available through the service, including text and other forms of messaging. Please be aware that you may receive certain emails from the Provider regarding the proper functioning of the Service.',
  'Transfer of information to third parties',
  'The provider takes user privacy very seriously. Therefore, we do not sell, rent or otherwise share your personal data with third parties for marketing purposes. We will share your personal data with third parties only to fulfill your instructions or to provide certain services or information. If you do not agree to this, these third parties do not save, transfer or store any personal data other than to provide these services, and they are bound by confidentiality agreements that restrict their use of such information.',
  'We may provide aggregated anonymous data about the use of the Site to third parties for such purposes that we consider, at our sole discretion, suitable, including potential advertisers on the Site.',
  'Collaboration with third parties',
  'The provider allows other companies to place advertisements on the Site. These companies use technology to deliver the advertisements you see on the Site directly to your browser. In doing so, they can automatically obtain your IP address. Other companies that advertise on the Site may be able to use cookies and / or web beacons to collect information, including information about your use of the Site. We do not control the processes that advertisers use to collect information. However, IP addresses, cookies and web beacons alone are not considered personally identifiable and generally cannot be used to identify people, only machines. Thus, advertisers and others whose advertisements or content may appear on the Site will generally not know who you are unless you provide them with additional information by responding to advertisements, entering into an agreement with them, or in some other way.',
  'Legal disclaimer',
  'The provider works with government, law enforcement, and individuals to enforce and comply with the law. We will disclose any information about you to government or law enforcement agencies or individuals as we, in our sole discretion, deem it necessary or appropriate to respond to claims and litigation (including but not limited to legal action) in order to protect property and rights. Provider or a third party, to protect the safety of the public or any person, and to prevent or terminate activities that we consider illegal or unethical.',
  'We provide representations and information in this section in accordance with European privacy laws, in particular the General European Data Protection Regulation (GDPR). They are only intended for people living in the EEA or Switzerland, so do not rely on the information below if you do not reside in one of those countries.',
  'If you are a visitor from European territories, our legal basis for collecting and using the personal data described in this Privacy Policy will depend on the relevant personal information and the specific context in which we collect it. “European Territories” means the European Economic Area and Switzerland. For the purposes of this Privacy Notice, the term “European Territories” will continue to include the United Kingdom even after the United Kingdom leaves the European Economic Area following Brexit.',
  'However, we usually collect your personal data if it is processed in our legitimate business interests, for example, to administer our platforms and services and to fulfill our contractual obligations as a service provider.',
  'In some cases, we may collect and process personal data based on consent. To the extent that our customers and advertising partners need to collect and transfer or allow us to facilitate the collection and sharing of personal data to provide our services, those parties are responsible for providing the necessary privacy notices and obtaining the necessary consent (s).',
  'If you have questions or need more information about the legal basis on which we collect and use your personal information, including if you want to better understand how our legitimate interests in processing your data relate to your data protection rights and freedoms then contact us using the contact form.',
  'Disclosure of information to others',
  'The Vendor may also disclose your personal data and other information that you provide to another third party as part of a reorganization or sale of assets of a division or company of the Vendor. Any third party to whom we transfer or sell our assets will have the right to continue to use the personal data and other information you provide to us.',
  'Summary information and non-identifying information',
  'We may share aggregated information that does not include personal data, and we may otherwise disclose non-identifying information and Log Data to third parties for industry analysis, demographic profiling, and other purposes. Any aggregated information transmitted in these contexts will not contain your personal data.',
  'International transfer',
  'Your information may be transferred to and stored on computers and servers located outside of your state, province, country, or other state jurisdiction where privacy laws may not be as protective as those in your jurisdiction. Your acceptance of this Privacy Policy, followed by the provision of such information, signifies your consent to such transfer.',
  'Links to other sites',
  'Our site contains links to other sites. If you choose to visit an advertiser by clicking on an ad banner or other type of advertisement, or by clicking on a link from another third party, you will be redirected to the websites of those third parties. The fact that we link to a website or present banner ads or other type of advertising does not constitute an endorsement, authorization or representation of our affiliation with that third party, nor endorse their privacy or information security policies or practices. We do not control the websites of third parties. These other websites may place their own cookies or other files on your computer, collect personal information, or request personal information from you. Other sites follow different guidelines regarding the use or disclosure of the personal information you submit to them. We encourage you to read the privacy policies (if any) or statements of other websites that you visit.',
  'Accessing and changing your information',
  'You can review the information you have provided to us and make any changes to it at any time. Please be aware that even after your change request has been processed, the Provider may keep residual information about you for some time in their backups and / or archived copies of their database. We take your rights seriously and encourage you to exercise them if you deem appropriate. You can exercise these rights by sending us an email using the following contact form',
  'Your commitments',
  'As a user, you have certain obligations imposed by applicable laws and regulations:',
  'You must comply with the terms and conditions of the applicable Privacy Policy and Terms of Use at all times. This includes respecting all intellectual property rights that may belong to third parties.',
  'You must not upload, download or otherwise distribute any information that may be deemed offensive, violent, offensive, racist or xenophobic.',
  'Users have the right to withdraw their consent to the processing of their personal data by notifying the Supplier. Although users provide their personal data to the Provider voluntarily, the refusal to provide them makes it impossible to conclude an agreement with the Provider and access the Site and use the Service.',
  'The provider complies with all the requirements of the Act of August 29, 1997 on the protection of personal data and EU Directive 95/46 / EC, EU Directive 2002/58 / EC and the General Data Protection Regulation (“GDPR”) from the date of its entry into force. ), which are related to the obligations of the party under this Agreement.',
  `The provider has the right to disclose personal data only to authorities authorized in accordance with the provisions of the current law (for example, the court, the police).
  Personal data provided to the Supplier in the contact form and / or in the registration form or in any other way will be used by the Supplier and its affiliates solely for the following purposes: payments, dispute resolution, prevention of prohibited or illegal actions; the user is a party to the agreement on services provided by electronic means); (ii) handling complaints and providing customer support; (iii) contact users and respond to correspondence sent through the contact form; (iv) technical work of the Site and Service; (v) direct marketing (based on user preferences); (vi) keeping statistics of the Site; and (vii) conducting research and analysis aimed at improving the Site and Service.`,
  'Having read the above Privacy Policy, using or accessing the Site, and especially by clicking the "Go to GC.CASH" button, you hereby grant:',
  'Consent to the processing and use by the Supplier of all personal data provided to the Supplier, including data obtained as a result of your use of the Site and / or Service, for the aforementioned purposes.',
  'Consent to the transfer of your personal data abroad, in particular to companies affiliated with the Supplier or related organizations, or the parent company of the Supplier, with their registered office outside Poland, for the purpose of processing in computer systems owned by the Supplier and its business partners ... You understand that the above organizations may be located in countries that do not guarantee the same level of personal data protection as the level guaranteed in Poland.',
  'Agree that your consent remains in effect for the entire period of your use of the Site and / or Service.',
  'Declare and confirm that you have read the Terms of Use, you understand them and will abide by them, both as they are now and with any subsequent amendments.',
  'How to contact us',
  'If you have any questions regarding your personal data or comments on this Privacy Policy, write to us using the support form available in the lower right corner of the site, or by email skinsta.ltd@yandex.ru',
];

export default paragraphsEN;
