import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// styles
import {
  StageTitle,
  StageDisclamer,
  StageInputContainer,
} from 'styles/offer/StyledOffer';
import Input from 'components/common/CashoutInput';

const ThirdStageError = (props) => {
  const {
    transactionId,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <StageTitle>
        {t('offer.thirdStageError.title')}
      </StageTitle>
      <StageDisclamer>
        {t('offer.thirdStageError.desc')}
      </StageDisclamer>
      <StageInputContainer>
        <Input placeholder={t('offer.firstStage.id')} value={transactionId} changeValue={() => { }} readonly />
      </StageInputContainer>
    </>
  );
};

ThirdStageError.propTypes = {
  transactionId: PropTypes.string.isRequired,
};

export default ThirdStageError;
