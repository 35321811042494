const paragraphsEN = [
  'General information',
  `These terms should be read carefully before any use of this Service. 
  By using this Service or by registering an account, you duly agree to these terms. 
  If you do not agree with any provision of these terms, you must not use the Service.`,
  `Gccash.gg reserves the right at any time to update, correct or change any part of these terms and conditions or otherwise change the Service or part of it without prior notice to Users. Any amendment to the terms or service becomes binding upon posting.
  You should be aware that participation in currency exchange in games, as well as any other activity provided or supported by this Service, may be prohibited in some countries. You are solely responsible and responsible for ensuring that access to this Service is in accordance with the laws applicable in your jurisdiction and that the exchange of game currency is not illegal in the territory in which you reside. Any claim against Gccash.gg in relation to the foregoing will be void.`,
  'This Service is not intended for use by anyone under the age of 18.',
  'Gccash.gg does not provide wallet services.',
  'Under no circumstances may you use this Service for any purpose that is illegal, offensive or inappropriate, or that we believe to be fraudulent or dishonest.',
  'These terms and conditions and any questions related to this Service are governed by and construed in accordance with the laws of the registered address of Gccash.gg. Any claims, disputes or disagreements arising from the use of this Service shall be governed exclusively by the district courts of the registered address of Gccash.gg. However, this clause does not exclude or limit the right of Gccash.gg to bring proceedings against any User in any other court of competent jurisdiction.',
  'Gccash.gg will not be held liable for any loss or damage that is believed or believed to be caused by or caused by this Service or its contents. This includes the use or misuse of its content by any person, the inability of any person to connect to or use the Service, delayed transactions or transmissions, communication lines failure, or any errors or omissions in the content.',
  'Copyright',
  'Gccash.gg and / or its licensors are the sole owners of all rights to this layout, codes, structure and organization of the Service, including trade secrets, intellectual property, and any other rights. You must not copy, distribute, publish, modify, or translate any part of this Service without the written permission of Gccash.gg.',
  'The domain Gccash.gg is exclusively owned by Gccash.gg.',
  'All content on the Services, including but not limited to images, graphics, photographs, animations, videos, broadcasts, music, sounds, texts, terms and conditions, belongs exclusively to Gccash.gg or its related partners and is protected by intellectual property or others. rights. By using this Service, you do not acquire any rights to its content.',
  'Privacy Policy',
  'The information we may store about a user includes: any data provided when registering (merging) an account, details of your visits to the website, your responses to research surveys / studies conducted, any information provided by other companies that have received your permission to transfer information about you, your IP address and technical information from your device.',
  'All of the above information may be used for the following purposes: processing your deposits and withdrawals, managing and personalizing your accounts, submitting any survey or research, monitoring any activity on the Website, especially, but not exclusively, to verify that it complies with the Regulations and conditions.',
  'Gccash.gg ensures that your information is securely protected. No information or data will be transferred or sold to third parties. However, please be aware that we may disclose information upon request from public security or relevant authorities to prevent fraud and money laundering.',
  'This website uses cookies to collect information. Cookies help us develop the Service and improve the quality of our services. If you continue to use this Service, you know and agree to our use of cookies.',
  'Registration',
  'Without registration, the User gets access to the Gccash.gg home page.',
  'To gain full access to the content of the Service, the user will be prompted to merge their Steam account. You don\'t need to provide details such as your email address or password to combine your account.',
  'Each user can only have 1 connected Steam account.',
  'If the User loses their login details or for any other reason is unable to access the account, Gccash.gg is not responsible for any loss of funds. Take care of your login details and keep them safe.',
  'Disconnecting and closing an account',
  'Any User can disconnect their personal account at any time. Gccash.gg is not responsible for any damages in this regard.',
  'Gccash.gg reserves the right to freeze, suspend or terminate any user account along with the user\'s games currency. This event may occur as part of our anti-fraud efforts or attempts to exploit loopholes and promotions on our website.',
  `Gccash.gg reserves the right to suspend a client's account without prior notice to the user.
  Deposit Funds The user will be asked to: set the Steam Trade URL generated from the user's Steam account set the public status of the Steam inventory If the user is unable to contribute items to Steam in any way, the possible reason is indicated on the Steam page.
  The total value of all Steam items will be shown in Russian rubles as the User's balance.
  The availability of payment methods is not constant and may change over time.`,
  'Return and cancellation policy',
  'There is no way to return the skins you sold.',
  'The deal cannot be canceled by you at any stage after confirmation in the Steam system. The deal can be canceled by the Company unilaterally at any stage on the basis of the Company\'s expertise without explaining the reasons. The company undertakes to pay the exact amount to your chosen billing account. If the Company is unable to do so for any reason, the Company may ask you to provide an alternative billing account. If for any reason you are unable to provide the Company with alternative billing information, the Company has the right to return the goods to you at its sole discretion. The goods can no longer be returned if the Company has already made a payment to the payment account you provided.',
  'Complaints',
  'Any complaints about us or the Services we provide should be addressed to us first by contacting customer service. You must clearly indicate that you want to file a complaint with us. This helps us distinguish a complaint from a simple request. We will send you a complaint confirmation by mail or email within 48 hours of receiving your complaint in accordance with our complaint process. You can request a copy of our complaint procedure at any time by contacting Customer Service.',
  'Definitions',
  'Service - a site located on the Gccash.gg domain.',
  'User - any person accessing the Service, also referred to as "you".',
  'GC CASH - Skinsta Limited, Company number: 121723, also referred to as "we".',
];

export default paragraphsEN;
