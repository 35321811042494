import styled from 'styled-components';
import {
  InputBase,
  InputLabel,
} from '@material-ui/core';
import { ReactComponent as EditIcon } from 'static/images/icons/edit_icon.svg';
import { ReactComponent as SuccessIcon } from 'static/images/icons/success_icon.svg';

export const StyledInputLabel = styled(InputLabel)`
  top: ${(props) => (props.centeredPlaceholder ? -12 : -10)}px;
  left: 10px;
  color: #fff;
  opacity: .5;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;

  &.Mui-focused {
    color: white;
  }

  &.MuiInputLabel-shrink {
    transform: translate(0, 17px) scale(0.75);
    font-size: 12px;
    line-height: 14px;
  }
`;

export const StyledCashoutInput = styled(InputBase)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  height: 48px;
  padding: 13px 12px 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: ${(props) => (props.endAdornment ? 12 : 0)}px;

  &.Mui-disabled {
    color: #fff;
    opacity: .5;
  }

  &.MuiInputBase-adornedEnd {
    color: #fff;
    opacity: .5;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  fill: #fff;
`;

export const StyledSuccessIcon = styled(SuccessIcon)`
`;
