import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'static/images/icons/close_icon.svg';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useGlobalState } from 'context/global/globalContext';
import { useModalsState } from 'context/modals/modalsContext';
import { CLOSE_PROMO_MODAL } from 'context/modals/modalsActions';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import Input from 'components/common/PromoInput';
import loadingIcon from 'static/images/icons/loading_icon.png';
// styles
import {
  ModalContainer,
  StyledDialog,
  ModalTitle,
  ModalHint,
  PromoContainer,
  HighlightText,
  PromoButton,
  CloseIconContainer,
  Spinner,
} from 'styles/modals/StyledPromoModal';

const PromoModal = () => {
  const { applyPromo } = useFirebaseContext();
  const { modalsState, modalsDispatch } = useModalsState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { globalState } = useGlobalState();

  const [promoValue, setPromoValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setError(false);
  }, [promoValue]);

  useEffect(() => {
    if (globalState.promoRestricted) {
      setLoading(false);
      setSuccess(true);
    }
  }, [globalState.promoRestricted]);

  const handleApplyPromo = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await applyPromo({
        promoCode: promoValue,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setLoading(false);
      setError(true);
    }
  };

  const handleClosePromoModal = () => {
    modalsDispatch({ type: CLOSE_PROMO_MODAL });
  };

  const changePromoValue = (e) => {
    const inputValue = e.target.value;
    setPromoValue(inputValue);
  };

  const promoBlur = () => {
    logAmplitudeAction('enter_promo_code', {
      promoCode: promoValue,
    });
  };

  return (
    <StyledDialog
      open={modalsState.promoModalOpened}
      onClose={handleClosePromoModal}
      scroll="body"
      maxWidth="lg"
    >
      <ModalContainer>
        <ModalTitle>{t('modalPromo.title')}</ModalTitle>
        <ModalHint>
          {t('modalPromo.desc1')}
          {' '}
          <HighlightText>{t('modalPromo.desc2')}</HighlightText>
          {' '}
          {t('modalPromo.desc3')}
        </ModalHint>
        <PromoContainer>
          <Input
            error={error}
            value={promoValue}
            changeValue={changePromoValue}
            placeholder={t('modalPromo.promoInput')}
            type="text"
            helperText={t('modalPromo.promoHelper')}
            onBlur={promoBlur}
            centeredplaceholder
            disabled={loading || success}
            confirmed={success}
          />
          {success ? (
            <PromoButton onClick={handleClosePromoModal}>
              {t('modalPromo.promoClose')}
            </PromoButton>
          ) : (
            <PromoButton onClick={handleApplyPromo} disabled={loading}>
              {loading ? <Spinner src={loadingIcon} alt="" /> : t('modalPromo.promoLoading')}
            </PromoButton>
          )}
        </PromoContainer>
      </ModalContainer>
      <CloseIconContainer onClick={handleClosePromoModal}>
        <CloseIcon />
      </CloseIconContainer>
    </StyledDialog>
  );
};

export default PromoModal;
