import React from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import topLeftBackground from 'static/images/bg_top_left.svg';
import bottomRightBackground from 'static/images/bg_bottom_right.svg';

const darkTheme = createMuiTheme({
  palette: {
    background: {
      default: '#202020',
    },
    text: {
      primary: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          position: 'relative',
          minHeight: '100vh',

          '&::before': {
            content: '\' \'',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundImage: `url(${topLeftBackground}), url(${bottomRightBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'left, right',
            backgroundPositionY: 'top, bottom',
            backgroundSize: 'cover',
          },
        },
        '#root': {
          minHeight: '100vh',
        },
        '.phone-drop-down ul': {
          backgroundColor: '#363839',
        },
      },
    },
  },
});

const DarkTheme = (props) => {
  const {
    children,
  } = props;

  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />
      { children }
    </MuiThemeProvider>
  );
};

DarkTheme.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DarkTheme;
