import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// styles
import {
  StageTitle,
  StageDisclamer,
  StageInputContainer,
  StageTimer,
} from 'styles/offer/StyledOffer';
import { useOfferState } from 'context/offer/OfferContext';
import Input from 'components/common/CashoutInput';

const ThirdStageSuccess = (props) => {
  const {
    transactionId,
    acceptDateMillis,
  } = props;

  const { offerState } = useOfferState();
  const { t } = useTranslation();

  const startTimer = () => {
    const dateMillis = offerState.transactionDate || acceptDateMillis;
    const deadline = new Date(dateMillis).getTime() + 60 * 60 * 24 * 1000;
    const diff = deadline - new Date();
    const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
    const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
    const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const [timer, setTimer] = useState(startTimer());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(startTimer());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <StageTitle>
        {t('offer.thirdStageSuccess.title')}
      </StageTitle>
      <StageDisclamer>
        {t('offer.thirdStageSuccess.desc')}
      </StageDisclamer>
      <StageTimer>
        {offerState.transactionDate || acceptDateMillis ? timer : '--:--:--'}
      </StageTimer>
      <StageInputContainer>
        <Input placeholder={t('offer.firstStage.id')} value={transactionId} changeValue={() => { }} readonly />
      </StageInputContainer>
    </>
  );
};

ThirdStageSuccess.propTypes = {
  transactionId: PropTypes.string.isRequired,
  acceptDateMillis: PropTypes.number.isRequired,
};

export default ThirdStageSuccess;
