import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// styles
import {
  OfferContainer,
  StageNumber,
  StageTitle,
  StageDescription,
  StageInputContainer,
  OfferThirdStageContainer,
  CashoutButtonContainer,
  StyledThirdStageSucces,
} from 'styles/mobile/offer/StyledOffer';
import Button from 'components/common/CashoutPayoutButton';

const FourthStage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const backToCashout = () => {
    history.push('/cashout');
  };

  return (
    <OfferContainer>
      <OfferThirdStageContainer>
        <StageNumber>
          { t('step') }
          4
        </StageNumber>
        <StageTitle>
          {t('offer.fourthStage.title')}
        </StageTitle>
        <StageDescription>
          {t('offer.fourthStage.desc')}
        </StageDescription>
        <StageInputContainer>
          <CashoutButtonContainer>
            <Button onClick={backToCashout}>
              {t('offer.fourthStage.button')}
            </Button>
          </CashoutButtonContainer>
        </StageInputContainer>
        <StyledThirdStageSucces />
      </OfferThirdStageContainer>
    </OfferContainer>
  );
};

export default FourthStage;
