import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import firebaseConfig from 'firebaseConfig.json';
import stagingFirebaseConfig from 'firebaseConfig_staging.json';
import firebase from 'firebase/app';
import { deleteCookie } from 'cookiesHelper';

if (process.env.REACT_APP_NODE_ENV === 'staging') {
  firebase.initializeApp(stagingFirebaseConfig);
} else {
  firebase.initializeApp(firebaseConfig);
}
const functions = firebase.functions();
// getting functions we need
const getUserInventory = functions.httpsCallable('getUserInventory');
const requestCashout = functions.httpsCallable('requestCashout');
const getSteamBots = functions.httpsCallable('getOurSteamBots');
const getActiveTransactions = firebase.functions().httpsCallable('getActiveTransactions');
const getTransactions = firebase.functions().httpsCallable('getTransactions');
// to use add '.doc(TRANSACTION_ID)' to it
const cashoutTransaction = firebase.firestore().collection('cashoutTransactions');
const appStatus = firebase.firestore().collection('admin').doc('app');
// to use add '.doc(STEAM_ID)' to it
const userData = firebase.firestore().collection('users');
const applyPromo = functions.httpsCallable('applyPromoCode');

const authenticate = async (token) => {
  await firebase.auth().signInWithCustomToken(token);

  return new Promise((res) => {
    res(firebase.auth().currentUser.uid);
  });
};

const signOut = async () => {
  deleteCookie('custom-token');
  await functions.httpsCallable('signOut');
  await firebase.auth().signOut();
  window.location.reload();
};

export const FirebaseContext = createContext(undefined);

const FirebaseProvider = ({ children }) => (
  <FirebaseContext.Provider value={{
    getUserInventory,
    requestCashout,
    getSteamBots,
    cashoutTransaction,
    appStatus,
    getActiveTransactions,
    getTransactions,
    userData,
    applyPromo,
    authenticate,
    signOut,
  }}
  >
    {children}
  </FirebaseContext.Provider>
);

export const useFirebaseContext = () => {
  const context = useContext(FirebaseContext);

  if (!context) throw new Error('useFirebaseContext must be used in a firebase context provider');

  return context;
};

FirebaseProvider.propTypes = {
  children: PropTypes.PropTypes.element.isRequired,
};

export default FirebaseProvider;
