import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalState } from 'context/global/globalContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useTranslation } from 'react-i18next';
// styles
import {
  DropDownButton,
  StyledDropDownIcon,
  StyledAccountMenu,
  AccountMenuItem,
} from 'styles/cashout/StyledHeader';

const MenuContainer = () => {
  const [anchor, setAnchor] = useState(null);
  const { globalState } = useGlobalState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { signOut } = useFirebaseContext();
  const location = useLocation();
  const { t } = useTranslation();

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const login = () => {
    logAmplitudeAction('steam_login_click', {
      source: location.pathname === '/cashout'
        ? 'cashout'
        : 'landing',
    });
    window.location = '/_auth/steam';
  };

  const logout = () => {
    logAmplitudeAction('sign_out');
    signOut();
  };

  if (globalState.steamAccount) {
    return (
      <>
        <DropDownButton onClick={openMenu}>
          {`ID ${globalState.steamAccount} `}
          <StyledDropDownIcon />
        </DropDownButton>
        <StyledAccountMenu
          anchorEl={anchor}
          keepMounted
          open={!!anchor}
          onClose={closeMenu}
        >
          {`ID ${globalState.steamAccount}`}
          <AccountMenuItem onClick={logout}>{t('header.out')}</AccountMenuItem>
        </StyledAccountMenu>
      </>
    );
  }
  return (
    <DropDownButton onClick={login}>{t('header.entry')}</DropDownButton>
  );
};

export default MenuContainer;
