import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PaginationIcon } from 'static/images/icons/pagination_icon.svg';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useTranslation } from 'react-i18next';
import {
  SET_INVENTORY_PAGE,
  ADD_SELECTED_ITEMS,
  REMOVE_SELECTED_ITEMS,
} from 'context/cashout/cashoutActions';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
// styles
import {
  ContentPaginationContainer,
  LeftPaginationButton,
  RightPaginationButton,
  AllPaginationButton,
} from 'styles/cashout/StyledBody';

const ContentPagination = (props) => {
  const {
    totalItems,
    currentItemsIds,
    currentItemsCost,
    currentItemsCostUsd,
    currentItemsCostCoins,
    currentTradableItems,
  } = props;
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const currentPage = cashoutState.page;
  const totalPages = Math.ceil(totalItems / 12);
  const { t } = useTranslation();

  const increasePage = () => {
    cashoutDispatch({ type: SET_INVENTORY_PAGE, payload: { page: currentPage + 1 } });
  };

  const decreasePage = () => {
    cashoutDispatch({ type: SET_INVENTORY_PAGE, payload: { page: currentPage - 1 } });
  };

  const checkAllTradableItemsSelected = () => {
    let allTradableItemsSelected = true;
    currentItemsIds.forEach((itemId, i) => {
      if (!cashoutState.selectedItems.has(itemId) && itemId && currentTradableItems[i]) {
        allTradableItemsSelected = false;
      }
    });

    return allTradableItemsSelected;
  };

  const addAllItems = () => {
    cashoutDispatch({
      type: ADD_SELECTED_ITEMS,
      payload: {
        itemsIds: currentItemsIds,
        itemsCost: currentItemsCost,
        itemsCostUsd: currentItemsCostUsd,
        itemsCostCoins: currentItemsCostCoins,
        tradableItems: currentTradableItems,
      },
    });
  };

  const removeAllItems = () => {
    cashoutDispatch({
      type: REMOVE_SELECTED_ITEMS,
      payload: {
        itemsIds: currentItemsIds,
        itemsCost: currentItemsCost,
        itemsCostUsd: currentItemsCostUsd,
        itemsCostCoins: currentItemsCostCoins,
        tradableItems: currentTradableItems,
      },
    });
  };

  const selectAllButtonClick = () => {
    logAmplitudeAction('choose_everything');
    if (checkAllTradableItemsSelected()) {
      removeAllItems();
    } else {
      addAllItems();
    }
  };

  return (
    <ContentPaginationContainer container justifyContent="space-between">
      <div>
        <LeftPaginationButton type="button" onClick={decreasePage} disabled={currentPage === 0}>
          <PaginationIcon />
        </LeftPaginationButton>
        { `${currentPage + 1} ${t('cashout.contentPaginationOf')} ${totalPages || 1}` }
        <RightPaginationButton
          type="button"
          onClick={increasePage}
          disabled={currentPage === (totalPages ? totalPages - 1 : 0)}
        >
          <PaginationIcon />
        </RightPaginationButton>
      </div>
      <AllPaginationButton onClick={selectAllButtonClick} type="button">
        {t('cashout.contentPagination')}
      </AllPaginationButton>
    </ContentPaginationContainer>
  );
};

ContentPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  currentItemsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentItemsCost: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentItemsCostUsd: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentItemsCostCoins: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentTradableItems: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

export default ContentPagination;
