/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import loadingIcon from 'static/images/icons/loading_icon.png';
import { useHistory, Link } from 'react-router-dom';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useOfferState } from 'context/offer/OfferContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useWalletState } from 'context/wallet/walletContext';
import { useGlobalState } from 'context/global/globalContext';
import { ReactComponent as RestrictedErrorIcon } from 'static/images/icons/restricted_error_icon.svg';
import { SET_TRADE_OFFER, SET_OFFER_STATE } from 'context/offer/offerActions';
import { SET_MOBILE_INVENTORY_STAGE } from 'context/cashout/cashoutActions';
import emailRegex from 'emailRegex';
// styles
import StyledFooterContainer, {
  ButtonContainer,
  MoneyBlock,
  SkinsAmountBlock,
  FooterContentContainer,
  PolicyBlock,
} from 'styles/mobile/cashout/footer/StyledFooter';
import {
  SnackbarCloseButton,
  StyledSnackbar,
} from 'styles/mobile/cashout/StyledBody';

import { Spinner, SomeError, IconContainer } from 'styles/cashout/StyledBody';
import Button from 'components/common/CashoutFilledButton';
import { ReactComponent as CoinIcon } from 'static/images/icons/coin_icon.svg';
import { ReactComponent as ArrowIcon } from 'static/images/icons/arrow_icon.svg';
import restrictedUsers from 'restrictedUsers.json';

const restrictedEmails = restrictedUsers.emails;
const restrictedPhones = restrictedUsers.phones;

const FooterContainer = () => {
  const [unknownError, setUnknownError] = useState(false);
  const [restrictedError, setRestrictedError] = useState(false);
  const [restrictedErrorNotification, setRestrictedErrorNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minimalPayoutOverflow, setMinimalPayoutOverflow] = useState(false);
  const [ableToConfirm, setAbleToConfirm] = useState(false);
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { requestCashout, cashoutTransaction } = useFirebaseContext();
  const { offerDispatch } = useOfferState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { walletState } = useWalletState();
  const history = useHistory();
  const { t } = useTranslation();

  const closeMinimalPayout = () => {
    setRestrictedErrorNotification(false);
  };

  const confirmCashout = async () => {
    logAmplitudeAction('get_money_click', {
      method: cashoutState.payoutMethod,
    });
    setLoading(true);
    try {
      const requestResponse = await requestCashout({
        tradeUrl: cashoutState.tradeLink,
        items: Array.from(cashoutState.selectedItems),
        walletNumber: walletState.walletValue,
        email: cashoutState.userEmail,
        walletType: cashoutState.payoutMethod,
      });
      if (requestResponse.data) {
        const responseData = requestResponse.data;
        offerDispatch({
          type: SET_TRADE_OFFER,
          payload: {
            tradeOfferLink: responseData.tradeOfferLink,
            transactionId: responseData.transactionId,
          },
        });
        cashoutTransaction.doc(responseData.transactionId).onSnapshot(
          {
            includeMetadataChanges: true,
          },
          (doc) => {
            const docData = doc.data();
            offerDispatch({
              type: SET_OFFER_STATE,
              payload: {
                steamOfferState: docData.offerState,
                cashoutState: docData.state,
                servingBot: docData.bot,
              },
            });
          },
        );
        history.push(`/offer/${responseData.transactionId}`);
      }
    } catch (e) {
      setUnknownError(true);
      console.error('something went wrong while requesting cashout', e.message);
    }
    setLoading(false);
  };

  const getFooterContent = () => {
    const [bonus, setBonus] = useState(0);
    const { globalState } = useGlobalState();

    const bonusSum = () => {
      const percent = globalState.cashoutBonusPercent / 100;
      const sum = (cashoutState.selectedItemsCost * percent).toFixed(2);
      setBonus(sum);
    };

    useEffect(() => {
      bonusSum();
    }, [cashoutState.selectedItemsCost]);

    if (cashoutState.mobileInventoryStage) {
      return (
        <PolicyBlock>
          <SomeError hidden={!unknownError}>
            {t('cashout.somethingError0')}
          </SomeError>
          {t('cashout.payoutForm.confirmDesc1')}
          {' '}
          <Link to="/terms" target="_blank">
            {t('cashout.payoutForm.confirmDesc2')}
          </Link>
        </PolicyBlock>
      );
    }

    return (
      <Grid container direction="column" center>
        <MoneyBlock item>
          <IconContainer>
            {cashoutState.payoutMethod === 'gccoins' ? <CoinIcon /> : '₽'}
          </IconContainer>
          {(
            Number(
              cashoutState.payoutMethod === 'gccoins'
                ? cashoutState.selectedItemsCostCoins
                : cashoutState.selectedItemsCost,
            ) + Number(bonus)
          ).toFixed(2)}
        </MoneyBlock>
        <SkinsAmountBlock item>
          {`${t('cashout.numbersSkins')} ${cashoutState.selectedItems.size}`}
        </SkinsAmountBlock>
      </Grid>
    );
  };

  const buttonHandleClick = () => {
    if (cashoutState.mobileInventoryStage) {
      confirmCashout();
    } else {
      cashoutDispatch({
        type: SET_MOBILE_INVENTORY_STAGE,
        payload: {
          ...cashoutState,
          mobileInventoryStage: cashoutState.mobileInventoryStage ? 0 : 1,
        },
      });
    }
  };

  useEffect(() => {
    if (
      cashoutState.selectedItems.size > 0
      && cashoutState.payoutMethod === 'qiwi'
      && cashoutState.selectedItemsCost < 100
    ) {
      setMinimalPayoutOverflow(true);
    } else if (
      cashoutState.selectedItems.size > 0
      && cashoutState.payoutMethod === 'card'
      && cashoutState.selectedItemsCost < 1000
    ) {
      setMinimalPayoutOverflow(true);
    } else {
      setMinimalPayoutOverflow(false);
    }
  }, [
    cashoutState.selectedItemsCost,
    cashoutState.selectedItems,
    cashoutState.payoutMethod,
  ]);

  useEffect(() => {
    const tradeUrlExists = !!cashoutState.tradeLink;
    const selectedItemsExist = !!cashoutState.selectedItems.size;
    const emailExists = emailRegex.test(cashoutState.userEmail) && !!cashoutState.userEmail;
    const walletExists = walletState.walletValidity;
    const tradeHoldStatus = !!cashoutState.tradeHoldStatus;
    if (cashoutState.payoutMethod === 'gccoins') {
      return setAbleToConfirm(
        tradeUrlExists
          && selectedItemsExist
          && emailExists
          && !minimalPayoutOverflow
          && !loading
          && tradeHoldStatus,
      );
    }
    return setAbleToConfirm(
      tradeUrlExists
        && selectedItemsExist
        && emailExists
        && walletExists
        && !minimalPayoutOverflow
        && !loading
        && tradeHoldStatus,
    );
  }, [
    cashoutState.tradeLink,
    cashoutState.selectedItems.size,
    cashoutState.userEmail,
    walletState.walletValidity,
    minimalPayoutOverflow,
    loading,
    cashoutState.tradeHoldStatus,
  ]);

  useEffect(() => {
    if (
      restrictedEmails.includes(cashoutState.userEmail)
      || restrictedPhones.includes(walletState.walletValue)
    ) {
      setRestrictedError(true);
      setRestrictedErrorNotification(true);
    }
  }, [cashoutState.userEmail, walletState.walletValue]);

  return (
    <StyledFooterContainer
      container
      justifyContent="space-between"
      wrap="nowrap"
    >
      <FooterContentContainer>{getFooterContent()}</FooterContentContainer>
      <ButtonContainer>
        <Button
          onClick={buttonHandleClick}
          disabled={
            (cashoutState.mobileInventoryStage && !ableToConfirm)
            || restrictedError
          }
        >
          {!loading ? <ArrowIcon /> : <Spinner src={loadingIcon} alt="" />}
        </Button>
      </ButtonContainer>
      <StyledSnackbar
        open={restrictedErrorNotification}
        message={(
          <>
            <RestrictedErrorIcon />
            <SnackbarCloseButton onClick={closeMinimalPayout}>
              X
            </SnackbarCloseButton>
            {t('cashout.payoutForm.errorPhoneNumber')}
          </>
        )}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      />

      <StyledSnackbar
        trade="true"
        open={!cashoutState.tradeHoldStatus}
        message={(
          <>
            {t('cashout.payoutForm.errorTradeHold')}
          </>
        )}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </StyledFooterContainer>
  );
};

export default FooterContainer;
