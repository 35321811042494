import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useOfferState } from 'context/offer/OfferContext';
// styles
import {
  StyledOfferInformationContainer,
  StageNumberBlock,
  StyledOfferInformationBlock,
  HintBlock,
} from 'styles/offer/StyledOffer';
import BotsButton from './ActualBotsButton';

const OfferInformationContainer = (props) => {
  const {
    children,
    hint,
    transaction,
  } = props;
  const { offerState } = useOfferState();
  const { t } = useTranslation();

  const getStage = () => {
    if (transaction) {
      if (transaction.state !== 4) {
        return '3';
      }
      return '4';
    }
    return offerState.offerStage;
  };

  return (
    <StyledOfferInformationContainer item xs={7}>
      <StyledOfferInformationBlock container direction="column" justifyContent="center">
        <StageNumberBlock>
          {t('step')}
          {getStage()}
        </StageNumberBlock>
        {children}
        <HintBlock>
          {
            hint || (
              <BotsButton>{t('offer.actualBots.children')}</BotsButton>
            )
          }
        </HintBlock>
      </StyledOfferInformationBlock>
    </StyledOfferInformationContainer>
  );
};

OfferInformationContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
  hint: PropTypes.string,
  transaction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
};

OfferInformationContainer.defaultProps = {
  hint: '',
  transaction: null,
};

export default OfferInformationContainer;
