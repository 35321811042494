import React from 'react';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useTranslation } from 'react-i18next';
import {
  SET_MOBILE_INVENTORY_STAGE,
} from 'context/cashout/cashoutActions';
// styles
import {
  StyledPaymentContainer,
  BodyBlockTitle,
  PayoutDivider,
  ReturnIcon,
} from 'styles/mobile/cashout/StyledBody';
import PayoutForm from 'components/cashout/body/payment/PayoutForm';
import Payout from './PayoutContainer';

const PaymentContainer = () => {
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { t } = useTranslation();

  const returnToInventory = () => {
    cashoutDispatch({
      type: SET_MOBILE_INVENTORY_STAGE,
      payload: {
        ...cashoutState,
        mobileInventoryStage: cashoutState.mobileInventoryStage ? 0 : 1,
      },
    });
  };

  return (
    <StyledPaymentContainer>
      <BodyBlockTitle>
        <ReturnIcon onClick={returnToInventory} />
        {' '}
        {t('cashout.paymentContainer')}
      </BodyBlockTitle>
      <Payout />
      <PayoutDivider />
      <PayoutForm />
    </StyledPaymentContainer>
  );
};

export default PaymentContainer;
