import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { useOfferState } from 'context/offer/OfferContext';
import { SET_OFFER_STAGE } from 'context/offer/offerActions';
import FirstStage from './FirstStage';
import MobileFirstStage from './mobile/FirstStage';
import MobileThirdStage from './mobile/ThirdStage';
import MobileFourthStage from './mobile/FourthStage';
import ThirdStage from './thirdStage/ThirdStageContainer';
import FourthStage from './FourthStage';

const OfferStage = (props) => {
  // ofcourse we need to wrap this into context, but for now using nesting
  const {
    transaction,
    acceptDateMillis,
  } = props;
  const [stage, setStage] = useState(1);
  const { offerState, offerDispatch } = useOfferState();

  useEffect(() => {
    if (transaction && offerState.offerStage === 1) {
      setStage(transaction.state);
    } else {
      setStage(offerState.offerStage);
    }
  }, [offerState, transaction]);

  useEffect(() => {
    let offerStage = 1;
    const { cashoutState } = offerState;

    if (cashoutState === 3 || cashoutState === 2) {
      offerStage = 3;
    }
    if (cashoutState === 4) {
      offerStage = 4;
    }

    offerDispatch({
      type: SET_OFFER_STAGE,
      payload: {
        offerStage,
        isError: cashoutState === 3,
      },
    });
  }, [offerState.cashoutState]);

  switch (stage) {
    case 1:
      return isMobile
        ? <MobileFirstStage transaction={transaction} />
        : <FirstStage transaction={transaction} />;
    case 2:
    case 3:
      return isMobile
        ? <MobileThirdStage transaction={transaction} acceptDateMillis={acceptDateMillis} />
        : <ThirdStage transaction={transaction} acceptDateMillis={acceptDateMillis} />;
    case 4:
      return isMobile
        ? <MobileFourthStage transatction={transaction} />
        : <FourthStage transaction={transaction} />;
    default:
      return <FirstStage transaction={transaction} />;
  }
};

OfferStage.propTypes = {
  transaction: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  acceptDateMillis: PropTypes.number.isRequired,
};

OfferStage.defaultProps = {
  transaction: null,
};

export default OfferStage;
