import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import notification from 'static/images/landing/notification.png';
import notificationEN from 'static/images/landing/notificationEN.svg';
import Button from '../common/button/Button';

const Main = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 100px;

  @media (max-width: 680px) {
    padding: 60px 0 40px;
  }
`;

const Header = styled.div`
  max-width: 692px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;

  @media (max-width: 1080px) {
    align-items: center;
    margin-bottom: 24px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #000000;

  @media (max-width: 680px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Desc = styled.div`
  font-weight: bold;
  font-size: 55px;
  line-height: 108%;
  color: #000000;
  opacity: 0.5;

  @media (max-width: 680px) {
    font-size: 24px;
    line-height: 108%;
  }
`;

const Container = styled.div`
  display: flex;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  max-width: 378px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(210.15deg, #102648 0%, #1e1e1e 100%);
  border-radius: 20px;
  padding: 30px 45px 0 30px;

  @media (max-width: 1080px) {
    padding: 36px 0;
    align-items: center;
    max-width: 572px;
    width: 100%;
    margin: 20px 16px;
  }

  @media (max-width: 600px) {
    max-width: 288px;
  }
`;

const CardDesc = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.5);
  margin: 94px 0 106px;
  max-width: 288px;
  width: 100%;

  @media (max-width: 1080px) {
    margin: 0 0 24px;
  }

  @media (max-width: 680px) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 602px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  margin-right: 20px;

  @media (max-width: 1080px) {
    margin-right: 0;
    padding: 0 16px;
  }

  @media (max-width: 600px) {
    max-width: 320px;
  }
`;

const Transactions = () => {
  const { t, i18n } = useTranslation();

  return (
    <Main>
      <Header>
        <Title>{t('landing.transactionsTitle')}</Title>
        <Desc>{t('landing.transactionsDesc')}</Desc>
      </Header>
      <Container>
        {i18n.language === 'ru' ? (
          <Image src={notification} alt="Notification" />
        ) : (
          <Image src={notificationEN} alt="Notification" />
        )}
        <Card>
          <CardDesc>
            {t('landing.transactionsCardTitle1')}
            {' '}
            <span style={{ color: '#ffffff' }}>{t('landing.transactionsCardTitle2')}</span>
            {' '}
            {t('landing.transactionsCardTitle3')}
          </CardDesc>
          <Button
            text={t('landing.startingButton')}
            theme="white"
            link="/cashout"
            chapter="phone"
          />
        </Card>
      </Container>
    </Main>
  );
};

export default Transactions;
