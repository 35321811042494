export const SET_TRADE_LINK = 'SET_TRADE_LINK';
export const SET_INVENTORY_PAGE = 'SET_INVENTORY_PAGE';
export const ADD_SELECTED_ITEM = 'ADD_SELECTED_ITEM';
export const ADD_SELECTED_ITEMS = 'ADD_SELECTED_ITEMS';
export const REMOVE_SELECTED_ITEM = 'REMOVE_SELETED_ITEM';
export const REMOVE_SELECTED_ITEMS = 'REMOVE_SELECTED_ITEMS';
export const REMOVE_ALL_SELECTED_ITEMS = 'REMOVE_ALL_SELECTED_ITEMS';
export const SET_STEAM_STATUS = 'SET_STEAM_STATUS';
export const SET_PAYOUT_METHOD = 'SET_PAYOUT_METHOD';
export const SET_MOBILE_INVENTORY_STAGE = 'SET_MOBILE_INVENTORY_STAGE';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_TRADE_STATUS = 'SET_TRADE_STATUS';
