/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
  const tmpOptions = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (tmpOptions.expires instanceof Date) {
    tmpOptions.expires = tmpOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)} = ${encodeURIComponent(value)}`;

  for (const optionKey in tmpOptions) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1,
  });
};

export default getCookie;
