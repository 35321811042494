import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  PayoutDivider,
} from 'styles/cashout/StyledBody';
import {
  Grid,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';
import { ReactComponent as Logo } from 'static/images/logos/logo.svg';
import { ReactComponent as DropDownIcon } from 'static/images/icons/drop_down_icon.svg';
import { ReactComponent as MenuBurger } from 'static/images/icons/menu_burger_icon.svg';

const navigationLinks = css`
  color: #fff;
  text-decoration: none;
  margin: 0 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledHeaderContainer = styled(Grid)`
  background-color: rgba(255, 255, 255, 0.1);
  min-height: ${isMobile ? 50 : 60}px;
  font-size: 13px;
  line-height: 24px;
`;

export const HeaderLogo = styled(Logo)`
  margin: 0 8px 0 16px;
`;

export const HeaderBeta = styled.div`
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  user-select: none;
`;

export const HeaderDesc = styled.div`
  text-decoration: none !important;
  border: none;
  padding-bottom: 2px;
`;

export const StyledLink = styled.a`
  ${navigationLinks}
`;

export const StyledRouterLink = styled(Link)`
  ${navigationLinks}
`;

export const StyledDivider = styled(Divider)`
  background-color: white;
  margin: 0 12px;
`;

export const DividerContainer = styled.div`
  height: 60px;
`;

export const DropDownButton = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0 23px 0 12px;
  cursor: pointer;
`;

export const StyledDropDownIcon = styled(DropDownIcon)`
  margin: 0 8px;
`;

export const StyledMenuBurger = styled(MenuBurger)`
  margin: 0 24px;
`;

export const StyledAccountMenu = styled(Menu)`
  backdrop-filter: blur(20px);
  margin-top: ${isMobile && '48px'};

  .MuiPaper-root {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 4px 16px;
    margin-top: ${!isMobile && '48px'};
  }
`;

export const AccountMenuItem = styled(MenuItem)`
  color: rgba(255, 255, 255, .5);
  padding: 0;
  padding-top: 12px;
  font-size: 16px;
  line-height: 22px;

  &, &:hover, &.Mui-focusVisible {
    background-color: transparent;
  }
`;

export const StyledCommissionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 31px;
  background: #FFD964;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.71);
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.3px;
`;

export const MenuDivider = styled(PayoutDivider)`
  margin: 0;
  margin-top: 8px;
`;

export const MobileMenuItem = styled(MenuItem)`
  color: rgba(255, 255, 255, .5);
  padding: 0;
  padding-top: 12px;
  font-size: 12px;
  line-height: 17px;
  min-height: 18px;
`;
