/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useHistory, Link } from 'react-router-dom';
import Input from 'components/common/CashoutInput';
import loadingIcon from 'static/images/icons/loading_icon.png';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useOfferState } from 'context/offer/OfferContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useWalletState } from 'context/wallet/walletContext';
import { useGlobalState } from 'context/global/globalContext';
import { ReactComponent as RestrictedErrorIcon } from 'static/images/icons/restricted_error_icon.svg';
import {
  SET_TRADE_OFFER,
  SET_OFFER_STATE,
  SET_TRANSACTION_DATE,
} from 'context/offer/offerActions';
import {
  SET_USER_EMAIL,
} from 'context/cashout/cashoutActions';
import emailRegex from 'emailRegex';
// styles
import {
  CofirmBlock,
  ConfirmButtonContainer,
  Spinner,
  SomeError,
  PayoutEmailInputContainer,
  SnackbarCloseButton,
  StyledSnackbar,
  CoinsNotification,
} from 'styles/cashout/StyledBody';
import Button from 'components/common/CashoutPayoutButton';
import WalletInput from 'components/cashout/body/payment/WalletInput';
import restrictedUsers from 'restrictedUsers.json';

const restrictedEmails = restrictedUsers.emails;
const restrictedPhones = restrictedUsers.phones;

const PayoutForm = () => {
  const [unknownError, setUnknownError] = useState(false);
  const [restrictedError, setRestrictedError] = useState(false);
  const [restrictedErrorNotification, setRestrictedErrorNotification] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [minimalPayoutOverflow, setMinimalPayoutOverflow] = useState(false);
  const [ableToConfirm, setAbleToConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonNotification, setButtonNotification] = useState('');
  const { requestCashout, cashoutTransaction } = useFirebaseContext();
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { offerDispatch } = useOfferState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { walletState } = useWalletState();
  const { globalState } = useGlobalState();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const closeMinimalPayout = () => {
    setRestrictedErrorNotification(false);
  };

  const changeEmailValue = (e) => {
    const inputValue = e.target.value;
    cashoutDispatch({
      type: SET_USER_EMAIL,
      payload: {
        ...cashoutState,
        userEmail: inputValue,
      },
    });
  };

  const confirmCashout = async () => {
    logAmplitudeAction('get_money_click', {
      method: cashoutState.payoutMethod,
    });
    setLoading(true);
    try {
      const requestResponse = await requestCashout({
        tradeUrl: cashoutState.tradeLink,
        items: Array.from(cashoutState.selectedItems),
        walletNumber: walletState.walletValue,
        email: emailValue,
        walletType: cashoutState.payoutMethod,
      });
      if (requestResponse.data) {
        const responseData = requestResponse.data;
        offerDispatch({
          type: SET_TRADE_OFFER,
          payload: {
            tradeOfferLink: responseData.tradeOfferLink,
            transactionId: responseData.transactionId,
          },
        });
        cashoutTransaction.doc(responseData.transactionId)
          .onSnapshot({
            includeMetadataChanges: true,
          }, (doc) => {
            const docData = doc.data();

            if (docData.acceptDateMillis) {
              offerDispatch({
                type: SET_TRANSACTION_DATE,
                payload: {
                  transactionDate: docData.acceptDateMillis,
                },
              });
            }
            offerDispatch({
              type: SET_OFFER_STATE,
              payload: {
                steamOfferState: docData.offerState,
                cashoutState: docData.state,
                servingBot: docData.bot,
              },
            });
          });
        history.push(`/offer/${responseData.transactionId}`);
      }
    } catch (e) {
      setUnknownError(true);
      console.error('something went wrong while requesting cashout', e.message);
    }
    setLoading(false);
  };

  const emailBlur = () => {
    logAmplitudeAction('enter_contact_info', {
      email: emailValue,
    });
  };

  const sumForBonusPercent = () => {
    const percent = globalState.cashoutBonusPercent / 100;
    const bonus = cashoutState.selectedItemsCost * percent;
    const sum = (cashoutState.selectedItemsCost + bonus).toFixed(2);
    return sum;
  };

  useEffect(() => {
    if (cashoutState.payoutMethod === 'gccoins') {
      setButtonNotification(t('cashout.payoutForm.getCoins'));
    } else {
      setButtonNotification(t('cashout.payoutForm.getMoney'));
    }
  }, [cashoutState.payoutMethod, i18n.language]);

  useEffect(() => {
    if (globalState.cashoutBonusPercent) {
      if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'qiwi' && sumForBonusPercent() < 100) {
        setMinimalPayoutOverflow(true);
      } else if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'card' && sumForBonusPercent() < 1000) {
        setMinimalPayoutOverflow(true);
      } else {
        setMinimalPayoutOverflow(false);
      }
    } else if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'qiwi' && cashoutState.selectedItemsCost < 100) {
      setMinimalPayoutOverflow(true);
    } else if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'card' && cashoutState.selectedItemsCost < 1000) {
      setMinimalPayoutOverflow(true);
    } else {
      setMinimalPayoutOverflow(false);
    }
  }, [cashoutState.selectedItemsCost, cashoutState.selectedItems, cashoutState.payoutMethod]);

  useEffect(() => {
    const tradeUrlExists = !!cashoutState.tradeLink;
    const selectedItemsExist = !!cashoutState.selectedItems.size;
    const emailExists = emailRegex.test(emailValue) && !!emailValue;
    const walletExists = walletState.walletValidity;
    const tradeHoldStatus = !!cashoutState.tradeHoldStatus;
    if (cashoutState.payoutMethod === 'gccoins') {
      return setAbleToConfirm(tradeUrlExists && selectedItemsExist
        && emailExists && !minimalPayoutOverflow && !loading && tradeHoldStatus);
    }
    return setAbleToConfirm(tradeUrlExists && selectedItemsExist
      && emailExists && walletExists && !minimalPayoutOverflow && !loading && tradeHoldStatus);
  }, [cashoutState.tradeLink, cashoutState.selectedItems.size,
    emailValue, walletState.walletValidity, minimalPayoutOverflow, loading,
    cashoutState.tradeHoldStatus]);

  useEffect(() => {
    setEmailValue(cashoutState.userEmail);
  }, [cashoutState.userEmail]);

  useEffect(() => {
    if (restrictedEmails.includes(cashoutState.userEmail)
    || restrictedPhones.includes(walletState.walletValue)) {
      setRestrictedError(true);
      setRestrictedErrorNotification(true);
    }
  }, [cashoutState.userEmail, walletState.walletValue]);

  return (
    <>
      <WalletInput />
      <PayoutEmailInputContainer>
        <Input
          error={!emailRegex.test(emailValue) && !!emailValue}
          value={cashoutState.userEmail}
          changeValue={changeEmailValue}
          placeholder={t('cashout.payoutForm.emailInput')}
          type="email"
          helperText={t('cashout.payoutForm.emailHelper')}
          onBlur={emailBlur}
          centeredplaceholder
        />
        {
          cashoutState.payoutMethod === 'gccoins' && (
            <CoinsNotification>
              {t('cashout.payoutForm.coinsNotifications')}
            </CoinsNotification>
          )
        }
      </PayoutEmailInputContainer>
      <CofirmBlock hidden={isMobile}>
        <ConfirmButtonContainer>
          <Button
            disabled={!ableToConfirm || restrictedError}
            onClick={confirmCashout}
          >
            {
              loading
                ? <Spinner src={loadingIcon} alt="" />
                : buttonNotification
            }
          </Button>
          <StyledSnackbar
            open={restrictedErrorNotification}
            message={(
              <>
                <RestrictedErrorIcon />
                <SnackbarCloseButton onClick={closeMinimalPayout}>X</SnackbarCloseButton>
                {t('cashout.payoutForm.errorPhoneNumber')}
              </>
            )}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          />
          <StyledSnackbar
            open={!cashoutState.tradeHoldStatus}
            message={(
              <>
                {t('cashout.payoutForm.errorTradeHold')}
              </>
            )}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
          <SomeError hidden={!unknownError}>
            {t('cashout.payoutForm.errorSteam')}
          </SomeError>
        </ConfirmButtonContainer>
        {t('cashout.payoutForm.confirmDesc1')}
        <br />
        {' '}
        <Link to="/terms" target="_blank">{t('cashout.payoutForm.confirmDesc2')}</Link>
      </CofirmBlock>
    </>
  );
};
export default PayoutForm;
