import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledDialog,
} from 'styles/modals/StyledBotsModal';
import {
  ServiceModalContainer,
  ServiceModalTitle,
  ServiceModalHint,
} from 'styles/modals/StyledServiceUnavailableModal';
import { useModalsState } from 'context/modals/modalsContext';
import {
  CLOSE_UNAVAILABLE_MODAL,
} from 'context/modals/modalsActions';

const ServiceUnavailableModal = () => {
  const [openedModal, setOpenedModal] = useState(false);
  const { modalsState, modalsDispatch } = useModalsState();
  const { t } = useTranslation();

  const closeModal = () => {
    modalsDispatch({ type: CLOSE_UNAVAILABLE_MODAL });
  };

  useEffect(() => {
    setOpenedModal(modalsState.unavailableModalOpened);
  }, [modalsState.unavailableModalOpened]);

  return (
    <StyledDialog
      open={openedModal}
      onClose={closeModal}
      maxWidth="lg"
    >
      <ServiceModalContainer>
        <ServiceModalTitle>
          {t('modalService.title')}
        </ServiceModalTitle>
        <ServiceModalHint>
          {t('modalService.desc1')}
          {' '}
          <a href="https://vk.com/gocases" target="_blank" rel="noreferrer">
            {t('modalService.desc2')}
          </a>
        </ServiceModalHint>
      </ServiceModalContainer>
    </StyledDialog>
  );
};

export default ServiceUnavailableModal;
