import React, { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import useWindowDimensions from 'hooks/windowWidthHook';
import { size } from 'styles/breakpoints';
import {
  ADD_SELECTED_ITEM,
  REMOVE_SELECTED_ITEM,
  REMOVE_ALL_SELECTED_ITEMS,
} from 'context/cashout/cashoutActions';
import {
  Grid,
} from '@material-ui/core';
import ItemBlock from 'components/common/CashoutItemBlock';
// styles
import {
  StyledInventoryContentContainer,
} from 'styles/cashout/StyledBody';
import MobilePagination from 'components/cashout/mobile_body/inventory/content/ContentPagination';
import Pagination from './ContentPagination';
import NoItems from './NoItemsBlock';

const convertItems = (inventoryItems) => inventoryItems.map((item, i) => ({
  key: i,
  id: item.assetId,
  name: item.name,
  imageUrl: item.imageUrl,
  price: +item.cashoutPriceRub.toFixed(2),
  priceUsd: +item.cashoutPrice.toFixed(2),
  priceCoins: +item.cashoutPriceGcCoins,
  tradable: item.tradable,
}));

const sortItems = (inventoryItems) => inventoryItems.sort(
  (a, b) => b.cashoutPriceRub - a.cashoutPriceRub,
);

const InventoryContentContainer = (props) => {
  const {
    items,
    loading,
    tradeLinkError,
  } = props;
  const [sortedItems, setSortedItems] = useState(null);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [currentItemsIds, setCurrentItemsIds] = useState([]);
  const [currentItemsCost, setCurrentItemsCost] = useState([]);
  const [currentItemsCostUsd, setCurrentItemsCostUsd] = useState([]);
  const [currentItemsCostCoins, setCurrentItemsCostCoins] = useState([]);
  const [currentTradableItems, setCurrentTradableItems] = useState([]);
  const [smallMobile, setSmallMobile] = useState(false);
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { page, selectedItems } = cashoutState;
  const { width } = useWindowDimensions();

  const itemsToShowAmount = () => {
    if (!isMobile) {
      return 12;
    }
    if (smallMobile) {
      return 4;
    }
    if (isTablet) {
      return 20;
    }
    return 6;
  };

  const addItem = (id, cost, costUsd, costCoins) => {
    cashoutDispatch({
      type: ADD_SELECTED_ITEM,
      payload: {
        itemId: id,
        itemCost: cost,
        itemCostUsd: costUsd,
        itemCostCoins: costCoins,
      },
    });
  };

  const removeItem = (id, cost, costUsd, costCoins) => {
    cashoutDispatch({
      type: REMOVE_SELECTED_ITEM,
      payload: {
        itemId: id,
        itemCost: cost,
        itemCostUsd: costUsd,
        itemCostCoins: costCoins,
      },
    });
  };

  const itemClick = (id, cost, costUsd, costCoins, name) => {
    if (id) {
      logAmplitudeAction('choose_skin', {
        item_name: name,
        item_price_rub: cost,
        item_price_usd: costUsd,
        item_price_coins: costCoins,
        removed: selectedItems.has(id),
      });
      if (selectedItems.has(id)) {
        removeItem(id, cost, costUsd, costCoins);
      } else {
        addItem(id, cost, costUsd, costCoins);
      }
    }
  };

  const onresize = () => {
    if (window.outerHeight < 680) {
      setSmallMobile(true);
    } else {
      setSmallMobile(false);
    }
  };

  useEffect(() => {
    cashoutDispatch({ type: REMOVE_ALL_SELECTED_ITEMS, payload: {} });
  }, []);

  useEffect(() => {
    document.addEventListener('resize', onresize());

    return () => {
      document.removeEventListener('resize', onresize());
    };
  }, [window.outerHeight]);

  useEffect(() => {
    if (items) {
      setSortedItems(sortItems(items));
    }
  }, [items]);

  useEffect(() => {
    const tmpItems = sortedItems ? convertItems(sortedItems.slice(page * itemsToShowAmount(),
      (page + 1) * itemsToShowAmount())) : [];
    const tmpItemsLength = tmpItems.length;
    // adding blank blocks
    if (tmpItemsLength < itemsToShowAmount()) {
      for (let i = 0; i < itemsToShowAmount() - tmpItemsLength; i += 1) {
        tmpItems.push({ key: i + tmpItemsLength, loading });
      }
    }
    setItemsToShow(tmpItems);
  }, [page, items, loading, window.outerHeight]);

  useEffect(() => {
    setCurrentItemsIds(itemsToShow.map((item) => item.id));
    setCurrentItemsCost(itemsToShow.map((item) => item.price));
    setCurrentItemsCostUsd(itemsToShow.map((item) => item.priceUsd));
    setCurrentItemsCostCoins(itemsToShow.map((item) => item.priceCoins));
    setCurrentTradableItems(itemsToShow.map((item) => item.tradable));
  }, [itemsToShow]);

  useEffect(() => {
    if (items && !loading && cashoutState.tradeLink) {
      logAmplitudeAction('enter_trade_url', {
        trade_url: cashoutState.tradeLink,
      });
    }
  }, [items, loading, cashoutState.tradeLink]);

  return (
    <>
      {
      !items && !loading && cashoutState.tradeLink
        ? <NoItems tradeLinkError={tradeLinkError} />
        : (
          <StyledInventoryContentContainer container spacing={width < size.desktopWide ? 1 : 2}>
            {
              itemsToShow.map((item) => (
                <Grid item xl={isMobile ? 6 : 3} key={item.key}>
                  <ItemBlock
                    item={item}
                    selected={selectedItems.has(item.id)}
                    itemClick={itemClick}
                    tradable={item.tradable}
                  />
                </Grid>
              ))
            }
          </StyledInventoryContentContainer>
        )
    }
      {
      items && !loading && (
      <Grid container>
        {
          isMobile
            ? <MobilePagination totalItems={items ? items.length : 0} />
            : (
              <Pagination
                totalItems={items ? items.length : 0}
                currentItemsIds={currentItemsIds}
                currentItemsCost={currentItemsCost}
                currentItemsCostUsd={currentItemsCostUsd}
                currentItemsCostCoins={currentItemsCostCoins}
                currentTradableItems={currentTradableItems}
              />
            )
        }
      </Grid>
      )
    }
    </>
  );
};

InventoryContentContainer.propTypes = {
  items: PropTypes.oneOfType([PropTypes.arrayOf([null]),
    PropTypes.arrayOf(PropTypes.object)]),
  loading: PropTypes.bool.isRequired,
  tradeLinkError: PropTypes.bool.isRequired,
};

InventoryContentContainer.defaultProps = {
  items: null,
};

export default InventoryContentContainer;
