import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import macbook from '../../../static/images/landing/macbook.svg';
import macbookEN from '../../../static/images/landing/macbookEN.svg';
import iphone from '../../../static/images/landing/iphone.svg';
import iphoneEN from '../../../static/images/landing/iphoneEN.svg';

import Button from '../common/button/Button';

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 148px 0 88px;
  width: 100%;

  @media (max-width: 1355px) {
    flex-direction: column;
    padding: 39px 0 0;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 596px;
  width: 100%;
  margin-left: 70px;
  margin-top: 131px;

  @media (max-width: 1355px) {
    margin-top: 0;
    margin-bottom: 32px;
  }

  @media (max-width: 680px) {
    max-width: 252px;
    margin-left: 24px;
  }
`;

const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 85px;

  @media (max-width: 680px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

const TitleHint = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
  margin: 0 12px;

  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Desc = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  opacity: 0.6;
  margin: 24px 0;

  @media (max-width: 680px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 971px;
  width: 100%;
  height: auto;

  @media (max-width: 1355px) {
    align-self: flex-end;
  }

  @media (max-width: 700px) {
    padding: 0 10px;
  }
`;

const Starting = () => {
  const [resize, setResize] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.innerWidth <= 700) {
      if (i18n.language === 'ru') {
        setResize(iphone);
      } else {
        setResize(iphoneEN);
      }
    } else if (i18n.language === 'ru') {
      setResize(macbook);
    } else {
      setResize(macbookEN);
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 700) {
        if (i18n.language === 'ru') {
          setResize(iphone);
        } else {
          setResize(iphoneEN);
        }
      } else if (i18n.language === 'ru') {
        setResize(macbook);
      } else {
        setResize(macbookEN);
      }
    });
    return () => window.removeEventListener('resize', () => {
      if (window.innerWidth <= 700) {
        if (i18n.language === 'ru') {
          setResize(iphone);
        } else {
          setResize(iphoneEN);
        }
      } else if (i18n.language === 'ru') {
        setResize(macbook);
      } else {
        setResize(macbookEN);
      }
    });
  }, [i18n.language]);

  return (
    <Main>
      <Left>
        <div>
          <Title>GC CASH</Title>
          <TitleHint>beta</TitleHint>
        </div>
        <Desc>
          {t('landing.startingTitle')}
          <br />
          {t('landing.startingDesc')}
        </Desc>
        <Button
          theme="white"
          text={t('landing.startingButton')}
          link="/cashout"
          chapter="main"
        />
      </Left>
      <Image src={resize} alt="macbook" />
    </Main>
  );
};

export default Starting;
