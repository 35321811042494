import React, { useEffect, useState } from 'react';
import {
  BrowserView,
  MobileView,
} from 'react-device-detect';
import PropTypes from 'prop-types';
import CashoutStateProvider from 'context/cashout/cashoutContext';
import getCookie, { deleteCookie } from 'cookiesHelper';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import { useGlobalState } from 'context/global/globalContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { SET_ACCOUNT } from 'context/global/globalActions';
import NotAuthenticatedModal from 'components/modals/NotAutenticatedModal';
import PromoModal from 'components/modals/PromoModal';
import { useModalsState } from 'context/modals/modalsContext';
import { OPEN_PROMO_MODAL } from 'context/modals/modalsActions';
import sendMetrika from 'utils/metriks';
import Body from './body/CashoutBodyContainer';
import MobileBody from './mobile_body/CashoutBodyContainer';

const CashoutContainer = ({ openPromoModal }) => {
  const [openedNotAuthenticated, setNotAuthenticated] = useState(false);
  const { globalDispatch } = useGlobalState();
  const { authenticate } = useFirebaseContext();
  const { setAmplitudeUser } = useAmplitudeContext();
  const { modalsDispatch } = useModalsState();

  useEffect(() => {
    const cookie = getCookie('custom-token');

    if (cookie) {
      authenticate(cookie)
        .then((data) => {
          setAmplitudeUser(data);
          return data;
        })
        .then((data) => {
          globalDispatch({
            type: SET_ACCOUNT,
            payload: {
              account: data,
            },
          });
          sendMetrika('reachGoal', 'successLogin');
        })
        .catch(() => {
          deleteCookie('custom-token');
          setNotAuthenticated(true);
        });
    } else {
      setNotAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (openPromoModal) {
      modalsDispatch({
        type: OPEN_PROMO_MODAL,
      });
    }
  }, [openPromoModal]);

  return (
    <CashoutStateProvider>
      <BrowserView>
        <Body />
      </BrowserView>
      <MobileView>
        <MobileBody />
      </MobileView>
      <NotAuthenticatedModal opened={openedNotAuthenticated} />
      <PromoModal />
    </CashoutStateProvider>
  );
};

CashoutContainer.propTypes = {
  openPromoModal: PropTypes.bool,
};

CashoutContainer.defaultProps = {
  openPromoModal: false,
};

export default CashoutContainer;
