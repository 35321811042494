import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as FourthStageImage } from 'static/images/fourth_stage_image.svg';
import PropTypes from 'prop-types';
// styles
import {
  StageTitle,
  StageDisclamer,
  StageInputContainer,
  ConfirmButtonContainer,
  ImageContainer,
} from 'styles/offer/StyledOffer';
import StageBlock from 'components/common/OfferStageBlock';
import Button from 'components/common/CashoutPayoutButton';
import sendMetrika from 'utils/metriks';
import OfferInformationContainer from './OfferInformationContainer';

const FourthStage = (props) => {
  const { transaction } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const backToCashout = () => {
    history.push('/cashout');
  };

  useEffect(() => {
    sendMetrika('reachGoal', 'successSale');
  }, []);

  return (
    <StageBlock>
      <OfferInformationContainer hint=" " transaction={transaction}>
        <StageTitle>{ t('offer.fourthStage.title') }</StageTitle>
        <StageDisclamer>
          { t('offer.fourthStage.desc') }
        </StageDisclamer>
        <StageInputContainer>
          <ConfirmButtonContainer>
            <Button onClick={backToCashout}>
              { t('offer.fourthStage.button') }
            </Button>
          </ConfirmButtonContainer>
        </StageInputContainer>
      </OfferInformationContainer>
      <ImageContainer item xs={5} container alignItems="center" justifyContent="center">
        <FourthStageImage />
      </ImageContainer>
    </StageBlock>
  );
};

FourthStage.propTypes = {
  transaction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
};

FourthStage.defaultProps = {
  transaction: null,
};

export default FourthStage;
