import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from 'components/common/CashoutFilledButton';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
// styles
import {
  StyledDialog,
  ModalContainer,
  ModalContentContainer,
  ModalTitle,
  ModalSubTitle,
  ButtonContainer,
} from 'styles/modals/styledNotAuthenticatedModal';

const NotAuthenticatedModal = (props) => {
  const {
    opened,
  } = props;
  const { logAmplitudeAction } = useAmplitudeContext();
  const { t } = useTranslation();

  const redirectAuthenticate = () => {
    logAmplitudeAction('steam_login_click', {
      source: 'blured',
    });
    window.location = '/_auth/steam';
  };

  return (
    <StyledDialog open={opened} maxWidth="lg">
      <ModalContainer>
        <ModalContentContainer>
          <ModalTitle>{t('modalAuth.title')}</ModalTitle>
          <ModalSubTitle>
            {t('modalAuth.desc')}
          </ModalSubTitle>
          <ButtonContainer>
            <Button onClick={redirectAuthenticate}>{t('modalAuth.button')}</Button>
          </ButtonContainer>
        </ModalContentContainer>
      </ModalContainer>
    </StyledDialog>
  );
};

NotAuthenticatedModal.propTypes = {
  opened: PropTypes.bool.isRequired,
};

export default NotAuthenticatedModal;
