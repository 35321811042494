import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  ModalContainer,
  ModalTitle,
  ModalHint,
} from './StyledBotsModal';

export const ServiceModalContainer = styled(ModalContainer)`
  height: ${isMobile ? 200 : 150}px;
  padding: ${isMobile && '24px 16px'};
  text-align: ${isMobile && 'center'};
`;

export const ServiceModalTitle = styled(ModalTitle)`
  font-size: 22px;
  margin-bottom: 16px;
`;

export const ServiceModalHint = styled(ModalHint)`
  font-size: 18px;

  a {
    color: #fff;
  }
`;
