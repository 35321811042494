import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useOfferState } from 'context/offer/OfferContext';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
// styles
import {
  OfferContainer,
  OfferStageContainer,
  StageNumber,
  StageTitle,
  StageDescription,
  StageInputContainer,
  SteamButtonContainer,
  SteamButtonContent,
  StageHint,
  OfferSecondStageContainer,
  BotsButtonContent,
  StyledFirstStageImage,
} from 'styles/mobile/offer/StyledOffer';
import Input from 'components/common/CashoutInput';
import Button from 'components/common/CashoutPayoutButton';
import BotsButton from '../ActualBotsButton';

const FirstStage = (props) => {
  const {
    transaction,
  } = props;
  const [botRegistrationDate, setBotRegistrationDate] = useState('');
  const { offerState } = useOfferState();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { t } = useTranslation();

  const getServingBotRegistrationDate = () => new Date(offerState.servingBot.registrationDateMillis)
    .toLocaleDateString();

  const openSteam = () => {
    logAmplitudeAction('accept_steam_click');
    if (offerState.tradeOfferLink) {
      window.open(offerState.tradeOfferLink);
    }
  };

  useEffect(() => {
    if (offerState.servingBot) {
      setBotRegistrationDate(getServingBotRegistrationDate());
    }
  }, [offerState.servingBot]);

  return (
    <OfferContainer>
      <OfferStageContainer container direction="column">
        <StageNumber>
          { t('offer.firstStage.stepOne') }
        </StageNumber>
        <StageTitle>
          { t('offer.firstStage.titleOne') }
        </StageTitle>
        <StageDescription>
          { t('offer.firstStage.descOne') }
        </StageDescription>
        <StageInputContainer>
          <Input
            placeholder={t('offer.firstStage.id')}
            value={(transaction && transaction.id) || offerState.transactionId}
            changeValue={() => {}}
            readonly
          />
        </StageInputContainer>
        <StageInputContainer>
          <Input
            placeholder={t('offer.firstStage.date')}
            value={(transaction
                && new Date(transaction.bot.registrationDateMillis).toLocaleDateString())
                || botRegistrationDate}
            changeValue={() => {}}
            readonly
          />
        </StageInputContainer>
        <StageInputContainer>
          <SteamButtonContainer>
            <Button onClick={openSteam}>
              <SteamButtonContent>
                {t('offer.firstStage.goToSteam')}
              </SteamButtonContent>
            </Button>
          </SteamButtonContainer>
        </StageInputContainer>
        <StageHint>
          {t('offer.firstStage.hintBlock')}
        </StageHint>
      </OfferStageContainer>
      <OfferSecondStageContainer container direction="column">
        <StageNumber>{t('offer.firstStage.stepTwo')}</StageNumber>
        <StageTitle>
          {t('offer.firstStage.titleTwo')}
        </StageTitle>
        <StageDescription>
          {t('offer.firstStage.descTwo')}
        </StageDescription>
        <StageInputContainer>
          <BotsButton>
            <BotsButtonContent>
              {t('offer.actualBots.children')}
            </BotsButtonContent>
          </BotsButton>
        </StageInputContainer>
        <StyledFirstStageImage />
      </OfferSecondStageContainer>
    </OfferContainer>
  );
};

FirstStage.propTypes = {
  transaction: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

FirstStage.defaultProps = {
  transaction: null,
};

export default FirstStage;
