import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  Dialog,
} from '@material-ui/core';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #363839;
    border-radius: 10px;
    margin: 0;

    &.MuiDialog-paperWidthLg.MuiDialog-paperScrollBody {
      max-width: unset;
    }
  }
`;

export const ModalContainer = styled.div`
  width: ${isMobile ? 288 : 750}px;
  background: #363839;
  border-radius: 10px;
  padding: ${isMobile ? '24px 16px' : '48px 24px'};
  text-align: ${!isMobile && 'center'};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: ${isMobile ? 16 : 20}px;
  line-height: ${isMobile ? 22 : 28}px;
  margin-bottom: 8px;
`;

export const ModalHint = styled.div`
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 17 : 22}px;
  opacity: .5;
  margin-bottom: ${isMobile ? 20 : 32}px;
  text-align: ${isMobile ? 'center' : 'none'};
`;

export const PromoContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HighlightText = styled.span`
  color: #fff;
`;

export const PromoButton = styled.button`
  width: 124px;
  height: 48px;
  border: 1px solid #fff;
  background-color: inherit;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  margin-top: ${isMobile ? 20 : 57}px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;

export const CloseIconContainer = styled.div`
  display: ${isMobile ? 'none' : 'block'};
  position: absolute;
  top: 12px;
  right: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const Spinner = styled.img`
  width: 22px;
  animation: ${rotate} 2s linear infinite;
`;
