const paragraphsRU = [
  'Мы предоставляем настоящую Политику конфиденциальности, чтобы проинформировать вас о наших политиках и процедурах в отношении сбора, использования и раскрытия личных данных и другой информации, которую мы получаем от пользователей.',
  'Продолжая использовать Сайт и / или Сервис после того, как вам было отправлено уведомление об изменениях, вы соглашаетесь с этими изменениями.',
  'Вы добровольно предоставляете нам определенные личные данные, которые мы собираем, чтобы вы могли пользоваться функциями Сайта и / или Сервиса. Если у вас есть какие-либо сомнения относительно предоставления нам таких личных данных или их использования иным образом любым способом, разрешенным настоящей Политикой конфиденциальности и нашими Условиями использования, вам не следует становиться пользователем.',
  'Сайт и сторонние сайты',
  'Настоящая Политика конфиденциальности применяется исключительно к Сайту. Сайт может содержать ссылки на сайты третьих лиц, которые не принадлежат и не контролируются Поставщиком. Провайдер не контролирует и не несет ответственности за контент, политику конфиденциальности (если таковая имеется) или действия сторонних сайтов. Вы признаете, что несете ответственность за просмотр и понимание политик конфиденциальности (если таковые имеются) любых сторонних сайтов, к которым вы можете получить доступ через этот Сайт. Используя этот Сайт, вы освобождаете Поставщика от любой ответственности, связанной с использованием вами сайтов третьих лиц, на которые этот Сайт может ссылаться.',
  'Наши основные цели при сборе ваших личных данных - это предоставление и улучшение Сайта и Сервиса и связанных с ним функций, администрирование вашей подписки и предоставление пользователям возможности пользоваться Сайтом и Сервисом и легко перемещаться по ним. Ниже приведены типы и категории информации, которую мы собираем.',
  'Собранные личные данные',
  'Мы собираем ваши персональные данные следующими способами:',
  'Log Data',
  'Когда вы посещаете Сайт, наши серверы автоматически записывают информацию о вашем использовании и посещении Сайта («Log Data»). Log Data могут включать такую информацию, как IP-адрес вашего компьютера, время и дата ваших запросов, тип вашего браузера, какое программное обеспечение вы использовали, веб-страницу, которую вы посещали непосредственно перед посещением Сайта, время доступа и даты, ваше местоположение, информация о мобильной сети и другая статистика. Мы используем эту информацию для мониторинга и анализа использования Сайта и для технического администрирования Сайта, чтобы улучшить функциональность Сайта и сделать его более удобным для пользователя, а также лучше адаптировать его к потребностям пользователей.',
  'Cookie',
  'Как и большинство сайтов, мы используем файлы cookie и веб-журналы для отслеживания использования Сайта. Файл cookie - это крошечный файл данных, который находится на вашем компьютере и позволяет поставщику распознавать вас как пользователя, когда вы возвращаетесь на сайт с помощью того же компьютера и веб-браузера. Если настройки вашего браузера не разрешают использование файлов cookie, возможно, вы не сможете использовать Сайт. Как и информация, которую вы вводите при подписке, файлы cookie и данные файлов журнала используются для настройки вашего взаимодействия с Сайтом.',
  'Один тип файлов cookie, известный как "сеансовые" файлы cookie, используется для идентификации конкретного посещения Сайта. Срок действия файла cookie сеанса истекает через короткое время или после закрытия веб-браузера.',
  'Более того, Сайт может использовать локальные общие объекты, обычно называемые «флеш-куки», для таких целей, как сохранение пользовательских предпочтений. Здесь вы можете управлять своими флеш-файлами cookie или отказаться от них.',
  'Log files, IP и информация о вашем компьютере',
  'В соответствии со стандартами связи в Интернете, когда вы посещаете Сайт, мы автоматически получаем URL-адрес сайта, с которого вы пришли, и сайта, на который вы переходите, когда вы покидаете Сайт. Мы также получаем адрес интернет-протокола (IP) вашего компьютера (или прокси-сервера, который вы используете для доступа к всемирной паутине), операционную систему вашего компьютера и тип используемого вами веб-браузера, шаблоны электронной почты, а также имя вашего интернет-провайдера. Эта информация используется для анализа общих тенденций, чтобы помочь нам улучшить Сайт. Связь между вашим IP-адресом и вашими личными данными не передается третьим лицам без вашего предварительного согласия или за исключением случаев, когда это разрешено или требуется законом.',
  'Связь',
  'Провайдер будет связываться с вами посредством электронной почты и уведомлений, размещенных на Сайте, или с помощью других средств, доступных через службу, включая текстовые и другие формы обмена сообщениями. Помните, что вы можете получать определенные электронные письма от Поставщика, касающиеся надлежащего функционирования Сервиса.',
  'Передача информации третьим лицам',
  'Провайдер очень серьезно относится к конфиденциальности пользователей. Таким образом, мы не продаем, не сдаем в аренду или иным образом не предоставляем ваши личные данные третьим лицам в маркетинговых целях. Мы будем передавать ваши личные данные третьим лицам только для выполнения ваших инструкций или для предоставления определенных услуг или информации. Если вы не согласились с этим, эти третьи стороны не сохраняют, не передают и не хранят какие-либо личные данные, кроме как для оказания этих услуг, и они связаны соглашениями о конфиденциальности, которые ограничивают их использование такой информации.',
  'Мы можем предоставлять агрегированные анонимные данные об использовании Сайта третьим лицам для таких целей, которые мы считаем, по нашему собственному усмотрению, подходящими, в том числе потенциальным рекламодателям на Сайте.',
  'Сотрудничество с третьими сторонами',
  'Провайдер разрешает другим компаниям размещать рекламу на Сайте. Эти компании используют технологии для доставки рекламы, которую вы видите на Сайте, прямо в ваш браузер. При этом они могут автоматически получить ваш IP-адрес. Другие компании, размещающие рекламу на Сайте, могут иметь возможность использовать файлы cookie и / или веб-маяки для сбора информации, включая информацию об использовании вами Сайта. Мы не контролируем процессы, которые рекламодатели используют для сбора информации. Однако сами по себе IP-адреса, файлы cookie и веб-маяки не считаются личными данными и, как правило, не могут использоваться для идентификации людей, а только для машин. Таким образом, рекламодатели и другие лица, чья реклама или контент могут появляться на Сайте, обычно не будут знать, кто вы, если вы не предоставите им дополнительную информацию, ответив на рекламу, заключив с ними соглашение или каким-либо другим способом.',
  'Правовая оговорка',
  'Провайдер сотрудничает с государственными и правоохранительными органами, а также с частными лицами для обеспечения соблюдения и соблюдения закона. Мы будем раскрывать любую информацию о вас правительственным или правоохранительным органам или частным лицам, поскольку мы, по нашему собственному усмотрению, считаем необходимым или уместным ответить на претензии и судебный процесс (включая, помимо прочего, судебный иск), для защиты собственности и прав. Поставщика или третьей стороны, для защиты безопасности населения или любого лица, а также для предотвращения или прекращения деятельности, которую мы считаем незаконной или неэтичной.',
  'Мы предоставляем представления и информацию в этом разделе в соответствии с европейскими законами о конфиденциальности, в частности, с Общим европейским регламентом защиты данных (GDPR). Они предназначены только для лиц, проживающих в странах ЕЭЗ или Швейцарии, поэтому не полагайтесь на приведенные ниже данные, если вы не проживаете в одной из этих стран.',
  'Если вы являетесь посетителем из европейских территорий, наша правовая основа для сбора и использования личных данных, описанных в настоящей Политике конфиденциальности, будет зависеть от соответствующей личной информации и конкретного контекста, в котором мы ее собираем. «Европейские территории» означают Европейское экономическое пространство и Швейцарию. Для целей настоящего Уведомления о конфиденциальности термин «европейские территории» будет по-прежнему включать Соединенное Королевство, даже после того, как Соединенное Королевство покинет Европейскую экономическую зону после Brexit.',
  'Тем не менее, мы обычно собираем ваши персональные данные, если их обработка отвечает нашим законным деловым интересам, например, для администрирования наших платформ и услуг и выполнения наших договорных обязательств в качестве поставщика услуг.',
  'В некоторых случаях мы можем собирать и обрабатывать персональные данные на основании согласия. В той степени, в которой нашим клиентам и рекламным партнерам необходимо собирать и передавать или позволять нам упрощать сбор и совместное использование личных данных для предоставления наших услуг, эти стороны несут ответственность за предоставление необходимых уведомлений о конфиденциальности и получение необходимого согласия (-ий).',
  'Если у вас есть вопросы или вам нужна дополнительная информация о правовой основе, на которой мы собираем и используем вашу личную информацию, в том числе если вы хотите лучше понять, как наши законные интересы в обработке ваших данных соотносятся с вашими правами и свободами на защиту данных, тогда свяжитесь с нами, используя контактную форму',
  'Раскрытие информации другим',
  'Поставщик также может раскрыть ваши личные данные и другую информацию, которую вы предоставляете, другой третьей стороне в рамках реорганизации или продажи активов подразделения или компании Поставщика. Любая третья сторона, которой мы передаем или продаем наши активы, будет иметь право продолжать использовать личные данные и другую информацию, которую вы нам предоставляете.',
  'Сводная информация и неидентифицирующая информация',
  'Мы можем делиться агрегированной информацией, которая не включает личные данные, и мы можем иным образом раскрывать неидентифицирующую информацию и Log Data третьим лицам для анализа отрасли, демографического профилирования и других целей. Любая агрегированная информация, передаваемая в этих контекстах, не будет содержать ваших личных данных.',
  'Международный трансфер',
  'Ваша информация может передаваться и храниться на компьютерах и серверах, расположенных за пределами вашего штата, провинции, страны или другой государственной юрисдикции, где законы о конфиденциальности могут быть не такими защитными, как в вашей юрисдикции. Ваше согласие с настоящей Политикой конфиденциальности, за которым следует предоставление такой информации, означает ваше согласие на такую передачу.',
  'Ссылки на другие сайты',
  'Наш сайт содержит ссылки на другие сайты. Если вы решите посетить рекламодателя, щелкнув рекламный баннер или другой тип рекламы, или щелкнув ссылку другой третьей стороны, вы будете перенаправлены на веб-сайты этих третьих лиц. Тот факт, что мы ссылаемся на веб-сайт или представляем баннерную рекламу или другой тип рекламы, не является одобрением, авторизацией или представлением нашей аффилированности с этой третьей стороной, а также не одобряет их политику или методы обеспечения конфиденциальности или информационной безопасности. Мы не контролируем сайты третьих лиц. Эти другие веб-сайты могут размещать свои собственные файлы cookie или другие файлы на вашем компьютере, собирать личные данные или запрашивать у вас личную информацию. Другие сайты следуют другим правилам в отношении использования или раскрытия личных данных, которые вы им отправляете. Мы рекомендуем вам ознакомиться с политикой конфиденциальности (если таковая имеется) или заявлениями других веб-сайтов, которые вы посещаете.',
  'Доступ и изменение вашей информации',
  'Вы можете просмотреть информацию, которую вы нам предоставили, и внести в нее любые изменения в любое время. Имейте в виду, что даже после обработки вашего запроса на изменение Провайдер может в течение некоторого времени сохранять остаточную информацию о вас в своих резервных и / или архивных копиях своей базы данных. Мы серьезно относимся к вашим правам и рекомендуем вам использовать их, если вы сочтете это уместным. Вы можете воспользоваться этими правами, отправив нам электронное письмо, используя следующую контактную форму',
  'Ваши обязательства',
  'Как пользователь, у вас есть определенные обязательства, налагаемые действующим законодательством и нормативными актами:',
  'Вы должны всегда соблюдать положения и условия действующей Политики конфиденциальности и Условий использования. Это включает в себя соблюдение всех прав интеллектуальной собственности, которые могут принадлежать третьим лицам.',
  'Вы не должны загружать, загружать или иным образом распространять любую информацию, которая может быть сочтена оскорбительной, насильственной, оскорбительной, расистской или ксенофобской.',
  'Пользователи имеют право отозвать свое согласие на обработку своих персональных данных, уведомив об этом Поставщика. Хотя пользователи предоставляют свои персональные данные Провайдеру добровольно, отказ в их предоставлении делает невозможным заключение соглашения с Провайдером и доступ к Сайту и использование Сервиса.',
  'Провайдер выполняет все требования Закона от 29 августа 1997 года о защите личных данных и Директивы ЕС 95/46 / EC, Директивы ЕС 2002/58 / EC и Общего регламента защиты данных («GDPR») с даты его вступления в силу. ), которые связаны с обязательствами стороны по настоящему Соглашению.',
  'Провайдер имеет право раскрывать личные данные только властям, имеющим право в соответствии с положениями действующего закона (например, суду, полиции).',
  'Персональные данные, предоставленные Поставщику в контактной форме и / или в форме регистрации или любым другим способом, будут использоваться Поставщиком и его аффилированными лицами исключительно для следующих целей: (i) использование пользователем Сайта и Сервиса (a возможность использования функций, предлагаемых Сайтом, включая обработку платежей, разрешение споров, предотвращение запрещенных или незаконных действий; пользователь является стороной соглашения об услугах, предоставляемых с помощью электронных средств); (ii) обработка жалоб и оказание поддержки клиентам; (iii) связываться с пользователями и отвечать на корреспонденцию, отправляемую через контактную форму; (iv) техническая работа Сайта и Сервиса; (v) прямой маркетинг (на основе предпочтений пользователя); (vi) ведение статистики Сайта; и (vii) проведение исследований и анализа, направленных на улучшение Сайта и Сервиса.',
  'Ознакомившись с вышеуказанной Политикой конфиденциальности, используя или получая доступ к Сайту, и особенно нажав кнопку «Перейти в GC.CASH», вы настоящим предоставляете:',
  'Согласие на обработку и использование Поставщиком всех персональных данных, предоставленных Поставщику, включая данные, полученные в результате использования вами Сайта и / или Сервиса, для вышеупомянутых целей.',
  'Согласие на передачу ваших личных данных за границу, в частности, компаниям, аффилированным с Поставщиком, или связанным с ними организациям, или материнской компании Поставщика, с их зарегистрированным офисом за пределами Польши, для целей обработки в компьютерных системах, принадлежащих Поставщику и его деловые партнеры. Вы понимаете, что вышеуказанные организации могут находиться в странах, которые не гарантируют такой же уровень защиты личных данных, как уровень, гарантированный в Польше.',
  'Согласитесь, что ваше согласие остается в силе в течение всего периода использования вами Сайта и / или Сервиса.',
  'Заявите и подтвердите, что вы прочитали Условия использования, вы их понимаете и будете соблюдать их как в нынешней редакции, так и с любыми последующими поправками.',
  'Как с нами связаться',
  'Если у вас есть какие-либо вопросы относительно ваших личных данных или комментарии к настоящей Политике конфиденциальности, напишите нам, используя форму поддержки, имеющуюся в правом нижнем углу сайта, или по электронной почте support@bladestorm.org',
];

export default paragraphsRU;
