import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import loadingIcon from 'static/images/icons/loading_icon.png';
// styles
import {
  ModalContainer,
  StyledDialog,
  ModalTitle,
  ModalHint,
  BotsContainer,
  BotLine,
  BotName,
  BotContent,
  ModalCloseButton,
} from 'styles/modals/StyledBotsModal';
import {
  Spinner,
} from 'styles/cashout/StyledBody';

const BotsModal = (props) => {
  const {
    opened,
    closeAction,
    bots,
  } = props;
  const { t } = useTranslation();

  const getConvertedBots = useCallback(() => bots.map((bot) => ({
    name: bot.steamId,
    date: new Date(bot.registrationDateMillis).toLocaleDateString(),
    link: bot.profileLink,
  })), [bots]);

  return (
    <StyledDialog
      open={opened}
      onClose={closeAction}
      scroll="body"
      maxWidth="lg"
    >
      <ModalContainer>
        <ModalCloseButton onClick={closeAction} hidden={!isMobile} />
        <ModalTitle>{t('modalActualBots.title')}</ModalTitle>
        <ModalHint>{t('modalActualBots.desc')}</ModalHint>
        <BotsContainer>
          {
            bots
              ? getConvertedBots().map((bot) => (
                <BotLine key={bot.name} container alignItems={!isMobile && 'center'} direction={isMobile && 'column'}>
                  <BotName item xs={3}>{bot.name}</BotName>
                  <BotContent item xs={3}>{bot.date}</BotContent>
                  <BotContent item xs={6} hidden={isMobile}>
                    <a href={bot.link} target="_blank" rel="noreferrer">
                      {`${bot.link.slice(8, 49)}...`}
                    </a>
                  </BotContent>
                </BotLine>
              ))
              : <Spinner src={loadingIcon} alt="" />
          }
        </BotsContainer>
      </ModalContainer>
    </StyledDialog>
  );
};

BotsModal.propTypes = {
  opened: PropTypes.bool,
  closeAction: PropTypes.func,
  bots: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.arrayOf(PropTypes.object)]),
};

BotsModal.defaultProps = {
  opened: false,
  closeAction: () => {},
  bots: null,
};

export default BotsModal;
