import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import loadingIcon from 'static/images/icons/loading_icon.png';
// styles
import {
  ModalContainer,
  StyledDialog,
  ModalTitle,
  ModalHint,
  BotsContainer,
  BotLine,
  BotContent,
  BotLineLink,
} from 'styles/modals/StyledBotsModal';
import {
  SnackbarCloseButton,
} from 'styles/mobile/cashout/StyledBody';
import {
  Spinner,
} from 'styles/cashout/StyledBody';

const TransactionsModal = (props) => {
  const {
    opened,
    closeAction,
    transactions,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const changeTransaction = (transctionId) => {
    history.push(`/offer/${transctionId}`);
    closeAction();
  };

  const getTransactionState = (transaction) => {
    switch (transaction) {
      case 1:
        return t('modalTransactions.case1');
      case 2:
        return t('modalTransactions.case2');
      case 3:
        return t('modalTransactions.case3');
      case 4:
        return t('modalTransactions.case4');
      default:
        return t('modalTransactions.case1');
    }
  };

  return (
    <StyledDialog
      open={opened}
      onClose={closeAction}
      scroll="body"
      maxWidth="lg"
    >
      <ModalContainer>
        <SnackbarCloseButton onClick={closeAction}>X</SnackbarCloseButton>
        <ModalTitle>{t('modalTransactions.title')}</ModalTitle>
        <ModalHint>{t('modalTransactions.desc')}</ModalHint>
        <BotsContainer>
          {
            transactions
              ? transactions.sort((a, b) => a.state - b.state).map((transaction) => (
                <BotLineLink onClick={() => changeTransaction(transaction.id)} key={transaction.id}>
                  <BotLine container alignItems="center" justifyContent="space-between">
                    <BotContent item>
                      {transaction.id}
                    </BotContent>
                    <BotContent item>{getTransactionState(transaction.state)}</BotContent>
                  </BotLine>
                </BotLineLink>
              ))
              : <Spinner src={loadingIcon} alt="" />
          }
        </BotsContainer>
      </ModalContainer>
    </StyledDialog>
  );
};

TransactionsModal.propTypes = {
  opened: PropTypes.bool,
  closeAction: PropTypes.func,
  transactions: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.arrayOf(PropTypes.object)]),
};

TransactionsModal.defaultProps = {
  opened: false,
  closeAction: () => {},
  transactions: null,
};

export default TransactionsModal;
