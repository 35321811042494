import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  SET_SELECTED_COUNTRY,
  SET_WALLET_VALUE,
  SET_WALLET_VALIDITY,
  SET_NOTIFICATION,
} from './walletActions';

export const WalletContext = createContext(undefined);

const initialState = {
  selectedCountry: 'Russia',
  walletValue: '',
  walletValidity: false,
  notification: false,
};

const setSelectedCountry = (state, selectedCountry) => ({
  ...state,
  selectedCountry,
});

const setWalletValue = (state, walletValue) => ({
  ...state,
  walletValue,
});

const setWalletValidity = (state, walletValidity) => ({
  ...state,
  walletValidity,
});

const setNotification = (state, notification) => ({
  ...state,
  notification,
});

const stateReducer = (state, action) => {
  const {
    selectedCountry,
    walletValue,
    walletValidity,
    notification,
  } = action.payload;

  switch (action.type) {
    case SET_SELECTED_COUNTRY:
      return setSelectedCountry(state, selectedCountry);
    case SET_WALLET_VALUE:
      return setWalletValue(state, walletValue);
    case SET_WALLET_VALIDITY:
      return setWalletValidity(state, walletValidity);
    case SET_NOTIFICATION:
      return setNotification(state, notification);
    default:
      return initialState;
  }
};

const StateProvider = ({ children }) => {
  const [walletState, walletDispatch] = useReducer(stateReducer, initialState);

  return (
    <WalletContext.Provider value={{ walletState, walletDispatch }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletState = () => {
  const context = useContext(WalletContext);

  if (!context) throw new Error('useWalletState must be used in a wallet state provider');

  return context;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element),
    PropTypes.element]).isRequired,
};

export default StateProvider;
