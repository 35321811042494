import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as RuIcon } from 'static/images/icons/ru_lang.svg';
import { ReactComponent as EnIcon } from 'static/images/icons/en_lang.svg';

const Main = styled.div`
  position: relative;
  display: flex;
  margin-right: 10px;
  margin-top: ${(props) => (props.mobile ? '12px' : '0')};

  &:hover {
    cursor: pointer;
  }
`;

const Modal = styled.div`
  position: absolute;
  top: 25px;
  left: -10px;
  display: flex;
  flex-direction: column;
  background-color: #363A42;
  padding: 10px 10px 10px;
  z-index: 20;
  border-radius: 5px;

  &:nth-child(1) {
    margin-bottom: 20px;
  }
`;

const RuLogo = styled(RuIcon)`
  margin-bottom: 10px;
`;

const Dropdown = (props) => {
  const { mobile } = props;
  const { i18n } = useTranslation();
  const [display, setDisplay] = useState(false);

  const handleClickIcon = () => {
    setDisplay(!display);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setDisplay(!display);
  };

  return (
    <Main mobile={mobile}>
      {i18n.language === 'ru' ? (
        <RuIcon onClick={() => handleClickIcon()} />
      ) : (
        <EnIcon onClick={() => handleClickIcon()} />
      )}
      {display && (
        <Modal>
          <RuLogo onClick={() => changeLanguage('ru')} />
          <EnIcon onClick={() => changeLanguage('en')} />
        </Modal>
      )}
    </Main>
  );
};

Dropdown.propTypes = {
  mobile: PropTypes.bool,
};

Dropdown.defaultProps = {
  mobile: false,
};

export default Dropdown;
