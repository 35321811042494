import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useOfferState } from 'context/offer/OfferContext';

// styles
import {
  OfferContainer,
  StageNumber,
  StageTitle,
  StageDescription,
  StageInputContainer,
  StageHint,
  OfferThirdStageContainer,
  StyledThirdStageSucces,
  StyledThirdStageError,
  StageTimer,
} from 'styles/mobile/offer/StyledOffer';
import Input from 'components/common/CashoutInput';

const ThirdStageContainer = (props) => {
  const {
    transaction,
    acceptDateMillis,
  } = props;
  const { offerState } = useOfferState();
  const { t } = useTranslation();

  const thirdStageData = {
    success: {
      title: t('offer.thirdStageSuccess.title'),
      description: t('offer.thirdStageSuccess.desc'),
      hint: t('offer.thirdStageContainer.error'),
      image: <StyledThirdStageSucces />,
    },
    error: {
      title: t('offer.thirdStageError.title'),
      description: t('offer.thirdStageError.desc'),
      hint: '',
      image: <StyledThirdStageError />,
    },
  };

  const getStageData = () => ((offerState.isError || transaction.offerState > 3)
    ? thirdStageData.error
    : thirdStageData.success);

  const startTimer = () => {
    const dateMillis = offerState.transactionDate || acceptDateMillis;
    const deadline = new Date(dateMillis).getTime() + 60 * 60 * 24 * 1000;
    const diff = deadline - new Date();
    const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
    const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
    const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const [timer, setTimer] = useState(startTimer());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(startTimer());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <OfferContainer>
      <OfferThirdStageContainer>
        <StageNumber>
          {t('step')}
          3
        </StageNumber>
        <StageTitle>
          {getStageData().title}
        </StageTitle>
        <StageDescription>
          {getStageData().description}
        </StageDescription>
        <StageTimer>
          {offerState.transactionDate || acceptDateMillis ? timer : '--:--:--'}
        </StageTimer>
        <StageInputContainer>
          <Input
            placeholder={t('offer.firstStage.id')}
            value={(transaction && transaction.id) || offerState.transactionId}
            changeValue={() => {}}
            readonly
          />
        </StageInputContainer>
        <StageHint>
          {getStageData().hint}
        </StageHint>
        {getStageData().image}
      </OfferThirdStageContainer>
    </OfferContainer>
  );
};

ThirdStageContainer.propTypes = {
  transaction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  acceptDateMillis: PropTypes.number.isRequired,
};

ThirdStageContainer.defaultProps = {
  transaction: null,
};

export default ThirdStageContainer;
