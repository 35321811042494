import styled from 'styled-components';
import { ReactComponent as StageImage } from 'static/images/phone_first_stage_image.svg';
import { ReactComponent as ThirdStageSuccess } from 'static/images/third_mobile_stage_success.svg';
import { ReactComponent as ThirdStageError } from 'static/images/third_mobile_stage_error.svg';
import {
  Grid,
} from '@material-ui/core';

export const OfferContainer = styled.div`
    padding: 24px 16px;
`;

export const OfferStageContainer = styled(Grid)`
    padding: 20px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    height: 100%;
`;

export const OfferSecondStageContainer = styled(OfferStageContainer)`
    margin-top: 16px;
    padding-bottom: 0;
`;

export const OfferThirdStageContainer = styled(OfferStageContainer)`
    padding-bottom: 0;
`;

export const StageNumber = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: rgba(255, 255, 255, .5);
`;

export const StageTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0px;
`;

export const StageDescription = styled(StageNumber)`
    line-height: 17px;
`;

export const StageTimer = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
`;

export const StageInputContainer = styled.div`
    margin-top: 20px;
`;

export const SteamButtonContainer = styled.div`
  width: 150px;
`;

export const SteamButtonContent = styled.span`
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
`;

export const StageHint = styled.div`
    font-size: 10px;
    line-height: 14px;
    color: rgba(255, 255, 255, .21);
    margin-top: 20px;
`;

export const BotsButtonContent = styled.span`
    font-size: 12px;
    line-height: 17px;
`;

export const StyledFirstStageImage = styled(StageImage)`
    margin-top: 20px;
`;

export const StyledThirdStageSucces = styled(ThirdStageSuccess)`
    margin-top: 20px;
`;

export const StyledThirdStageError = styled(ThirdStageError)`
    margin-top: 10px;
    margin-bottom: -5px;
`;

export const CashoutButtonContainer = styled.div`
    width: 151px;
`;
