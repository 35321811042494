/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { goToAnchor } from 'react-scrollable-anchor';
import { useLocation, useHistory } from 'react-router-dom';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
// styles
import {
  StyledLink,
  StyledRouterLink,
  StyledDivider,
  DividerContainer,
} from 'styles/cashout/StyledHeader';
import TransactionsModal from 'components/modals/TransactionsModal';
import Dropdown from 'components/common/DropdownLanguage';
import Menu from './MenuContainer';

const HeaderNavigation = () => {
  const [openedTransactionsModal, setOpenedTransactionsModal] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { logAmplitudeAction } = useAmplitudeContext();
  const { getTransactions } = useFirebaseContext();
  const { t } = useTranslation();

  const handleFAQClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      logAmplitudeAction('main_page_click');
      history.push('/');
      goToAnchor('faq');
    } else {
      logAmplitudeAction('faq_click');
      goToAnchor('faq');
    }
  };

  const closeDialog = () => {
    setOpenedTransactionsModal(false);
  };

  const handleTransactionsClick = async (e) => {
    e.preventDefault();
    setOpenedTransactionsModal(true);
    try {
      const transactionsResponse = await getTransactions();

      if (transactionsResponse.data) {
        setTransactions(transactionsResponse.data.sort((a, b) => a.state - b.state));
      }
    } catch (error) {
      console.error('Something happened while getting transactions', error);
      closeDialog();
    }
  };

  const handleTrustpilotClick = () => {
    logAmplitudeAction('trustpilot_click');
    window.open('https://www.trustpilot.com/review/gccash.gg', '_blank');
  };

  return (
    <Grid item>
      <Grid container alignItems="center">
        <Dropdown />
        <StyledLink onClick={handleTrustpilotClick}>{t('header.linkOne')}</StyledLink>
        <StyledLink onClick={handleTransactionsClick}>{t('header.linkTwo')}</StyledLink>
        <StyledLink onClick={handleFAQClick}>{t('header.linkThree')}</StyledLink>
        <StyledRouterLink to="/instructions">{t('header.linkFour')}</StyledRouterLink>
        <DividerContainer>
          <StyledDivider orientation="vertical" light />
        </DividerContainer>
        <Menu />
      </Grid>
      <TransactionsModal
        opened={openedTransactionsModal}
        transactions={transactions}
        closeAction={closeDialog}
      />
    </Grid>
  );
};

export default HeaderNavigation;
