import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledNoItemsBlock,
} from 'styles/cashout/StyledBody';

const NoItemsBlock = (props) => {
  const {
    tradeLinkError,
  } = props;
  const { t } = useTranslation();

  const getNoItemsText = () => {
    if (tradeLinkError) {
      return t('cashout.noItemsBlock.tradeLinkError');
    }
    return t('cashout.noItemsBlock.inventoryError');
  };

  return (
    <StyledNoItemsBlock>
      {getNoItemsText()}
    </StyledNoItemsBlock>
  );
};

NoItemsBlock.propTypes = {
  tradeLinkError: PropTypes.bool.isRequired,
};

export default NoItemsBlock;
