import React, { useEffect, useState } from 'react';
import { useModalsState } from 'context/modals/modalsContext';
import { useCashoutState } from 'context/cashout/cashoutContext';
import { useFirebaseContext } from 'context/firebase/firebaseContext';
import WalletStateProvider from 'context/wallet/walletContext';
import { useGlobalState } from 'context/global/globalContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SteamErrorIcon } from 'static/images/icons/steam_error_icon.svg';
import { ReactComponent as CardNotificationIcon } from 'static/images/icons/card_notification_icon.svg';
import {
  OPEN_UNAVAILABLE_MODAL,
} from 'context/modals/modalsActions';
import {
  SET_STEAM_STATUS,
} from 'context/cashout/cashoutActions';
// styles
import {
  BodyContainer,
  BodyContent,
  SnackbarCloseButton,
  StyledSnackbar,
  // Banner,
  // BannerTitle,
  // BannerDesc,
  // BannerWarningIcon,
  // BannerCloseIcon,
} from 'styles/cashout/StyledBody';
import Inventory from './inventory/InventoryContainer';
import Payment from './payment/PaymentContainer';

const CashoutBodyContainer = () => {
  const [openToast, setOpenToast] = useState(false);
  const [minimalPayoutOverflow, setMinimalPayoutOverflow] = useState(false);
  // const [displayBanner, setDisplayBanner] = useState(true);
  const { globalState } = useGlobalState();
  const { modalsDispatch } = useModalsState();
  const { cashoutState, cashoutDispatch } = useCashoutState();
  const { appStatus } = useFirebaseContext();
  const { t } = useTranslation();

  const closeSnackbar = () => {
    setOpenToast(false);
  };

  const closeMinimalPayout = () => {
    setMinimalPayoutOverflow(false);
  };

  // const closeBanner = () => {
  //   setDisplayBanner(false);
  // };

  useEffect(() => {
    if (!cashoutState.steamEnabled) {
      setOpenToast(true);
    } else {
      setOpenToast(false);
    }
  }, [cashoutState.steamEnabled]);

  useEffect(() => {
    appStatus.onSnapshot({
      includeMetadataChanges: true,
    }, (doc) => {
      const docData = doc.data();
      if (!docData.enabled) {
        modalsDispatch({ type: OPEN_UNAVAILABLE_MODAL });
      }
      cashoutDispatch({
        type: SET_STEAM_STATUS,
        payload: {
          steamEnabled: docData.isSteamAvailable,
        },
      });
    });
  }, []);

  const sumForBonusPercent = () => {
    const percent = globalState.cashoutBonusPercent / 100;
    const bonus = cashoutState.selectedItemsCost * percent;
    const sum = (cashoutState.selectedItemsCost + bonus).toFixed(2);
    return sum;
  };

  useEffect(() => {
    if (globalState.cashoutBonusPercent) {
      if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'qiwi' && sumForBonusPercent() < 100) {
        setMinimalPayoutOverflow(true);
      } else if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'card' && sumForBonusPercent() < 1000) {
        setMinimalPayoutOverflow(true);
      } else {
        setMinimalPayoutOverflow(false);
      }
    } else if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'qiwi' && cashoutState.selectedItemsCost < 100) {
      setMinimalPayoutOverflow(true);
    } else if (cashoutState.selectedItems.size > 0 && cashoutState.payoutMethod === 'card' && cashoutState.selectedItemsCost < 1000) {
      setMinimalPayoutOverflow(true);
    } else {
      setMinimalPayoutOverflow(false);
    }
  }, [cashoutState.selectedItemsCost, cashoutState.selectedItems, cashoutState.payoutMethod]);

  const minimalPayoutNotification = () => {
    if (cashoutState.payoutMethod === 'qiwi') {
      return t('cashout.cashoutBodyContainer.minimalPayoutNotification.minimalQiwi');
    }
    return t('cashout.cashoutBodyContainer.minimalPayoutNotification.minimalCard');
  };

  return (
    <BodyContainer>
      {/* <Banner displayBanner={displayBanner}>
        <BannerWarningIcon />
        <BannerTitle>
          {t('cashout.cashoutBodyContainer.bannerQiwi.title')}
          <BannerCloseIcon onClick={closeBanner} />
        </BannerTitle>
        <BannerDesc>
          {t('cashout.cashoutBodyContainer.bannerQiwi.desc')}
        </BannerDesc>
      </Banner> */}
      <BodyContent container justifyContent="space-between">
        <Inventory />
        <WalletStateProvider>
          <Payment />
        </WalletStateProvider>
      </BodyContent>
      <StyledSnackbar
        open={openToast}
        message={(
          <>
            <SteamErrorIcon />
            <SnackbarCloseButton onClick={closeSnackbar}>X</SnackbarCloseButton>
            {t('cashout.cashoutBodyContainer.payout')}
          </>
        )}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      />
      <StyledSnackbar
        open={minimalPayoutOverflow}
        message={(
          <>
            <CardNotificationIcon />
            <SnackbarCloseButton onClick={closeMinimalPayout}>X</SnackbarCloseButton>
            {minimalPayoutNotification()}
          </>
        )}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      />
    </BodyContainer>
  );
};

export default CashoutBodyContainer;
