import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import {
  Dialog,
} from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const ModalContainer = styled.div`
  max-width: 960px;
  width: 100%;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: ${isMobile ? 16 : 20}px;
  line-height: ${isMobile ? 20 : 24}px;
`;

export const ModalSubTitle = styled.div`
  font-weight: 500;
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 18 : 22}px;
  color: rgba(255, 255, 255, .5);
  text-align: center;
  white-space: pre;
  margin: 12px 0 24px;
`;

export const ButtonContainer = styled.div`
  width: 186px;
`;
