import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Main = styled.div`
  height: ${isMobile ? 'calc(100vh - 240px)' : 'calc(100vh - 320px)'};
  overflow: ${isMobile ? 'scroll' : 'hidden'};
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: ${isMobile ? 'calc(100% - 32px)' : '1000px'};
  margin: ${isMobile ? '16px' : '85px auto'};
  padding: ${isMobile ? '24px 16px' : '42px 30px'};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 42px;
  margin-top: 0;
  padding: 0 10px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-weight: 500;
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 17 : 22}px;
  color: rgba(255, 255, 255, 0.5);
`;

export const Paragraph = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 42px;
  display: flex;
  padding: 0 10px;
`;

export const Link = styled.a`
  text-decoration: none;
  margin-left: 5px;
  color: #ffffff;
`;

export default Main;
