import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
// styles
import StyledInstructionsContainer, {
  InstructionsTitle,
  RedirectButtonContainer,
} from 'styles/instructions/StyledInsrtuctions';
// RU
import firstStepImage from 'static/images/instructions/step_1.svg';
import secondStepImage from 'static/images/instructions/step_2.png';
import thirdStepImage from 'static/images/instructions/step_3.svg';
import fourthStepImage from 'static/images/instructions/step_4.svg';
import fifthStepImage from 'static/images/instructions/step_5.svg';
// EN
import firstStepImageEN from 'static/images/instructions/step_1EN.svg';
import secondStepImageEN from 'static/images/instructions/step_2EN.svg';
import thirdStepImageEN from 'static/images/instructions/step_3EN.svg';
import fourthStepImageEN from 'static/images/instructions/step_4EN.svg';
import fifthStepImageEN from 'static/images/instructions/step_5EN.svg';
import Button from 'components/common/CashoutPayoutButton';
import Step from './Step';

const stepImagesRU = [
  firstStepImage,
  secondStepImage,
  thirdStepImage,
  fourthStepImage,
  fifthStepImage,
];

const stepImagesEN = [
  firstStepImageEN,
  secondStepImageEN,
  thirdStepImageEN,
  fourthStepImageEN,
  fifthStepImageEN,
];

const InstuctionsContainer = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const stepTitles = [
    `1. ${t('instructions.stepOneTitle')}`,
    `2. ${t('instructions.stepTwoTitle')}`,
    `3. ${t('instructions.stepThreeTitle')}`,
    `4. ${t('instructions.stepFourTitle')}`,
    `5. ${t('instructions.stepFiveTitle')}`,
  ];

  const stepContents = [
    `${t('instructions.stepOneDesc')}`,
    `${t('instructions.stepTwoDesc')}`,
    `${t('instructions.stepThreeDesc')}`,
    `${t('instructions.stepFourDesc')}`,
    `${t('instructions.stepFiveDesc')}`,
  ];

  const redirect = () => {
    history.push('/cashout');
  };

  return (
    <StyledInstructionsContainer>
      <InstructionsTitle hidden={isMobile}>
        {t('instructions.title')}
      </InstructionsTitle>
      {i18n.language === 'ru' ? (
        stepTitles.map((title, i) => (
          <Step image={stepImagesRU[i]} title={title} content={stepContents[i]} key={title} />
        ))
      ) : (
        stepTitles.map((title, i) => (
          <Step image={stepImagesEN[i]} title={title} content={stepContents[i]} key={title} />
        ))
      )}
      <RedirectButtonContainer>
        <Button onClick={redirect}>
          {t('landing.startingButton')}
        </Button>
      </RedirectButtonContainer>
    </StyledInstructionsContainer>
  );
};

export default InstuctionsContainer;
