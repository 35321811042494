import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PayoutInputContainer,
  PayoutNotification,
} from 'styles/cashout/StyledBody';
import Input from 'components/common/CashoutInput';
import PhoneInput from 'material-ui-phone-number';
import { useAmplitudeContext } from 'context/amplitude/amplitudeContext';
import { useWalletState } from 'context/wallet/walletContext';
import { useCashoutState } from 'context/cashout/cashoutContext';
import {
  SET_WALLET_VALIDITY,
  SET_NOTIFICATION,
  SET_SELECTED_COUNTRY,
  SET_WALLET_VALUE,
} from 'context/wallet/walletActions';

const walletLocalization = {
  Armenia: 'Армения',
  Russia: 'Россия',
  Azerbaijan: 'Азербайджан',
  Belarus: 'Белоруссия',
  Estonia: 'Эстония',
  Georgia: 'Грузия',
  Kazakhstan: 'Казахстан',
  Kyrgyzstan: 'Киргизия',
  Latvia: 'Латвия',
  Lithuania: 'Литва',
  Tajikistan: 'Таджикистан',
  Turkmenistan: 'Туркменистан',
  Ukraine: 'Украина',
  Uzbekistan: 'Узбекистан',
};

const countryPhoneLength = {
  Armenia: 15,
  Azerbaijan: 15,
  Belarus: 12,
  Estonia: 13,
  Georgia: 15,
  Kazakhstan: 11,
  Kyrgyzstan: 15,
  Latvia: 15,
  Lithuania: 15,
  Russia: 11,
  Tajikistan: 15,
  Turkmenistan: 15,
  Ukraine: 12,
  Uzbekistan: 15,
};

const WalletInput = () => {
  const [validity, setValidity] = useState(false);
  const [cardValue, setCardValue] = useState('');
  const { logAmplitudeAction } = useAmplitudeContext();
  const { walletState, walletDispatch } = useWalletState();
  const { cashoutState } = useCashoutState();
  const { t } = useTranslation();

  const checkPhoneValidity = (inputNumber) => {
    if (
      inputNumber.length === countryPhoneLength[walletState.selectedCountry]
    ) {
      setValidity(true);
    } else {
      setValidity(false);
    }
  };

  useEffect(() => {
    if (validity) {
      walletDispatch({
        type: SET_WALLET_VALIDITY,
        payload: { walletValidity: validity },
      });
    }
  }, [validity]);

  useEffect(() => {
    if (cashoutState.payoutMethod === 'qiwi') {
      setCardValue('');
    }
    if (cashoutState.payoutMethod === 'card') {
      walletDispatch({
        type: SET_NOTIFICATION,
        payload: { notification: false },
      });
    }
  }, [cashoutState.payoutMethod]);

  const changeWalletValue = (inputValue, country) => {
    if (country.dialCode !== '7') {
      walletDispatch({
        type: SET_NOTIFICATION,
        payload: { notification: true },
      });
    } else {
      walletDispatch({
        type: SET_NOTIFICATION,
        payload: { notification: false },
      });
    }
    walletDispatch({
      type: SET_SELECTED_COUNTRY,
      payload: { selectedCountry: country.name },
    });
    walletDispatch({
      type: SET_WALLET_VALUE,
      payload: { walletValue: inputValue },
    });
  };

  const walletBlur = () => {
    logAmplitudeAction('enter_payment_method', {
      method: 'QIWI',
      wallet: walletState.walletValue,
    });
  };

  const cardReg = /^[0-9]*$/;
  const changeCardValue = (e) => {
    const inputValue = e.target.value;
    if (cardReg.test(inputValue) && inputValue.length <= 16) {
      setCardValue(inputValue);
    }
    if (inputValue.length === 16) {
      setValidity(true);
      walletDispatch({
        type: SET_WALLET_VALUE,
        payload: { walletValue: inputValue },
      });
    } else {
      setValidity(false);
    }
  };

  const cardBlur = () => {
    logAmplitudeAction('enter_payment_method', {
      method: 'Card',
      wallet: cardValue,
    });
  };

  return (
    <>
      <PayoutInputContainer>
        {cashoutState.payoutMethod === 'qiwi' && (
          <PhoneInput
            localization={walletLocalization}
            countryCodeEditable={false}
            isValid={checkPhoneValidity}
            regions="ex-ussr"
            defaultCountry="ru"
            inputClass="phone-input"
            dropdownClass="phone-drop-down"
            label={t('cashout.walletInput.qiwiInput')}
            onChange={changeWalletValue}
            onBlur={walletBlur}
            fullWidth
            disableAreaCodes
          />
        )}
        {cashoutState.payoutMethod === 'card' && (
          <Input
            error={cardValue.length < 16 && !!cardValue}
            value={cardValue}
            changeValue={changeCardValue}
            placeholder={t('cashout.walletInput.cardInput')}
            type="text"
            helperText={t('cashout.walletInput.cardHelper')}
            onBlur={cardBlur}
            centeredplaceholder
            hidden={cashoutState.payoutMethod !== 'card'}
          />
        )}
      </PayoutInputContainer>
      <PayoutNotification hidden={!walletState.notification}>
        {t('cashout.walletInput.qiwiNotification')}
      </PayoutNotification>
    </>
  );
};

export default WalletInput;
