import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { size } from 'styles/breakpoints';
import useWindowDimensions from 'hooks/windowWidthHook';
import { useTranslation } from 'react-i18next';
import {
  Grid,
} from '@material-ui/core';
import Input from 'components/common/CashoutInput';
import Button from 'components/common/CashoutFilledButton';
import { ReactComponent as ArrowIcon } from 'static/images/icons/arrow_icon.svg';

const TradeLinkRow = (props) => {
  const {
    confirmedTradeUrl,
    tradeLink,
    tradeUrlApprove,
    changeTradeLink,
    reEnterTradeLink,
  } = props;
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <Grid container spacing={width < size.desktopWide ? 1 : 2}>
      <Grid item xs={confirmedTradeUrl ? 12 : 9} hidden={isMobile && confirmedTradeUrl}>
        <Input
          placeholder={t('cashout.tradeLinkRow.title')}
          confirmed={confirmedTradeUrl}
          value={tradeLink}
          changeValue={changeTradeLink}
          changeLink={reEnterTradeLink}
          onEnterPress={tradeUrlApprove}
        />
      </Grid>
      <Grid item xs={3} hidden={confirmedTradeUrl}>
        <Button disabled={!tradeLink} onClick={tradeUrlApprove}>
          {isMobile ? <ArrowIcon /> : t('cashout.tradeLinkRow.button')}
        </Button>
      </Grid>
    </Grid>
  );
};

TradeLinkRow.propTypes = {
  confirmedTradeUrl: PropTypes.bool.isRequired,
  tradeLink: PropTypes.string.isRequired,
  tradeUrlApprove: PropTypes.func.isRequired,
  changeTradeLink: PropTypes.func.isRequired,
  reEnterTradeLink: PropTypes.func.isRequired,
};

export default TradeLinkRow;
