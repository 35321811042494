import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { device } from 'styles/breakpoints';
import {
  Grid, Divider, Snackbar, Dialog,
} from '@material-ui/core';
import BannerWarning from 'static/images/banner/banner_warning.svg';
import BannerClose from 'static/images/banner/banner_close.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.displayBanner ? '24px 0' : '0')};
  height: ${(props) => (props.displayBanner ? 'none' : 'calc(100vh - 60px)')};
`;

export const BodyContent = styled(Grid)`
  width: 960px;

  @media ${device.desktopWide} {
    width: 1220px;
  } ;
`;

export const SnackbarCloseButton = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
`;

export const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    position: relative;
  }

  .MuiSnackbarContent-message {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    svg {
      margin-right: 8px;
    }
  }
`;

export const BodyBlockTitle = styled.div`
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 15px 0 16px;
`;

// inventory

export const StyledInventoryContainer = styled(Grid)`
  height: 580px;
  width: 562px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 30px 25px;

  @media ${device.desktopWide} {
    height: 797px;
    width: 820px;
  } ;
`;

export const TradeLinkContainer = styled(Grid)`
  & > div {
    padding: 8px;
  }
`;

export const TradeUrlLink = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
  cursor: pointer;
`;

export const SteamError = styled.div`
  color: red;
  font-weight: normal;
`;

export const StyledInventoryContentContainer = styled(Grid)`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

export const StyledNoItemsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* white-space: ${!isMobile && 'pre'}; */
  height: ${isMobile ? 300 : 405}px;
  margin: 0 auto;
  width: 280px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.5;
`;

export const ContentPaginationContainer = styled(Grid)`
  margin-top: 12px;

  @media ${device.desktopWide} {
    margin-top: 24px;
  }
`;

export const LeftPaginationButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  border: none;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? '.3' : '1')};

  &:hover {
    background: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)')};
  }
`;

export const RightPaginationButton = styled(LeftPaginationButton)`
  transform: rotate(-180deg);
  margin-left: 16px;
`;

export const AllPaginationButton = styled(LeftPaginationButton)`
  margin-right: 0;
  width: 120px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
`;

// payment

export const StyledPaymentContainer = styled(StyledInventoryContainer)`
  position: relative;
  width: 378px;
`;

export const StyledCommissionBlock = styled.div`
  position: absolute;
  top: -15px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 35px;
  background: #ffd964;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.71);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

export const PayoutContainerItem = styled(Grid)`
  margin: 8px 0;
`;

export const PayoutContainerFirstItem = styled(Grid)`
  margin-bottom: 8px;
`;

export const PayoutTitle = styled.span`
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  opacity: 0.5;
`;

export const PayoutContent = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.blue ? '#3378FF' : 'inherit')};
`;

export const PayoutMethodItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 44px;
  margin: 0 6px;
  box-sizing: border-box;
  border-radius: 4px;
  background: white;
  /* background: ${(props) => (props.selected ? 'white' : 'rgba(255, 255, 255, .1)')}; */
  border: ${(props) => (props.selected ? '3px solid #3378FF' : 'none')};

  /* svg {
    opacity: ${(props) => (props.selected ? '1' : '.2')};
  } */

  &:first-of-type {
    margin-left: 0;
  }
`;

export const PayoutMethodItemText = styled.span`
  position: absolute;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
`;

export const PayoutDivider = styled(Divider)`
  background: #fff;
  opacity: 0.1;
  border-radius: 5px;
  margin: 20px 0;
`;

export const PayoutInputContainer = styled.div`
  margin: 12px 0;

  .phone-input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    height: 48px;
    padding: 13px 12px 0 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: ${(props) => (props.disabled ? 12 : 0)}px;

    .MuiInputLabel-shrink {
      transform: translate(12px, 7px) scale(0.75);
      font-size: ${isMobile ? 12 : 14}px;
      line-height: ${isMobile ? 12 : 14}px;
      color: rgba(255, 255, 255, 0.5);
    }

    label + .MuiInput-formControl {
      margin-top: 4px;
    }

    .MuiInputBase-input {
      font-size: 14px;
    }

    .MuiInputBase-root::before,
    .MuiInputBase-root::after {
      border: none;
    }

    &.Mui-disabled {
      color: #fff;
      opacity: 0.5;
    }
  }
`;

export const PayoutEmailInputContainer = styled.div`
  min-height: 65px;

  .MuiFormHelperText-root {
    font-size: ${isMobile && '10px'};
    line-height: ${isMobile && '14px'};
    font-weight: 500;
  }
`;

export const PayoutNotification = styled.div`
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
`;

export const CoinsNotification = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  margin-top: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;
`;

export const CofirmBlock = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);

  a {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const ConfirmButtonContainer = styled.div`
  margin: 16px auto;
  width: 186px;
`;

export const MaxPayoutHint = styled.div`
  font-weight: 500;
  font-size: ${isMobile ? 10 : 12}px;
  line-height: ${isMobile ? 14 : 17}px;
  color: #ff4242;
`;

export const Spinner = styled.img`
  animation: ${rotate} 2s linear infinite;
`;

export const SomeError = styled.div`
  color: red;
  font-size: 12px;
`;

export const CardNotifications = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  padding-top: 8px;
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #363839;
    border-radius: 10px;
    margin: 0;

    &.MuiDialog-paperWidthLg.MuiDialog-paperScrollBody {
      max-width: unset;
    }
  }
`;

export const ModalContainer = styled.div`
  width: ${isMobile ? 288 : 550}px;
  background: #363839;
  border-radius: 10px;
  padding: ${isMobile ? '24px 16px' : '48px 24px'};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTradeContainer = styled.div`
  width: ${isMobile ? 288 : 750}px;
  background: #363839;
  border-radius: 10px;
  padding: ${isMobile ? '24px 16px' : '48px 24px'};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QiwiModalTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 12px;
`;

export const QiwiModalDesc = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${(props) => (props.warning ? '#FF0000' : '#FFFFFF')};
  opacity: 0.8;
  margin-bottom: 8px;
`;

export const QiwiModalLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #ff8c00;
  text-decoration: none;
  margin-bottom: 8px;
`;

export const QiwiModalButton = styled.button`
  background-color: inherit;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 14.5px 22px;
  max-width: 234px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const Banner = styled.div`
  display: ${(props) => (props.displayBanner ? 'block' : 'none')};;
  max-width: 1000px;
  width: 100%;
  position: relative;
  background: #FFFBEB;
  margin-bottom: 20px;
  padding: 16px 16px 16px 48px;
  border-radius: 6px;
  z-index: 10;
`;

export const BannerTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #92400E;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const BannerDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #B45309;
`;

export const BannerWarningIcon = styled.div`
  width: 15px;
  height: 14px;
  background-image: url(${BannerWarning});
  position: absolute;
  left: 18.5px;
  top: 18px;
`;

export const BannerCloseIcon = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${BannerClose});

  &:hover {
    cursor: pointer;
  }
`;
