import React from 'react';
import PropTypes from 'prop-types';
// styles
import StageBlock from 'styles/common/StyledStageBlock';

const OfferStageBlock = (props) => {
  const {
    children,
  } = props;

  return <StageBlock container>{children}</StageBlock>;
};

OfferStageBlock.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default OfferStageBlock;
