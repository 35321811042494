import React from 'react';
import PropTypes from 'prop-types';
// styles
import {
  InstructionsStepContainer,
  InstructionStepTitle,
  InstructionsStepContent,
  InstructionsStepImage,
} from 'styles/instructions/StyledInsrtuctions';

const Step = (props) => {
  const {
    title,
    content,
    image,
  } = props;

  return (
    <InstructionsStepContainer>
      <InstructionStepTitle>{title}</InstructionStepTitle>
      <InstructionsStepContent>
        {content}
      </InstructionsStepContent>
      <InstructionsStepImage src={image} alt="step" />
    </InstructionsStepContainer>
  );
};

Step.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Step;
